<app-navbar></app-navbar>




<!-- Start Main Banner Area -->
<div class="machine-learning-slider owl-carousel owl-theme">
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <kbd class="NewLaunchLabel">POWERED BY DIGIVISION.AI</kbd>
                                <h1>Masking & Compliance for <span class="highlight"><u>BFSI & Other Industry</u></span></h1>
                                <p>Ensure regulatory compliance effortlessly with DigiMask. Our cutting-edge technology enables real-time and batch processing Aadhaar Image and Customer Data Masking. Configure masking for sensitive documents like Aadhaar Card and Pan Card. Benefit from AI-based number detection for unmatched accuracy on non-templated documents.</p>
                                 
                                <!-- <div class="banner-btn">
                                    <a  class="default-btn me-4 burger-menu">
                                        Get Started <span></span>
                                    </a> -->
                                    <!-- <a  class="btn-danger default-btn" routerLink="/Redaction-Solution" target="_blank">Get Started</a> -->

                                    <a routerLink="/Redaction-Solution" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn-danger default-btn">Get Started</a>

                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/it-banner/animate-img.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>

    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <kbd class="NewLaunchLabel">POWERED BY DIGIVISION.AI</kbd>
                                    <h1>Making <span class="highlight"><u>Paradigmatic Shift </u></span> in <span>Document Management</span> </h1>
                                    <p>An AI-powered advanced system for managing documents, which effectively organizes and brings
                                        structure to unorganized data. It provides a secure platform for storing, accessing, and sharing
                                        all your files conveniently in a single location.</p>
                                <!-- <div class="banner-btn"> -->
                                    <!-- <a  routerLink="/Digidrive" class="default-btn me-4" target="_blank">
                                        Get Started <span></span>
                                    </a> -->
       
                                    <a routerLink="/Digidrive" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn-danger default-btn">Get Started</a>
                                    <!-- <a routerLink="/Redaction-Solution" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn-danger default-btn">Get Started</a> -->
                                <!-- </div> -->
                            </div>
                        </div>
 
                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img style="background-color: #ff00000d;" src="assets/img/it-banner/digidrive-2.svg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <!-- <kbd class="NewLaunchLabel">  NEW LAUNCH  </kbd> -->
                                <kbd class="NewLaunchLabel">POWERED BY DIGIVISION.AI</kbd>
                                <h1 style="color: red;">TrueCheck</h1>
                                <h2 style="color: #e23636;">Identity Verification & Fraud Prevention Platform</h2>
                                <h3>Onboard Everyone but Not Anyone..!!</h3>

                                <p>TrueCheck refers to the process of managing and securing digital identities. It involves authenticating users, controlling access to resources, onboarding, identity verification while ensuring data privacy and security.</p>    
                                <p>Protect your digital environment with built-in spam prevention, fraud prevention, tamper proof auto-classification tools which simplifies form completion and ensures data integrity.</p>                             
                                <!-- <div class="banner-btn">
                                    <a  class="default-btn me-4 burger-menu" style="background-color: #0f33e0;">
                                       New Launch <span></span>
                                    </a>                              
                                </div> -->
                                <a style="background-color: red;" class="default-btn" routerLink="/TrueCheck" target="_blank">Get Started</a>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img style="background-color: #ff00001f"src="assets/img/it-banner/trucheck_banner.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    
    <!-- <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>CUSTOMER <span class="highlight"><u>ONBOARDING & KYC</u></span></h1>
                                <p>Achieve instant customer onboarding, lower customer acquisition costs with our 3 in 1 digital KYC suite. Streamline agent-assisted and self-serviced Video-KYC processes, document verification with AI enabled text extraction and matching algorithms and ensure accurate verification by detecting and eliminating fraud or bogus documents.</p>
                             
                                <div class="banner-btn">
                                    <a class="default-btn me-4 burger-menu">
                                        Get Started <span></span>
                                    </a>
        
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/it-banner/animate-img2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
         Shape Images -->
        <!-- <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>  -->
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <kbd class="NewLaunchLabel">POWERED BY DIGIVISION.AI</kbd>
                                <h1>LDR  <span class="highlight"><u> Legal Document  Review Solution</u></span></h1>
                                <p>A Legal document review (LDR) system is a comprehensive AI framework to streamline all the legal elements, review agreements and clauses to automate the contract and renewal processes within different organization, LDR is use to set of automated tools designed for end to end legal document review and processes. </p>                                <div class="banner-btn">
                                    <a  class="default-btn me-4 burger-menu">
                                        Book a free Demo <span></span>
                                    </a>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img  style="background-color: #ff00000d ;" src="assets/img/it-banner/LOS-removebg-preview.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <!-- <h1><span class="highlight"><u>Fraud Prevention</u></span> Solution</h1> -->
                                <kbd class="NewLaunchLabel">POWERED BY DIGIVISION.AI</kbd>
                                <h1>FCPS  <span class="highlight"><u> Fraud Prevention System </u></span> </h1>
                                <p>Protect your business and reputation with our powerful tamper detection and document fraud prevention solution. Detect and stop fraudulent transactions, safeguarding both customers and financial institutions. Safeguard your loan approval process with our digivision.ai framework.   </p>
                              
                                <div class="banner-btn">
                                    <a class="default-btn me-4 burger-menu">
                                        Book a free Demo <span></span>
                                    </a>      
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                              <div class="animate-image">
                                <img src="assets/img/it-banner/animate-img3.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
       <!-- <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>-->
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
       <!-- <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>-->
    </div>
</div>
<!-- End Main Banner Area -->


<!-- Start BD Fun Facts Area -->
<section class="bd-fun-facts-area">
    <div class="container-fluid">
        <div class="bd-fun-facts-inner-box pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-1.png" alt="icon">
                        </div>
                        <h3><span data-count="1.60">1.60 </span><span class="sign-icon">bn+</span></h3>
                        <p>Aadhaar Masking</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-2.png" alt="icon">
                        </div>
                        <h3><span data-count="1.32">1.32</span><span class="sign-icon">bn+</span></h3>
                        <p>Document Verified</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-3.png" alt="icon">
                        </div>
                        <h3><span  data-count="740"> 740  </span><span class="sign-icon">mn+</span></h3>
                        <p>Files Compressed</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="bd-single-fun-facts">
                        <div class="image-icon">
                            <img src="assets/img/big-data-home/fun-facts/image-4.png" alt="icon">
                        </div>
                        <h3><span class="odometer" data-count="8.3">8.3  </span><span class="sign-icon">mn+</span></h3>
                        <p>Video KYC</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End BD Fun Facts Area -->
<!-- Start BD Services Area -->
<div class="bd-services-area pt-100">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <span class="sub-title">OUR SERVICES</span>
            <h2>See Wide Range Of Our <span>Services</span> With Big Data Solutions</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-1.svg" alt="image">
                    </div>
                    <h3>
                        Digi KYC Suite
                    </h3>
                    <p>Embrace Seamless Verification, Conquer Compliance and Optimize Your KYC Journey using EKYC, OKYC & CKYC.</p>
                    <a href="EKYC" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-2.svg" alt="image">
                    </div>
                    <h3>
                        Offline Vision Engine
                    </h3>
                    <p>Empowering visual intelligence with offline AI-ML technology using X-Biz OCR-ICR, Face & Sign Match.</p>
                    <a href="https://xbizocr.digivision.ai/" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-3.svg" alt="image">
                    </div>
                    <h3>
                        Video KYC
                    </h3>
                    <p>Seamless Compliance, Face-to-Face : AI Powered Video KYC (Self Assisted & Agent Based)- Your Key to Secure Onboarding.</p>
                    <a href="Video-KYC" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-4.svg" alt="image">
                    </div>
                    <h3>
                        Indexing & Classification
                    </h3>
                    <p>Streamlining information with smart & effortless Document sorting for enhanced productivity, auto bucketing & full-text search.</p>
                    <a href="Document-Indexing" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-5.svg" alt="image">
                    </div>
                    <h3>
                        Digi Automation Solution
                    </h3>
                    <p>AI-OCR technology for identifying and processing KYC, Financial & Other documents. Capability of 115+ Template and 350+ variants. </p>
                    <a href="Document-Detection" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-6.svg" alt="image">
                    </div>
                    <h3> TrueCheck
                            
                    </h3>
                    <p>Bridging the Gap for Seamless and Secure Identity Verification Platform, provides Onboarding, Verification, REKYC & Authetication</p>
                    <a href="https://truecheck.ai/" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>
        </div>

                <div class="row  collapse" id="digi-product">

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-7.svg" alt="image">
                    </div>
                    <h3>
                        Redaction Solution
                    </h3>
                    <p>Protecting privacy by concealing sensitive data such as PII Masking, Aadhaar Masking, Pan Masking & QR Masking.</p>
                     <a href="Redaction-Solution" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-8.svg" alt="image">
                    </div>
                    <h3>
                        Compression Solution
                    </h3>
                    <p>Maximizing storage efficiency upto 90% without compromising quality</p>
                    <a href="Compression-Solution" class="get-started-btn">GET STARTED</a> 
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-11.svg" alt="image">
                    </div>
                    <h3>
                        iMagica Suite
                    </h3>
                    <p> Powerful backend utility provide lossless Compression, File Converter, Merger, Splitter & enhance Images, Video, Audio effortlessly.</p>
                    <a href="iMagica-Suite" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>
           

            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-10.svg" alt="image">
                    </div>
                    <h3>
                        Object Detection Solution
                    </h3>
                    <p>Unleashing DigiVision AI power for real-world insights such as Label, Entity Object, Consumer goods, Landmark, Logo, Stamp.</p>
                    <a href="Object-Detection-Solution" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

            
            <div class="col-lg-4 col-sm-6">
                <div class="bd-services-card">
                    <div class="services-image">
                        <img src="assets/img/digi-9.svg" alt="image">
                    </div>
                    <h3>
                        Location Based Solution
                    </h3>
                    <p>Navigating the corporate world with precision and context.</p>
                    <a href="Location-Based-Solution" class="get-started-btn">GET STARTED</a> 
                </div>
            </div>

       
       
    </div>
</div>

        <div class="row"><div class="col-md-12 text-center pb-70"><a  class="default-btn"  data-bs-toggle="collapse" href="#digi-product" role="button" aria-expanded="false" aria-controls="digi-product"> View More Products &nbsp; <i class="fas fa-angles-down fa-bounce"></i></a></div></div>

</div>
<!-- End BD Services Area -->

<!-- End Featured Services Area -->




<!-- About Us -->
<section class="choose-area-two ptb-100 bg-white">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <img src="assets/img/digivision-logo.png" class="Digivision-logo"  style="width: 500px;" alt="Digivision">
                        <!-- <span class="sub-title">About DIGIVISION</span> -->
                        <h4>Engaging New Audiences Through Smart Approach</h4>

                        <p>Unleash the power of DigiVision: our intelligent computer vision and data capture solution revolutionizes document and video analysis. Through cutting-edge algorithms, NLP, machine learning and advanced AI techniques, DigiVision.ai reveals hidden details within visuals.</p>

                            <p> What sets us apart is the synergy of our specialist AI algorithms, which bring all the elements together to deliver exceptional accuracy in outputs. DigiVision is more than just technology it's a unique blend of computer vision and AI capabilities that make a real impact.</p>
                            
                                <p> Experience the difference with DigiVision.ai and unlock a suite of 25+ products designed to transform your visual data analysis.</p>
                    </div>

                   
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/digidrive-1.svg" alt="image">
                </div>
            </div>
        </div>
        <div>
            <a href="https://digivision.ai/" target="_blank" class="default-btn burger-menu mt-4">
               For More Info<span></span>
            </a>
        </div>
    </div>
    

</section>
<!-- End About Us -->

<div class="container">
    <div class="d-flex justify-content-center mt-5">
        <div class="h-50"><h1>READY TO TAKE NEXT STEP? </h1></div>
     </div>
     <div class="d-flex justify-content-center mb-5">
        <div class="banner-btn">
            <a class="default-btn me-4 burger-menu">
                CONTACT SALES <span></span>
            </a>
        </div>
     </div>
</div>
<!-- End Projects Area -->
<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/TrueCheck" ><i class="flaticon-left-chevron"></i> Truecheck Identity Verification Platform</a>
        </div>

        <div class="nav-next">
            <a routerLink="/Digidrive">Digi-Drive DMS<i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>



<!-- Start FAQ Area -->
<section class="faq-area ptb-100 d-none">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">FAQ</span>
            <h2>Frequently Asked Questions</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-img pr-3">
                    <img src="assets/img/faq.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
           
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How to get start with us?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<section class="featured-services-area ptb-100 pt-0 d-none">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Featured Services</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>Digi Compression AI </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon color-facd60">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3>Digi i-Magica Converter</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-featured-box">
                    <div class="icon color-1ac0c6">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3>Digi i-Magica Cutter</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe d-none">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/machine-learning/free-trial-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">Get Started Instantly!</span>
                    <h2>Start your free trial</h2>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="email">
                        <button type="submit">Sign Up Free</button>
                    </form>

                    <p class="help-text">Test out the Machine Learning features for 14 days, no credit card required.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

