<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>EKYC, CKYC & OKYC</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>EKYC</h2>
                    <p>eKYC, or electronic Know Your Customer, is a digital process that allows individuals or
                        businesses to verify their identity remotely. By using advanced technologies eKYC eliminates
                        the need for in-person verification, making it convenient, efficient, and secure. It enables
                        seamless onboarding for various services, including banking, insurance, and government
                        registrations, by validating personal information against trusted databases. eKYC not only
                        streamlines customer verification processes but also helps in reducing fraud and ensuring
                        compliance with regulatory requirements in the digital age.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                OTP-based Aadhaar authentication
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Avoid fraudulent activities using our AI/ML learnings
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Data encryption to securely store and make it readily available for next KYC
                                journey
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                On fly customer onboarding & verification
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/ekyc.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="overview-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/CKYC.svg" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>CKYC</h2>
                    <p>CKYC, or Central Know Your Customer, is a centralized repository of customer information
                        maintained by financial institutions in India. It aims to streamline the customer
                        identification process and reduce duplication by creating a single source of verified KYC
                        data. CKYC allows financial institutions to access and share customer information securely,
                        facilitating faster and more efficient onboarding for various financial products and services.
                        It enhances transparency, reduces paperwork, and helps in combating money laundering
                        and financial fraud by maintaining a comprehensive and up-to-date record of customer
                        identities and profiles.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Quicker response rate
                            </span>
                        </li>
                        
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Reliable response with no failure rate
                            </span>
                        </li>
                       <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Securely stored in electronic format
                            </span>
                        </li>
  <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Similar name detection and auto evaluation
                            </span>
                        </li>
 <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Upload updated documents to CERSAI records
                            </span>
                        </li>
                        
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>OKYC</h2>
                    <p>Aadhaar Paperless Offline e-KYC eliminates the need for the resident to provide photo copy
                        of Aadhaar letter and instead resident can download the KYC XML and provide the same to
                        the service. The KYC details is in machine readable XML which is digitally signed by UIDAI
                        allowing service to verify its authenticity and detect any tampering.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                RPA service for XML download
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Service can automate popular captcha with 100% accuracy

                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Data is directly from government records and reliable
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                It can work in batch mode, if OTP is not required
                            </span>
                        </li>
                     






                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/OKYC.svg" alt="image">
                </div>
            </div>
        </div>

    </div>
</section>
  
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
           &nbsp;
        </div>

        <div class="nav-next">
            <a routerLink="/Offline-vision-Engine">Offline Vision Engine <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>