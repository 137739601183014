<!-- Start Topbar Wrap Area -->
<div class="topbar-wrap-area">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-4 col-md-4">
                <ul class="topbar-social-links">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                </ul>
            </div>
            <div class="col-lg-8 col-md-8">
                <ul class="topbar-action-list">
                    <li><i class='bx bxs-map'></i> 118-120 IJMIMA Complex, Mindspace, Malad West, Mumbai - 400064</li>
                    <li>
                        <i class='bx bxs-phone'></i> <span>Call</span> <a href="tel:1234567890">+1800 266 8575</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Topbar Wrap Area -->

<!-- Start Navbar Area -->
<div class="navbar-area navbar-area-with-position-relative">
    <div class="xbiz-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="others-options">
                    <div class="option-item"><button   class="default-btn burger-menu">Request Demo &nbsp; <i class="fa fa-arrow-up-right-dots"></i> <span></span></button>
                   
                      
                    </div>
                
                </div>
            </nav>
        </div>
    
</div>
<!-- End Navbar Area -->