<app-navbar></app-navbar>

<style>
    :root {
        --fontFamily: 'Poppins', sans-serif;
        --whiteColor: #ffffff;
        --blackColor: #212529;
        --paragraphColor: #737b9a;
        --grayColor: #f9f9fc;
        --fontSize: 15px;
        --SecondaryfontSize: 14px;
    }

    .team-section {
        padding: 50px 0;
    }

    .team-section h2 {
        text-align: center;
        margin-bottom: 50px;
        color: red;
        font-weight: 700;
        font-size: 40px;
    }

    .team-member {
        text-align: center;
        margin-bottom: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .team-member img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        border: 0.5px solid #dcd6d8;
    }

    .role {
        background-color: #003366;
        color: white;
        padding: 5px 0;
        border-radius: 5px;
        width: 150px;
        margin: 10px auto;
        text-align: center;
    }

    .team-member h5 {
        font-size: 1.25rem;
        margin: 10px 0 5px;
        color: #003366;
    }

    .team-member p {
        font-size: var(--fontSize);
        line-height: 1.8;
        color: var(--paragraphColor);
        margin: 0;
        height: 105px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 768px) {
        .team-member img {
            width: 75%;
            height: auto;
            max-width: none;
        }

        .team-section h2 {
            font-size: 30px;
        }

       
        .team-member {
            flex-basis: 100%;
        }
    }
</style>






<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
   <!-- <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>-->
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- About Area Two -->
<section class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img2.jpg" alt="image" class="rounded-10">

                    <div class="solution-video d-none">
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About X-Biz Techventures</span>
                        <h2>We Innovate, Incubate and Invest</h2>
                        <p>Xbiz techventures private limited are Information Management professionals who have been at leadership roles as CEO / CIO / CXO with Global organizations in FMCG, Retail, Financial Services, Private Security, Renewable Energy sectors. xbiz aims to create an eco-system of socially relevant products & services that touch people, that are unique, that address unstated needs, which are easy to use. xbiz explores un-chartered waters and fosters a spirit of Adventure in the Ventures.</p>
                    </div>
                    <div class="about-text">
                        <h4>Who We Are</h4>
                        <p>We are a team of information management professionals with a wealth of experience in leadership roles in various sectors such as FMCG, retail, financial services, private security, and renewable energy.</p>
                    </div>
                    <div class="about-text">
                        <h4>Our History</h4>
                        <p>Established in 2012 & trusted by leading Banks & Insurance companies, “XBiz Techventures Pvt. Ltd” has been a premier provider of AI/ML, Computer Vision and Cyber Security solutions to BFSI sector.</p>
                    </div>
                    <div class="about-text">
                        <h4>Our Mission</h4>
                        <p>X-Biz founders desire to give back to Industry and Society that provided the opportunity to grow & excel as professionals. Having embarked on the journey of Entrepreneurship themselves, equipped with knowledge, experience & understanding of the business world, they are keen to share their experience, guide & lead others to success.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area Two -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-two pt-100 pb-70 bg-f2f2f7">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-list-check'></i>
                    <h3>
                        <span class="odometer" data-count="524">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-smile'></i>
                    <h3>
                        <span class="odometer" data-count="100">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Happy Clients globally </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-user-plus'></i>
                    <h3>
                        <span class="odometer" data-count="128">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Expert Members</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-trophy'></i>
                    <h3>
                        <span class="odometer" data-count="29">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Core Team -->

<section class="team-section" style="background-color: #8080801a;">
    <div class="container">
        <h2 class="mb-5">Meet Our Team</h2>
        <div class="row mb-4">
            <!-- Team Member 1 -->
            <div class="col-lg-3 col-md-4 col-12 team-member">
                <img src="assets/img/Dipak Nair.png" alt="Dipak J. Nair" class="img-fluid mb-2">
                <div class="role">TECH & STRATEGY</div>
                <h5>Dipak J. Nair</h5>
                <p>25+ years of Tech Leadership across life & non-life Insurance. Ex-TATA AIG, ICICI PRU, AVIVA.</p>
            </div>
            <!-- Team Member 2 -->
            <div class="col-lg-3 col-md-4 col-12 team-member">
                <img src="assets/img/Sunil Yadav.jpg" alt="Sunil Yadav" class="img-fluid mb-2">
                <div class="role">CYBER SECURITY</div>
                <h5>Sunil Yadav</h5>
                <p>15+ years experience. Top Cyber professional and Lead trainer and speaker (Global Black Hat Conference).</p>
            </div>
            <!-- Team Member 3 -->
            <div class="col-lg-3 col-md-4 col-12 team-member">
                <img src="assets/img/Sanjiv Shah.png" alt="Sanjiv Shah" class="img-fluid mb-2">
                <div class="role">COMPANY AFFAIRS</div>
                <h5>Sanjiv Shah</h5>
                <p>30+ years experience. Serial Entrepreneur & Ex COKE, Birla Sunlife, AVIVA.</p>
            </div>
            <!-- Team Member 4 -->
            <div class="col-lg-3 col-md-4 col-12 team-member">
                <img src="assets/img/Vinod Kumar.png" alt="Vinod Kumar" class="img-fluid mb-2">
                <div class="role">OPERATIONS</div>
                <h5>Vinod Kumar</h5>
                <p>30+ years experience. Entrepreneur & Tech Product Engineering and Business management.</p>
            </div>
        </div>
        <div class="row">
            <!-- Team Member 5 -->
            <div class="col-lg-4 col-md-4 col-12 team-member">
                <img src="assets/img/Pushkar Singh.jpg" alt="Pushkar Prakash" class="img-fluid mb-2">
                <div class="role" style="width: 205px;">COMPUTER VISION & AI</div>
                <h5>Pushkar Prakash</h5>
                <p>20+ years experience. Computer Vision, AI and Innovation Lab Leader. Worked for ISRO/DRDO etc.</p>
            </div>
            <!-- Team Member 6 -->
            <div class="col-lg-4 col-md-4 col-12 team-member">
                <img src="assets/img/Vishy Ganesan.png" alt="Vishy Ganesan" class="img-fluid mb-2">
                <div class="role" style="width: 205px;">MARKETING & ALLIANCES</div>
                <h5>Vishy Ganesan</h5>
                <p>20+ years experience. Marketing leader with experience across CPG, Auto, and Tech. Ex-WPP.</p>
            </div>
            <!-- Team Member 7 -->
            <div class="col-lg-4 col-md-4 col-12 team-member">
                <img src="assets/img/Ashish Rao.png" alt="Ashish Rao" class="img-fluid mb-2">
                <div class="role">BIZ CONSULTING</div>
                <h5>Ashish Rao</h5>
                <p>25+ years experience. Process Excellence, Operations, Insurance domain expert. Ex-ICICI PRU.</p>
            </div>
        </div>
    </div>
</section>











<!-- Start Solution Area -->
<section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Your Business</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                    <a class="default-btn burger-menu">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=jzYxbpId2sI" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<br>
<br>
<section class="blog-area ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
<div class="article-footer">
    <div class="small">
        <span><i class="fas fa-bookmark"></i></span> &nbsp;
        <a routerLink="/Digivision">AI Technology</a>,
        <a routerLink="/TrueCheck">Customer Onboarding</a>,
        <a routerLink="/TrueCheck">Identity  Verification</a>,
        <a routerLink="/TrueCheck">Account Opening</a>,
        <a routerLink="/EKYC">KYC Suite</a>,
        <a routerLink="/Video-KYC">Video KYC</a>,
        <a routerLink="/Offline-Vision-Engine">Digital Signature Verification</a>,<a routerLink="/">Document
            Classification</a>,
            <a routerLink="/Offline-Vision-Engine">Physical Signature Verification</a>,<a routerLink="/">Document
                Classification</a>,
        <a routerLink="/Offline-Vision-Engine">Face Verification AI</a>,
        <a routerLink="/iMagica-Suite">Image/ Video/ Audio Compression</a>,
        <a routerLink="/iMagica-Suite">Image/ Video/ Audio Converter</a>,
        <a routerLink="/iMagica-Suite">Image Splitter</a>,
        <a routerLink="/Digidrive">Document Management System</a>,
        <a routerLink="/Banking">Banking</a>,
        <a routerLink="/Insurance">Insurance</a>,
        <a routerLink="/Healthcare">Healthcare</a>,
        <a routerLink="/Offline-Vision-Engine">Auto Underwriting</a>,
    </div>

 
</div>
            </div>
        </div>
    </div>

</section>


<!-- Start Team Area -->
<section class="team-area ptb-100 d-none">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Pushkar Singh</h3>
                    <span>Associate Director - Head of Digivision.ai</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Sanjiv Shah</h3>
                    <span>Founder - Director</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Vinod Kumar</h3>
                    <span>Founder - Director</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Dipak Nair</h3>
                    <span>Founder - Director</span>
                </div>
            </div>


            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Sunil Yadav</h3>
                    <span>Founder - Director - Head of SecureNexus</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe d-none">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/machine-learning/free-trial-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">Get Started Instantly!</span>
                    <h2>Stay up to date with the latest news and exclusive updates</h2>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->
