<app-navbar></app-navbar>

        <!-- Start Main Banner Area -->
        <div class="machine-learning-slider owl-carousel owl-theme ">

            <div class="machine-learning-banner ">  
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <h1 style="color: red;"> TrustTier</h1>
                                        <h3 style="color: #e23636;">Proactive Assurance for Audit and Compliance Management</h3>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            TrustTier AI strengthens risk management in Banking, Financial
                                            Services, and Insurance by leveraging data analysis to detect and
                                            address a multitude of risks, such as fraud and compliance
                                            concerns, while ensuring the secure masking of Aadhaar
                                            information.                               
                                         </p>
                    
                                        
                                        <div class="banner-btn">
                                            <a href="https://trusttier.ai/" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div>
                                        <img src="/assets/img/TrustTier/banner-image.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Shape Images -->
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>



            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <h1> PII <span class="highlight"><u>Masking </u></span> <br><span> </span> </h1>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            Facilitate regulatory compliance effortlessly with DigiMasks
                                            real-time Aadhaar Image and customer Data Masking,
                                            including Al-based number detection for unmatched accuracy
                                            on sensitive documents.                                   
                                         </p>
                    
                                        
                                        <div class="banner-btn">
                                            <a href="https://piimasking.digivision.ai/" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div>
                                        <img src="/assets/img/TrustTier/aadhaar.svg" alt="image" style="height: 500px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Shape Images -->
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>
            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <!-- <span class="sub-title">Empowering Mobile Efficiency</span> -->
                                        <h1>FCPS <span class="highlight"><u>(Financial Crime Prevention) </u></span></h1>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            The Financial Crime Prevention System detects, investigates,
                                            and mitigates anomalies and fraud in financial documents to
                                            uphold the integrity of financial systems and protect individuals
                                            and businesses from financial harm.
                                        </p>
                                     
                                        <div class="banner-btn">
                                            <a href="https://demo.digivision.ai/fcpg/" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-5">
                                    <div class="animate-image">
                                        <img src="/assets/img/TrustTier/FCPG.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>
            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <!-- <span class="sub-title">Empowering Mobile Efficiency</span> -->
                                        <h1>  <span class="highlight"><u> V-CIP</u></span> Auditor</h1>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            Our V CIP Auditor ensures real-time user authenticity checks
                                            and fraud prevention. With features like Liveliness check and
                                            government-mandated Video and audio anomaly verification.
                                            Trust in our solution for reliable and efficient operations
                                            safeguarding.                                              </p>
                                     
                                        <div class="banner-btn">
                                            <a href="https://pivc.digivision.ai/" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-5">
                                    <div class="animate-image">
                                        <img src="/assets/img/TrustTier/Vidio_auditor.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>
            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <!-- <span class="sub-title">Empowering Mobile Efficiency</span> -->
                                        <h1>LDRS <span class="highlight"><u>(Legal Document Review System)</u></span></h1>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            Our Legal Document Review System (LDRS) automates contract
                                            review, centralizes matter management, and ensures
                                            compliance tracking. Leverage Al-powered analytics and
                                            secure document management for efficient and accurate legal
                                            operations.                              
                                          </p>
                                     
                                        <div class="banner-btn">
                                            <a href="https://los.digivision.ai/" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-5">
                                    <div class="animate-image">
                                        <img src="/assets/img/TrustTier/LDRS.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>
            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <!-- <span class="sub-title">Empowering Mobile Efficiency</span> -->
                                        <h1> <span class="highlight"><u>Liveness Check </u></span> </h1>
                                        <p><i class="fa-solid fa-check colorAdd"></i>&nbsp;
                                            On client upload, our system rapidly evaluates video content to differentiate live faces from
                                            static images, ensuring accuracy in identity verification. By analyzing facial movements or
                                            biometric cues in real-time, liveliness detection thwarts spoofing attempts, enhancing the
                                            integrity of our verification processes. 
                                           </p>
                                     
                                        <div class="banner-btn">
                                            <a href="" target="_blank" class="default-btn me-4">
                                                Visit Product Website <span></span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-5">
                                    <div class="animate-image">
                                        <img src="/assets/img/TrustTier/Liveness_2.jpg" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>
            <!-- Early prevention -->

            <div class="machine-learning-banner ">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container mt-80">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="banner-content">
                                        <h1 class="mb-2">Early <span class="highlight">Warning </span><span class="highlight">Prevention</span> System</h1>
                                        <!-- <p style="font-size: 15px; color: black;"></p> -->
                                        <p style="font-size: 15px;"><i class=" colorAdd"></i>&nbsp;
                                            Stay tuned for the launch of our innovative system that will help prevent audit and Compilance.
                                           </p>
                                        <div  class="coming-soon-banner" style="font-size: 42px; font-weight: bold; color: rgb(28, 23, 23);">Coming Soon...!</div>

                                    </div>
                                </div>
        
                                <div class="col-lg-5">
                                    <div class="">
                                        <img src="/assets/img/TrustTier/Warning.png" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
                <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
                <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
                <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
                <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
                <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
                <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
                <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
                <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
            </div>

        </div>
        

<!-- End Main Banner Area -->



<!-- info about trustAI -->
<div class="bd-choose-area ptb-100" style="background: url('/assets/img/TrustTier/left-bg.png') left no-repeat, url('/assets/img/TrustTier/right-bg.png') right no-repeat;" >
    <div class="container" style="margin-top: -4rem;">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-image">
                    <img src="/assets/img/TrustTier/trusttier_diagram 1.png" alt="image" style="margin-top:35px;"> 
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-content" style="margin-left: 60px;">
                    <h3 style="color: red;text-align: center;">TrustTier AI</h3>
                    <p style="text-align: center;">
                            TrustTier AI enhances risk management in BFSI by using data analysis to detect fraud and compliance issues, ensuring secure Aadhaar masking.
                    </p>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li style="color: black;"><i class="fa-solid fa-check"></i> PII Masking</li>
                                <li style="color: black;"><i class="fa-solid fa-check"></i> Legel Document Review System</li>                              
                                <li style="color: black;"><i class="fa-solid fa-check"></i> Financial Crime Prevention System</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li style="color: black;"><i class="fa-solid fa-check"></i>Liveness Check</li>
                                <li style="color: black;"><i class="fa-solid fa-check"></i> V-CIP Auditor</li>
                                <li style="color: black;"><i class="fa-solid fa-check"></i>Early Prevention Warning System </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -->

<!-- Our Services -->
<section class="ml-services-area bg-f2f2f7 pt-100 pb-70" style="background: url('/assets/img/TrustTier/ServicesBackground.jpg')no-repeat center center;background-size: cover;">
    <div class="container">
        <div class="section-title">
            <span class="sub-title" style="color: #feff00; font-size: 20px;">Our Services</span>
            <h2 style="color: white;">Our Professionals Services For You</h2>
           
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="/assets/img/TrustTier/aadhaar.svg" alt="image">
                    </div>
                    <h3 style="text-align: center;">PII Masking</h3>
                    <ul style="text-align: left;">
                        <li class="bullet-Point" style="padding-bottom: 10px;">
                            We support a wide range of document formats to mask aadhaar. From JPG, PNG through to PDF and TIFF files.						<li class="bullet-Point">
                            Immediately mask Aadhaar images at the point of document capture in your App or your Web portal.	
                        </li>
                    </ul>
                    <div class="banner-btn">
                        <a href="https://piimasking.digivision.ai/" target="_blank" class="default-btn p-2">
                            Visit Product Website <span></span>
                        </a> 
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="/assets/img/TrustTier/Legal_documet.png" alt="image">
                    </div>
                    <h3 style="text-align: center;">LDRS</h3>
                    <ul style="text-align: left;">
                        <li class="bullet-Point"  style="padding-bottom: 10px;">A systematic framework for legal professionals to efficiently manage and explore cases, enhancing productivity and outcomes.</li>
                        <li class="bullet-Point">All legal cases centralized for easy access to data, dates, and documents.</li>
                    </ul>
                    <div class="banner-btn">
                        <a href="https://los.digivision.ai/" target="_blank" class="default-btn p-2">
                            Visit Product Website <span></span>
                        </a> 
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="/assets/img/TrustTier/VCIP.jpeg" alt="image">
                    </div>
                    <h3 style="text-align: center;">V-CIP Auditor</h3>
                    <ul style="text-align: left;">
                        <li class="bullet-Point" style="padding-bottom: 10px;">VCIP auditor reduces manual effort and resource needs, leading to organizational cost savings.</li>
                        <li class="bullet-Point">
                            Features include <span class="liveliness-effect" style="transform: none;">liveliness checks</span> and government-mandated verification of video, audio and document.  
                        </li>                                      
                         </ul>
                    <div class="banner-btn">
                        <a href="https://pivc.digivision.ai/" target="_blank" class="default-btn p-2">
                            Visit Product Website <span></span>
                        </a> 
                    </div>

                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="/assets/img/TrustTier/financial_prevent.jpg"alt="image" style="width: 215px;">
                    </div>
                    <h3 style="text-align: center;">FCPS</h3>
                    <ul style="text-align: left;">
                        <li class="bullet-Point" style="padding-bottom: 10px;">Financial Crime Prevention System ensure compliance with regulations and best.</li>
                        <li class="bullet-Point">Streamlines workflows, reduces manual intervention, minimizes human error, and ensures consistent, efficient fraud detection.</li>
                    </ul>
                    <div class="banner-btn">
                        <a href="https://demo.digivision.ai/fcpg/" target="_blank" class="default-btn p-2">
                            Visit Product Website <span></span>
                        </a> 
                    </div>

                </div>
            </div>



            <!-- <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="/assets/img/TrustTier/liveliness_new.jpg" alt="image">
                    </div>
                    <h3 style="text-align: center;">Liveness Check</h3>
                    <ul style="text-align: left;">
                        <li class="bullet-Point" style="padding-bottom: 5px;">
                            Detects facial movements to enhance verification by analyzing live expressions.
						</li>
						<li class="bullet-Point">
                            Utilizes behavioral biometrics to detect unique live behaviors like micro-expressions, enhancing verification accuracy.
						</li>
                    </ul>

                </div>
            </div> -->

        </div>
    </div>
</section>

<!-- End -->



<!-- Benifits of TrustAI -->

<div class="bd-featured-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <strong><h2> <span class="highlight" style="transform: none;">TrustTier</span> Benefits</h2></strong>
           
        </div>
        
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/Enhanced_Compliance.gif" class="img-width" alt="image">
                    </div>
                    <h3>Enhanced Compliance</h3>
                    <p>
                        TrustTier ensures regulatory compliance through real-time monitoring, alerts, and automated issue resolution.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/audits.gif" class="img-width" alt="image">
                    </div>
                    <h3>Faster Audits</h3>
                    <p style="margin-bottom: 28px;">
                        Automation software mitigates risks by providing compliance insights and enabling proactive management strategies.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/risk_management.gif" class="img-width" alt="image">
                    </div>
                    <h3>Risk Management</h3>
                    <p>Trusttier fosters auditor collaboration through a centralized platform for communication and workflow management.</p>
                </div>
            </div> 

            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/document-ai.gif" class="img-width" alt="image">
                    </div>
                    <h3>Improved Visibility and Reporting</h3> 
                    <p>TrustTier offers real-time compliance insights for improved decision-making and stakeholder updates.</p>
                </div>
            </div> 
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/customize_solution.gif" class="img-width" alt="image">
                    </div>
                    <h3>Customizable Solutions</h3> 
                    <p style="margin-bottom: 27px;">TrustTier AI offers customizable, scalable solutions tailored to diverse business needs.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/customized_solution.gif" class="img-width" alt="image">
                    </div>
                    <h3>Seamless Integration</h3> 
                    <p style="margin-bottom: 27px;">TrustTier AI integrates seamlessly with existing systems, minimizing disruption and maximizing efficiency.</p>
                </div>
            </div> 
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/cost_saving.gif" class="img-width" alt="image">
                    </div>
                    <h3>Reduced Operational Costs</h3> 
                    <p>TrustTier AI automates processes, cutting costs for compliance, risk management, and document review.</p>
                </div>
            </div> 
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="/assets/img/TrustTier/customer_trust.gif" class="img-width" alt="image">
                    </div>
                    <h3>Enhanced Customer Trust</h3> 
                    <p >TrustTier AI's solutions boost security, compliance, and customer privacy, fostering trust and loyalty.</p>
                </div>
            </div> 

        </div>
    </div>
</div>