<app-navbar></app-navbar>

 
<!-- Start Main Banner Area -->
<div class="machine-learning-slider owl-carousel owl-theme dms-banner-margin">
    <div class="dms-machine-learning-banner-1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center banner1-margin-top">
                        <div class="col-lg-7 dms-text-margin-top">
                            <div class="banner-content"> 
                                <span class="sub-title">Next-Gen Cloud-Agnostic Document Management System</span>
                                <h1>Making <span class="highlight"><u>Paradigmatic Shift </u></span> in <span>Document Management</span> </h1>
                                <p>An AI-powered advanced system for managing documents, which effectively organizes and brings
                                    structure to unorganized data. It provides a secure platform for storing, accessing, and sharing
                                    all your files conveniently in a single location.</p>
            
                                <!-- <ul class="banner-btn">
                                    <li>
                                        <a href="https://digidrive.ai/" target="_blank" class="default-btn">
                                            Visit Product Website <span></span>
                                        </a>
                                    </li> 
                                </ul> -->
                                <div class="banner-btn">
                                    <a href="https://digidrive.ai/" target="_blank" class="default-btn me-4">
                                        Visit Product Website <span></span>
                                    </a> 
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-5 col-md-12">
                            <div class="bd-banner-image">
                                <img src="assets/img/digidrive-2.svg" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
            
                                <div class="circle-shape"></div>
                            </div>
                        </div> -->
                        <!-- <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/digidrive-2.svg" alt="image">
                            </div>
                        </div> -->
                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/digidrive-2.svg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center dms-banner2-row">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <span class="sub-title">Empowering Mobile Efficiency</span>
                                <h1>Our <span class="highlight"><u>Mobile App </u></span> Unveiled!</h1>
                                <p>Our Digidrive DMS app revolutionizes document management, enabling offline KYC scans and smooth offline data sync. With advanced features like auto-capture and edge detection, it simplifies processes like Auto classification, offering unparalleled convenience.</p>
                             
                                <div class="banner-btn">
                                    <a href="https://digidrive.ai/" target="_blank" class="default-btn me-4 burger-menu">
                                        Visit Product Website <span></span>
                                    </a> 
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/DMS-MobileApp.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7 dms-banner2-col7">
                            <div class="banner-content">
                                <!-- <img src="assets/img/new-launch.gif" style="max-width: 14%;" alt="image"> -->
                                <kbd class="NewLaunchLabel">  NEW LAUNCH  </kbd>
                                <!-- <span class="sub-title">NEW LAUNCH</span> -->
                                <h1> Introducing Our <span class="highlight"><u>DMS SaaS </u> </span> Solution</h1>
                                <p>As we reveal the potential of our all-inclusive Document Management System (DigiDrive DMS) SaaS across all channels and platforms, come experience its power. Use our solution to handle documents with ease and ensure productivity in all areas of your business.
                                    Come along with us as we transform document management for the contemporary age.</p> 
                                    <div class="banner-btn">
                                        <a href="https://digidrive.ai/" target="_blank" class="default-btn me-4 burger-menu">
                                            Visit Product Website <span></span>
                                        </a> 
                                    </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                              <div class="animate-image">
                                <img class="saas-img-size" src="assets/img/digidrive-2.svg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
       <!-- <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>-->
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
       <!-- <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>-->
    </div>

    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <!-- <img src="assets/img/new-launch.gif" style="max-width: 14%;" alt="image"> -->
                                <!-- <span class="sub-title">NEW LAUNCH</span> -->
                                <h1> Optimise your storage space <span class="highlight"><u>Upto 80% </u> </span></h1>
                                <p> Our handcrafted Digimagica compression technology can reduce file sizes by up to 80%, leading to cost optimization.</p> 
                                    <div class="banner-btn">
                                        <a href="https://digidrive.ai/" target="_blank" class="default-btn me-4 burger-menu">
                                            Visit Product Website <span></span>
                                        </a> 
                                    </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                              <div>
                                <img src="assets/img/file-compress.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
       <!-- <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>-->
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
       <!-- <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>-->
    </div> 
</div>
<!-- End Main Banner Area -->





<div class="bd-banner-section" style="display:none;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-content">
                    <span class="sub-title">Next-Gen Centrailized Document Management System </span>
                    <h1>Making Paradigmatic Shift in   <span>Document Management</span> </h1>
                    <p>An advanced system for managing documents, which effectively organizes and brings
                        structure to unorganized data. It provides a secure platform for storing, accessing, and sharing
                        all your files conveniently in a single location.</p>

                    <ul class="banner-btn">
                        <li>
                            <a href="https://digidrive.ai/" target="_blank" class="default-btn burger-menu">
                                Get Started <span></span>
                            </a>
                        </li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-banner-image">
                    <img src="assets/img/digidrive-2.svg" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">

                    <div class="circle-shape"></div>
                </div>
            </div>
        </div>
    </div>

  <!--   <div class="bd-banner-shape-1">
        <img src="assets/img/big-data-home/banner/shape-1.png" alt="image">
    </div>
   <div class="bd-banner-shape-2">
        <img src="assets/img/big-data-home/banner/shape-2.png" alt="image">
    </div>
    <div class="bd-banner-shape-3">
        <img src="assets/img/big-data-home/banner/shape-3.png" alt="image">
    </div> -->
</div>
<!-- End Big Data Banner Area -->


<!-- Start main Featured Area -->
<div class="bd-featured-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-line-text-with-white-color">
            <h2>Explore the essential features of our document management.</h2>
            <p>Efficiently organize, access, and collaborate with our cloud-powered document management system.</p>
        </div>
        
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/scan.gif" class="img-width" alt="image">
                    </div>
                    <h3>Scanner Utility (web and windows)</h3>
                    <p>Improve document management with our flexible Scanner Utility, which works seamlessly on Windows and the web. Digitize and easily arrange your documentation to improve productivity throughout your DMS ecosystem.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/artificial-intelligence.gif" class="img-width" alt="image">
                    </div>
                    <h3>AI based Indexing & classification, content extraction</h3>
                    <p>AI-powered indexing and classification streamline document organization and retrieval, while content extraction ensures pertinent information is captured accurately."</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/search.gif" class="img-width" alt="image">
                    </div>
                    <h3>Intelligent search via content keywords</h3>
                    <p>Boost your search with clever algorithms that quickly and accurately retrieve documents from our DMS by sifting through information using keywords.Find documents quickly and intuitively with our smart search feature</p>
                </div>
            </div> 

            <div class="col-lg-3 col-sm-6">
                <div class="bd-featured-card text-center">
                    <div class="featured-image">
                        <img src="assets/img/big-data-home/featured/workflow.gif" class="img-width" alt="image">
                    </div>
                    <h3>In built Workflow Management & escalation matrix </h3> 
                    <p>Discover how integrated escalation mechanisms and workflow management operate flawlessly.Our DMS also offers cloud-friendly architecture and cross-platform support</p>
                </div>
            </div> 
        </div>
    </div>

    <div class="bd-featured-shape">
        <img src="assets/img/big-data-home/featured/shape.png" alt="image">
    </div>
</div>
<!-- End BD Featured Area -->

<!-- Start BD About Area -->
<div class="bd-about-area">
    <div class="container" style="display:none;">
        <div class="row align-items-center"><div class="col-lg-12"><div class="section-title with-line-text-with-white-color"><span class="sub-title text-center">ABOUT DIGIDRIVE</span>
            <h3>Efficiently store, organize, and collaborate with our cloud-based document management system.</h3>
            <p>We collaborate closely with industry leaders to redefine the possibilities and unlock the full potential of digital engagements.</p>
        </div></div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-image">
                    <img src="assets/img/big-data-home/about/about.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-1.png" alt="image">
                        </div>
                        <h4>Licensing implications</h4>
                        <p>Ensure compliance and simplify licensing with our comprehensive document management system.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-2.png" alt="image">
                        </div>
                        <h4>Application Integrations</h4>
                        <p>Seamlessly integrate with your favourite applications and enhance productivity with our versatile document management system.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-3.png" alt="image">
                        </div>
                        <h4>Security & Audit control deficiencies</h4>
                        <p>Mitigate security risks and maintain robust audit control with our secure document management system.</p>
                    </div>
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img src="assets/img/big-data-home/about/image-3.png" alt="image">
                        </div>
                        <h4>Data Privacy regulations</h4>
                        <p>Ensure compliance with data privacy regulations and protect sensitive information with our trusted document management system.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bd-about-shape">
        <img src="assets/img/big-data-home/about/shape.png" alt="image">
    </div>
</div>
<!-- End BD About Area -->   

<!-- Ml Services Area -->
<section class="ml-services-area bg-f2f2f7 pt-100 pb-70" style="display:none;">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Professionals Services For You</h2>
            <p class="d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/auto_archive.svg" alt="image">
                    </div>
                    <h3>Auto Archive for shared folders</h3>
                    <p>Effortlessly maintain shared folders' organization and optimize storage space with our automated archiving feature in the document management system.</p>
                 
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/Intelligent_Search.svg" alt="image">
                    </div>
                    <h3>Intelligent Search via content-keywords</h3>
                    <p>Quickly locate relevant documents with our document management system's intelligent search, powered by content keywords.</p>
                    
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/AI-based.svg" alt="image">
                    </div>
                    <h3>AI-based Indexing & Classification</h3>
                    <p>Automatically identify the document type and classify it into the appropriate folder using our advanced classification & indexing system.</p>
                    
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/Version_Control.svg" alt="image">
                    </div>
                    <h3>Version Control Capability</h3>
                    <p>Maintain control and track document versions effectively with our robust version control feature</p>
                   
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/AI-based.svg" alt="image">
                    </div>
                    <h3>AI-based Content Extraction & Meta Data Creation</h3>
                    <p>Effortlessly extract and create metadata from documents using AI-based content extraction</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/Bulk-Upload.svg" alt="image">
                    </div>
                    <h3>Bulk Upload</h3>
                    <p>Save time and streamline the document import process with our convenient bulk upload feature</p>
                   
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/Securing-documents.svg" alt="image">
                    </div>
                    <h3>Securing documents & data</h3>
                    <p>Ensure the utmost security and protection of your documents and data with our comprehensive document management system.</p>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/big-data-home/Efficient-storage.svg" alt="image">
                    </div>
                    <h3>Efficient storage</h3>
                    <p>Optimize storage capacity and maximize efficiency</p>
                </div>
            </div>
            
           <!--<div class="col-lg-4 col-md-6">
                <div class="ml-service">
                    <div class="image">
                        <img src="assets/img/machine-learning/ml-service6.png" alt="image">
                    </div>
                    <h3><a routerLink="/service-details">Bulk Upload</a></h3>
                    <p>Effortlessly retrieve multiple documents at once with our convenient bulk download feature in the document management system</p>
                </div>
            </div>--> 
        </div>
    </div>
</section>
<!-- End Ml Services Area -->

<!-- Start sass solution Area -->
<div class="bd-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-content">
                    <kbd class="NewLaunchLabel">  NEW LAUNCH  </kbd>
                    <!-- <p>Unveiling Efficiency</p> -->
                    <h3>Introducing Our New <span class="highlight"><u>Digi Drive DMS SaaS </u></span> Solution to Streamline Your Workflow.</h3>
                    <!-- <p>DigiDrive offers a comprehensive, secure, and user-friendly document management solution that boosts productivity, collaboration, and data management efficiency for your organization.</p> -->

                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="list">
                                <li><i class="fa-solid fa-check lead"></i>Access Anytime, Anywhere: <p class="small">Our Digi Drive DMS SaaS brings your documents to your fingertips, wherever you are.</p></li>
                                <li><i class="fa-solid fa-check"></i>Secure Data Management:<p class="small"> Protect your sensitive information with our robust encryption and security features.</p></li>
                                <li><i class="fa-solid fa-check"></i>Simplified Document Organization:<p class="small">Easily organize and categorize your documents for efficient retrieval.</p></li>
                                 <li><i class="fa-solid fa-check"></i>Power Your Productivity:<p class="small">Introducing Our Digi Drive DMS SaaS Across All Platforms</p> </li>
                                 <li><i class="fa-solid fa-check"></i>Automated Workflow:<p class="small" >Boost efficiency with automated processes for document routing and approvals.</p> </li>
                            </ul> 
                        </div>
                        <!-- <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Scalability</li>
                                <li><i class="fa-solid fa-check"></i>Integration-Friendly</li>
                                <li><i class="fa-solid fa-check"></i> User-Friendly Interface</li>
                                <li><i class="fa-solid fa-check"></i>Automation and Workflow</li>
                                <li><i class="fa-solid fa-check"></i> Reliable Customer Support</li>
                            </ul>
                        </div> -->
                    </div>
                    <div >
                        <a href="https://digidrive.ai/" target="_blank" class="default-btn burger-menu mt-4">
                           For More Info<span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-image">
                    <img src="assets/img/big-data-home/banner/main.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End sass solution Area -->

<!-- Start Plans section -->
 <section _ngcontent-ng-c151934761="" class="pricing-area-two pt-100 pb-70 bg-black">

    <div class="container"> 
    </div>
    <div _ngcontent-ng-c151934761="" class="container">
        <div _ngcontent-ng-c151934761="" class="section-title">

            <h2 _ngcontent-ng-c151934761="" class="text-white">Choose the plan that best meets your needs.</h2>
            <p _ngcontent-ng-c151934761="" class="text-white"> Contact sales@digidrive.ai for questions.</p>
        </div>


        <div _ngcontent-ng-c151934761="" class="row justify-content-center">
            <div _ngcontent-ng-c151934761="" class="col-lg-4  col-sm-12">
                <div _ngcontent-ng-c151934761="" class="single-pricing-table text-left">
                    <div _ngcontent-ng-c151934761="" class="pricing-header">
                    </div>
                    <div class="row">
                        <div class="col-7">
                        <p _ngcontent-ng-c151934761="" class="mb-4 no-margin-to-p">
                            <kbd _ngcontent-ng-c151934761="" class="bg-maincolor mb-4">DMS Lite</kbd> 
                        
                        </p></div>
                        <div class="col-5">
                            <img src="assets/img/SAAS-based.png" style="float: right;" class="saas-sticker" width="150" alt="image">
                        </div>
                    </div>
                    <!-- <h2 _ngcontent-ng-c151934761=""> Rs 2323</h2> -->
                    <!-- <p _ngcontent-ng-c151934761="" class="mb-0 no-margin-to-p"> <small>+taxes<br />Monthly</small></p> -->
                    <ul _ngcontent-ng-c151934761="" class="dms-pricing">
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i><b>150 GB</b> storage</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Up to <b>5</b>  users </li>

                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Approvals &amp; Workflows</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>API</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Image Compression</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Retention Automation</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Auto Classification and Bucketing</li>
                       
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Granular Access Management</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Aadhaar/PII Masking</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Web Based Scanner</li>
                        
                    </ul>
                    <!-- <div class="text-center">
                        <button class="btn btn-lg btn-danger rounded-pill  px-5" data-toggle="modal" data-target="#myModal">SELECT</button>
                    </div> -->
                </div>
            </div>

            <div _ngcontent-ng-c151934761="" class="col-lg-4 col-sm-12">
                <div _ngcontent-ng-c151934761="" class="single-pricing-table text-left">
                    <div _ngcontent-ng-c151934761="" class="pricing-header">
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <p _ngcontent-ng-c151934761="" class="mb-4 no-margin-to-p">
                                <kbd _ngcontent-ng-c151934761="" class="bg-maincolor mb-4">DMS Standard</kbd> 
                            </p>
                        </div>
                        <div class="col-5">
                            <img src="assets/img/SAAS-based.png" style="float: right;" class="saas-sticker" width="150" alt="image">
                        </div>
                    </div>
                    
                    <!-- <h2 _ngcontent-ng-c151934761="" class="no-margin-to-p"> Rs 6971</h2> -->
                    <!-- <p _ngcontent-ng-c151934761="" class="no-margin-to-p"> <small>+taxes<br />Monthly</small></p> -->
                    <ul _ngcontent-ng-c151934761="" class="dms-pricing">
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i><b>500 GB</b> storage</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Up to <b>20</b>  users </li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Approval Workflow upto 2 Levels</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>API</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Image Compression</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Retention Automation</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Auto Classification and Bucketing</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Granular Access Management</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Aadhaar/PII Masking</li>
                        <li _ngcontent-ng-c151934761="" class="disabled"><i _ngcontent-ng-c151934761="" class="bx bx-badge-check disabled"></i>Web Based Scanner</li>
                        
                    </ul>
                    <!-- <div class="text-center">
                        <button class="btn btn-lg btn-danger rounded-pill  px-5" data-toggle="modal" data-target="#myModal">SELECT</button>
                    </div> -->
                </div>
            </div>
            <div _ngcontent-ng-c151934761="" class="col-lg-4 col-sm-12">
                <div _ngcontent-ng-c151934761="" class="single-pricing-table text-left">
                    <div _ngcontent-ng-c151934761="" class="pricing-header">
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <p _ngcontent-ng-c151934761="" class="mb-4 no-margin-to-p">
                                <kbd _ngcontent-ng-c151934761="" class="bg-maincolor mb-4">DMS Enterprise</kbd> 
                            </p>
                        </div>
                        <div class="col-5">
                            <img src="assets/img/ON-Premise.png" style="float: right;" class="saas-sticker" width="150" alt="image">
                        </div>
                    </div>
                    <!-- <h2 _ngcontent-ng-c151934761="" class="mb-0 no-margin-to-p"> Rs 15798</h2> -->
                    <!-- <p _ngcontent-ng-c151934761="" class="mb-0 no-margin-to-p"> <small>+taxes<br />Monthly</small></p> -->
                    <ul _ngcontent-ng-c151934761="" class="dms-pricing">
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i><b>Custom</b> storage</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i><b>Unlimited</b>  Users </li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Approvals &amp; Dynamic Workflow</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>API</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Image/Video Compression</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Retention Automation</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Auto Classification and Bucketing</li>
                        
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Granular Access Management</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Aadhaar/PII Masking</li>
                        <li _ngcontent-ng-c151934761=""><i _ngcontent-ng-c151934761="" class="bx bx-badge-check"></i>Web Based Scanner</li>
                        
                    </ul>
                    <!-- <div class="text-center">
                        <button class="btn btn-lg btn-danger rounded-pill  px-5" data-toggle="modal" data-target="#myModal">SELECT</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section> 
<!-- End Plans -->



<!-- Start BD Overview Area -->
<div class="bd-overview-area pt-100 pb-70 jarallax d-none" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="bd-overview-content">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-1.png" alt="image">
                        </div>
                        <h3>Safe And Secure</h3>
                        <p>Ensure the utmost safety and security of your documents with our robust and reliable Digi Drive DMS.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-2.png" alt="image">
                        </div>
                        <h3>Operations Manage</h3>
                        <p>Efficiently manage your document.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-3.png" alt="image">
                        </div>
                        <h3>Experts Support</h3>
                        <p>Get professional assistance and expert guidance throughout your entire journey.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="bd-overview-card">
                        <div class="overview-image">
                            <img src="assets/img/big-data-home/overview/image-4.png" alt="image">
                        </div>
                        <h3>Risk Management</h3>
                        <p>Effectively mitigate risks and ensure compliance with our comprehensive document management system's risk management capabilities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End BD Overview Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100 d-none">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">FAQ</span>
            <h2>Frequently Asked Questions</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-img pr-3">
                    <img src="assets/img/faq.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
           
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How to get start with us?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Subscribe Area -->
<section class="free-trial-area ptb-100 bg-f4f7fe d-none">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="ft-img">
                    <img src="assets/img/machine-learning/free-trial-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="subscribe-content">
                    <span class="sub-title">Get Started Instantly!</span>
                    <h2>Start your free trial</h2>

                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="email">
                        <button type="submit">Sign Up Free</button>
                    </form>

                    <p class="help-text">Test out the Machine Learning features for 14 days, no credit card required.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="d-flex justify-content-center mt-5">
        <div class="h-50"><h1>READY TO TAKE NEXT STEP? </h1></div>
     </div>
     <div class="d-flex justify-content-center mb-5">
        <div class="banner-btn">
            <a class="default-btn me-4 burger-menu">
                CONTACT SALES <span></span>
            </a>
        </div>
     </div>
</div>


<!-- End Subscribe Area -->
<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/TrueCheck" ><i class="flaticon-left-chevron"></i>Trucheck and Identity Verification  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Compression-Solution">Compression Solution<i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>
