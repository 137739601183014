<app-navbar></app-navbar>
<!-- Start Main Banner Area -->
<div class="it-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row"><div class="col-md-12">&nbsp;</div></div>
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content mt-20">
                            <h2 class="mt-20">Register with us</h2>
                            
                            <div class="contact-form p-0">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <input type="text" name="name" id="name" class="form-control" placeholder="First Name">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <input type="text" name="name" id="name" class="form-control" placeholder="Last Name">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                            </div>
                                        </div>
            
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone Number">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <select class="form-control"><option>Select Product or Services</option><option>Digivision.ai - AI & Computer Vision</option><option>Digidrive.ai - Disrupting DMS</option><option>Secure Nexus - Cyber Security</option></select>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Company Name">
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
        
                                    </div>
                                </form>
                            </div>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn me-3">
                                    Submit <span></span>
                                </a>
     
                                <a href="#" class="video-btn popup-youtube ml-25">
                                   
                                    Reset
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="it-banner-image owl-carousel owl-theme">
                            <div class="animate-image">
                                <img src="assets/img/form.svg" alt="image">
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
   
</div>
<!-- End Main Banner Area -->

