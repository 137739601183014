<app-navbar></app-navbar>


<!-- Start Cyber Security Banner Area -->
<div class="cs-banner-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="cs-banner-content">
                    <span class="sub-title d-none">Your Cyber Risk Shield</span>
                    <h1>Secure the Digital Nexus of Forces</h1>
                    <p>Experts in Application, Infrastructure, and Cloud Security assessments. Protecting your digital assets with precision and expertise.</p>

                    <ul class="banner-btn">
                        <li>
                            <a class="default-btn burger-menu">
                                Get Started <span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.securenexus.io/" target="_blank" class=" default-btn mt-4">
                                Visit Our Website <span></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="cs-banner-image">
                    <img src="assets/img/cyber-security-home/banner/main.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="cs-lock">
        <img src="assets/img/cyber-security-home/banner/lock.png" alt="image">
    </div>
</div>

<!-- End Cyber Security Banner Area -->

<div class="background-with-black-color">

    <!-- Start CS About Area -->
    <div class="cs-about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-image">
                        <img src="assets/img/cyber-security-home/about.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-content">
                        <span class="sub-title">ABOUT SECURENEXUS</span>
                        <h3>Securing Your Nexus of Forces</h3>
                        <p>SecureNexus specializes in Cyber Security, offering VAPT, Red Teaming, OSINT, Source code review, threat modeling, Cloud Security Assessment, and more. With our proprietary products and advanced threat intelligence, we provide enhanced services for top-notch security.</p>

                        <!-- <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-lock"></i>
                                    </div>
                                    <h4>Quality Team</h4>
                                    <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-shield"></i>
                                    </div>
                                    <h4>High Quality Output</h4>
                                     <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="about-btn">
                            <a class="default-btn burger-menu">
                                Get Started with free POC <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

       <!-- Start CS Services Area -->
       <div class="cs-services-area pt-100 pb70">
        <div class="container">
            <div class="section-title with-line-text">
                <span class="sub-title">OUR SERVICES</span>
                <h2>The Power To Protect Your <span>Cyberspace</span> & Complete End-To-End Security</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-2.png" alt="image">
                        </div>
                        <h3  class="text-white">
                            Application Security
                        </h3>
                        <p>Protect your applications against vulnerabilities and threats with our comprehensive security assessments.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-1.png" alt="image">
                        </div>
                        <h3 class="text-white">
                            Infrastructure Security
                        </h3>
                        <p>Ensure the security and resilience of your infrastructure with our advanced security asessments.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-3.png" alt="image">
                        </div>
                        <h3 class="text-white">
                            Cloud Security
                        </h3>
                        <p>Assess and strengthen the security of your cloud environment with our expert assessment and recommendations.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-5.png" alt="image">
                        </div>
                        <h3 class="text-white">
                            Source Code Review
                        </h3>
                        <p>Detect and address security flaws in your source code, preventing potential vulnerabilities and breaches.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-4.png" alt="image">
                        </div>
                        <h3 class="text-white">Red Teaming
                        </h3>
                        <p> Assist clients in uncovering vulnerabilities and evaluating the effectiveness of their security measures through simulated real-world and goal driven attacks.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-6.png" alt="image">
                        </div>
                        <h3 class="text-white">Threat Modeling
                        </h3>
                        <p>Proactively collaborate with clients to identify potential threats and vulnerabilities early in the development lifecycle with effective risk mitigation.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-7.png" alt="image">
                        </div>
                        <h3 class="text-white">Cyber Security Advisory
                        </h3>
                        <p>Provide expert guidance and strategic advice to enhance your cyber security posture.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-services-card text-center">
                        <div class="image">
                            <img src="assets/img/cyber-security-home/services/services-8.png" alt="image">
                        </div>
                        <h3 class="text-white">API Security
                        </h3>
                        <p>Provide API security assessment, including API gateway governance.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Services Area -->
    <!-- End CS About Area -->

    <!-- Start CS Choose Area -->
    <div class="cs-choose-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-content">
                        <span class="sub-title">WHY CHOOSE SECURE NEXUS</span>
                        <h3>Your Cyber Risk Shield -  Choose SecureNexus for <span>Unmatched</span> Cyber Security</h3>
                        <p>Enhance your cyber resilience with our tailored strategies and proactive approach—partner with SecureNexus to safeguard your digital assets.</p>

                        <ul class="list">
                            <li><i class="fa-solid fa-check"></i> Industry-leading professionals ensuring exceptional security.</li>
                            <li><i class="fa-solid fa-check"></i> Holistic approach covering all security aspects.</li>
                            <li><i class="fa-solid fa-check"></i> Trusted choice with successful outcomes.</li>
                        </ul>
                        <div class="choose-btn">
                            <a  class="default-btn burger-menu">
                                Get Started <span></span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-image">
                        <img src="assets/img/cyber-security-home/choose.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Choose Area -->

 

    <!-- Start CS Fun Facts Area -->
    <section class="cs-fun-facts-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">01.</div>
                        <h3><span class="odometer" data-count="200">00</span><span class="sign-icon">+</span></h3>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">02.</div>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>All Time Clients</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">03.</div>
                        <h3><span class="odometer" data-count="200">00</span><span class="sign-icon">+</span></h3>
                        <p>Attacks Blocked</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">04.</div>
                        <h3><span class="odometer" data-count="60">00</span><span class="sign-icon">+</span></h3>
                        <p>Experts Team</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End CS Fun Facts Area -->

    <!-- Start Leading Technology Area -->
    <!-- <div class="leading-technology-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-image">
                        <img src="assets/img/cyber-security-home/leading-technology.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-content">
                        <span class="sub-title">LEADING TECHNOLOGY FIRM</span>
                        <h3>Provide Advanced <span>Security</span> For Threat And Effective Protection</h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Detection</li>
                                    <li>Managing Cloud Security</li>
                                    <li>Website Hack Repair</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Trafic Analysis</li>
                                    <li>Hybrid Cloud Security</li>
                                    <li>24/7 Security Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Leading Technology Area -->

    <!-- Start CS Overview Area -->
    <div class="cs-overview-area pb-70 pt-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Personal Online Protection Everywhere We Go</h3>
                                    <a class="get-started-btn burger-menu">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Simple Solution For Your Business's Security</h3>
                                    <a class="get-started-btn burger-menu">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Overview Area -->

</div>

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area with-black-color d-none">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>

            <div class="subscribe-shape-1">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-1.png" alt="image">
            </div>
            <div class="subscribe-shape-2">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-2.png" alt="image">
            </div>
            <div class="subscribe-shape-3">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-3.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->