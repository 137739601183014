<app-navbar></app-navbar>


<!-- Start Main Banner Area -->
<div class="machine-learning-slider owl-carousel owl-theme ">
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                
                                <!-- <span class="sub-title">Next-Gen Cloud-Agnostic Document Management System</span> -->
                                <h1> Evolution in <span class="highlight"><u>Cutting Edge </u></span> <br><span>Document Processing</span> </h1>
                                <p>Experience the next evolution in document processing with xBiz Offline Vision Engine. Powered by cutting-edge AI technology, our solution transcends the limitations of traditional OCR by seamlessly handling non-structured documents, including those with tabular data such as bank financial documents and invoices.</p>
            
                                
                                <div class="banner-btn">
                                    <a href="https://xbizocr.digivision.ai/" target="_blank" class="default-btn me-4 burger-menu">
                                        Visit Product Website <span></span>
                                    </a> 
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-5 col-md-12">
                            <div class="bd-banner-image">
                                <img src="assets/img/digidrive-2.svg" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
            
                                <div class="circle-shape"></div>
                            </div>
                        </div> -->
                        <!-- <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/digidrive-2.svg" alt="image">
                            </div>
                        </div> -->
                        <div class="col-lg-5">
                            <div>
                                <img src="assets/img/Offline Vision Engine image_ocr.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <!-- <span class="sub-title">Empowering Mobile Efficiency</span> -->
                                <h1>Empower Your Business with <span class="highlight"><u>Cost-Effective </u></span> OCR Solutions!</h1>
                                <p>Say goodbye to unpredictable expenses with our fixed-cost OCR solutions. Our innovative technology offers unparalleled efficiency and accuracy at a low and fixed cost, you can achieve significant cost savings by reducing the need for manual data entry and streamlining document processing tasks. By automating repetitive tasks with offline vision engine.</p>
                             
                                <div class="banner-btn">
                                    <a href="https://xbizocr.digivision.ai/" target="_blank" class="default-btn me-4 burger-menu">
                                        Visit Product Website <span></span>
                                    </a> 
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/Data extraction-bro.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
</div>


<!-- Start Main Banner Area -->
<!-- <div class="main-banner  pb-30">
    <div class="container">
        <div class="main-banner-content">
            <span class="sub-title">X-Biz DigiVision.ai</span>
            <h1>Offline Vision Engine</h1>
            <p>Over 10+ years of Artificial Intelligence & Machine Learning. Our products hold
                a competitive advantage in terms of delivering quality product at fixed cost.</p>
            <div class="btn-box d-none">
                <a routerLink="/" class="default-btn">Get Started <span></span></a>
            </div>
        </div>

     
    </div>


</div> -->
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="featured-services-area pb-30 ">
    <div class="container">
      
<div class="pb-30">
    <div class="about-inner-area">
        <div class="row justify-content-center">
           

            <div class="col-lg-6 col-md-6 col-sm-6 ">
                <div class="about-text card p-10">
                    <h3>Business challenges</h3>
                    <ul class="features-list">
                        <li><i class="flaticon-tick"></i>Other OCR providers have unhedged per page costing.</li>
                        <li><i class="flaticon-tick"></i> Document Detection/Identifying document type, accuracy seems low.</li>
                        <li><i class="flaticon-tick"></i> Camera mega pixels increases file size exponentially.</li>
                        <li><i class="flaticon-tick"></i> Companies have many customer documents in an uncompressed format,
                            which becomes difficult to store, process, extract & access.</li>
                        <li><i class="flaticon-tick"></i> Masking/Redaction over customers Aadhaar Card becomes challenging
                            over scanned document, handwritten Aadhaar number recognition, image
                            stored is tilted.</li>
                        <li><i class="flaticon-tick"></i> Companies face customer liveliness detection challenges, Matching
                            customers live face with submitted photo ID or KYC document Photo ID.</li>

                    </ul><p>&nbsp;</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="about-text card p-10">
                    <h3>Offline Vision Engine</h3>
                    <ul class="features-list">
                        <li><i class="flaticon-tick"></i>Fixed priced OCR engine with unlimited usage.</li>
                        <li><i class="flaticon-tick"></i> High accuracy document detection.</li>
                        <li><i class="flaticon-tick"></i> Compress images to upto 90% with no loss in image quality.</li>
                        <li><i class="flaticon-tick"></i> Mask/Redact customers Aadhaar Card & handwritten Aadhaar number
                            over desired templates. Validates authentic handwritten Aadhaar number
                            masking.</li>
                        <li><i class="flaticon-tick"></i> Bulk Aadhaar Masking for historic/stored repository images.</li>
                        <li><i class="flaticon-tick"></i> AI enabled liveliness detection check, match customers live face with
                            provided KYC document & provides a confidence level percentage,
                            Matching customers Photo ID provided over Application form with
                            submitted KYC document & provide result as PASS/FAIL.</li>
         

                    </ul>
                </div>
            </div>
        </div>
    </div>


 

    <div class="widget-area mt-30 card p-20 bg-F4F7FC mt-20"><section  class="widget widget_categories">
        <h3 class="widget-title">ROI</h3>
        <ul>  <li> Save upto 90% on storage cost.</li>
            <li> AI-ML enabled components.</li>
             <li>  Unlimited transaction.</li>
                 <li> Fixed pricing.</li>
         <li> High accuracy results.</li>
         
         </ul></section></div>


    


</div>


</div>
</section>



<!-- End Featured Services Area -->

<!-- New OCR Section  -->

<div class="bd-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-content">
                    
                    <!-- <p>Transforming documents into actionable digital data seamlessly, DigiVision offers an integrated and secure OCR system. Experience heightened productivity, collaboration, and data efficiency through our user-friendly platform, which centralizes essential features, placing document images at the heart of your streamlined workflow.</p> -->
                    <h3>xBiz offers an integrated <span class="highlight"><u>secure OCR system</u></span> enhances productivity and workflow.</h3>
                    <!-- <p>DigiDrive offers a comprehensive, secure, and user-friendly document management solution that boosts productivity, collaboration, and data management efficiency for your organization.</p> -->

                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-sm-12">
                            <ul class="list">
                                <li><i class="fa-solid fa-check lead"></i>Handwriting Recognition: <p class="small">OCR  solution manages handwritten content, ensuring accuracy and efficiency.</p></li>
                                <li><i class="fa-solid fa-check"></i>Multi-Language Support:<p class="small"> xBiz Offline OCR facilitates multi-language support, including regional Indian languages.</p></li>
                                <li><i class="fa-solid fa-check"></i>Cost Efficiency:<p class="small">xBiz offers fixed low-cost Offline OCR solutions, providing cost predictability and affordability.</p></li>
                                 <li><i class="fa-solid fa-check"></i>Non-Standard Document Processing:<p class="small">The system efficiently handles non-structured documents with tabular data such as bank financial documents and invoices, streamlining workflow and enhancing OCR advantage.</p> </li>
                                 
                            </ul> 
                        </div>
                        <!-- <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> Scalability</li>
                                <li><i class="fa-solid fa-check"></i>Integration-Friendly</li>
                                <li><i class="fa-solid fa-check"></i> User-Friendly Interface</li>
                                <li><i class="fa-solid fa-check"></i>Automation and Workflow</li>
                                <li><i class="fa-solid fa-check"></i> Reliable Customer Support</li>
                            </ul>
                        </div> -->
                    </div>
                    <div>
                        <a href="https://xbizocr.digivision.ai/" target="_blank" class="default-btn burger-menu mt-4">
                           For More Info<span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="bd-choose-image">
                    <img src="assets/img/ocr.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End OCR Section    Area -->


<section class="mt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12 ocr-main-banner-content"><h1>Vision Engine Components</h1></div>
        </div>
    </div>
</section>
<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/Document_Classification.svg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Suite - 01</span> -->
                    <h2>Digi AI KYC (Verification and Classification)</h2>
                    <p>Banks and online businesses are continually seeking methods to streamline and automate their Know Your Customer procedures. Using AI for KYC-Suite takes consumer identification to a new, automated level.</p>
 
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Auto classification on all type and variant for large data-block, zero False positive/ False negative.</span></li>
                        <li><span><i class="fas fa-check"></i> Identify document at source(Mobile, Tab, Laptop or PC).</span></li>
                        <li><span><i class="fas fa-check"></i>Eliminate intentional / unintentional, wrong / bogus document
                            submission.</span></li>
                            <li><span><i class="fas fa-check"></i> Inbuilt fraud prevention requirements, have made AI-KYC compliance durable for companies.</span></li>

                            
                    
                    </ul>
                    <div>
                        <a href="https://TrueCheck.ai" target="_blank" class="default-btn burger-menu mt-4">
                           For More Info<span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->


<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Combo - 02</span> -->
                    <h2>Masking (Aadhaar and Pan)</h2>
                    <p> AI-enabled Aadhaar masking is the use of AI algorithms to automatically redact or hide sensitive information,
                        such as the Aadhaar number, Custom Templates or digital photographs.
                        Enhance privacy and compliance by masking/redacting of
                        first 8 or 12 digits of Aadhaar number.</p>
                    <ul class="features-list">
                    <li><span><i class="fas fa-check"></i> Auto detect desired files from a cluster of images</span></li> 
                    <li><span><i class="fas fa-check"></i> On-the-fly masking of sensitive data on documents</span></li> 
                    <li> <span><i class="fas fa-check"></i> Redact also available on Video masking from VKYC, Training or other onboarding records.</span></li>
                    <li><span><i class="fas fa-check"></i> Configure masking for any document type</span></li> 
                    <li><span><i class="fas fa-check"></i> Supports both OCR and ICR (Handwritten number)</span></li> 
                    <li> <span><i class="fas fa-check"></i> Bulk processing over repository images</span></li> 
                    <li> <span><i class="fas fa-check"></i> QR Code Masking </span></li> 
                     

                    </ul>


                </div>
                <div>
                    <a href="https://piimasking.digivision.ai" target="_blank" class="default-btn burger-menu mt-4">
                       For More Info<span></span>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/aadhar_mask.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->
<!-- Start About Area -->
<section class="about-area ptb-100 mt-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/underwriting.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Combo - 03</span> -->
                    <h2>Automated underwriting (Eyeball elimination)</h2>
                    <p>Reduces manual effort by automating repetitive tasks on the basis of BRE rule. AI Driven automation in underwriting system brings complete risk visibility in to cases, thereby suggesting the best possible decisions to the underwriting system within no time.</p>

                       
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i>Eyeball underwriting elimination.</span></li>
                        <li><span><i class="fas fa-check"></i>Real-time verification and tamper detection.</span></li>
                        <li><span><i class="fas fa-check"></i>Service optimization, Cost optimization.</span></li>
                        <li><span><i class="fas fa-check"></i> 99% reduction in fraudulent activity.</span></li>
                        <li><span><i class="fas fa-check"></i>End-to-end automated journey, enhance compliance and auditing.</span></li>
             </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->


<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-60">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Combo - 04</span> -->
                    <h2>Digital Sign Verification AI</h2>
                    <p>Authenticate digitally signed documents validity.</p>
<ul class="features-list">
   <li><span><i class="fas fa-check"></i> Single & Multi party signature verification.</span></li> 
   <li><span><i class="fas fa-check"></i>Expiry verification.</span></li> 
   <li> <span><i class="fas fa-check"></i> Offline Solution.</span></li> 
</ul>


                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/digi-sign.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->


<!-- Start About Area -->
<section class="about-area ptb-60">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/Physical_Signature_Verification_AI.svg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Combo - 05</span> -->
                    <h2>Physical Signature Verification AI</h2>
                    <p>Effortless Sign Recognition: Experience seamless and efficient sign recognition capabilities, streamlining authentication processes. Our AI-powered system ensures swift and reliable identification, revolutionizing the way signatures are verified.</p>

                       
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Single & Multi party handwritten signature detection.</span></li>
                        <li><span><i class="fas fa-check"></i> Multi party signature verification or matching.</span></li>
                        <li><span><i class="fas fa-check"></i>Popular Offline Solution for Cheque and nach clearance.</span></li>
                        <li><span><i class="fas fa-check"></i>Embracing a Variety of Templates(Generic, ScanCopy, Camera Capture).</span></li>
                    
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->


<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Combo - 06</span> -->
                    <h2>Face Verification AI</h2>
                    <p>Face AI uses Smart Computer Programs to find and recognize faces in pictures or videos.
                        First, it spots a face in an image(face detection), and then
                        it figures out who that person is (face recognition).</p>
<ul class="features-list">
   <li><span><i class="fas fa-check"></i> Liveliness check and Emotion detection.</span></li> 
   <li><span><i class="fas fa-check"></i> Verification service for matching pic vs submitted documents.</span></li> 
   <li> <span><i class="fas fa-check"></i> Supports Photo ID based verification of an image file with other existing
    documents.</span></li> 
    <li> <span><i class="fas fa-check"></i> Tracking Human activity, authorization business process .</span></li> 
  

</ul>


                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/Face_Verification_AI.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>






<!-- End Why Choose Us Area -->
<div class="container">
    <div class="d-flex justify-content-center mt-5">
        <div class="h-50"><h1>READY TO TAKE NEXT STEP? </h1></div>
     </div>
     <div class="d-flex justify-content-center mb-5">
        <div class="banner-btn">
            <a class="default-btn me-4 burger-menu">
                CONTACT SALES <span></span>
            </a>
        </div>
     </div>
</div>
<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/EKYC" ><i class="flaticon-left-chevron"></i> Digi KYC Suite  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Video-KYC">Video KYC <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>