<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2 ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>SITEMAP</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sitemap</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    
</div>
<!-- End Page Title Area -->

<!-- Login Area -->
<div class="container ptb-100">
    <div class="row"><div class="col-lg-8">
<div class="tree ">
    <ul>
    
    <li><span><a  data-bs-toggle="collapse" data-bs-target="#home" aria-expanded="false" aria-controls="home"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder-open"></i></i> Home</a></span>
    <div id="home" class="collapse show">
    <ul>
        <li><span><a   data-bs-toggle="collapse" data-bs-target="#digivision" aria-expanded="false" aria-controls="digivision"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
            <i class="expanded"><i class="far fa-folder-open"></i></i> DigiVision</a></span>
               <ul><div id="digivision" class="collapse show">
                 <li><span><i class="far fa-file"></i><a href="/EKYC"> EKYC, CKYC & OKYC</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Offline-Vision-Engine"> Offline Vision Engine</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Video-KYC"> Video KYC</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Document-Indexing"> Document Indexing & Classification</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Document-Detection"> Document Detection Solution</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Identity-Management"> Identity Management & Authentication</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Redaction-Solution"> Redaction Solution</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Compression-Solution"> Compression Solution</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Location-Based-Solution">Location Based Solution</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/Object-Detection-Solution"> Object Detection Solution</a></span></li>
                 <li><span><i class="far fa-file"></i><a href="/iMagica-Suite"> iMagica Suite</a></span></li>
                 </div>
               </ul>
             </li>
     <li><span><a   data-bs-toggle="collapse" data-bs-target="#secure" aria-expanded="false" aria-controls="secure"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder"></i></i> Secure Nexus</a></span>
       <ul><div id="secure" class="collapse show">
         <li><span><i class="far fa-file"></i><a href="/SecureNexus-ISS"> Information Security Services</a></span></li>
         <li><span><i class="far fa-file"></i><a href="/SecureNexus-GRC"> Governance Risk & Compliance</a></span></li>
         </div>
       </ul>
     </li>
    
     <li><span><i class="far fa-file"></i><a href="/Digidrive"> DigiDrive</a></span></li>
    <li><span><a    data-bs-toggle="collapse" data-bs-target="#usecases" aria-expanded="false" aria-controls="usecases"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder"></i></i> Use Cases</a></span>
      <ul><div id="usecases" class="collapse show">
        <li><span><a    data-bs-toggle="collapse" data-bs-target="#banking" aria-expanded="false" aria-controls="banking"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder"></i></i> Banking & Finance</a></span>
           <ul><div id="banking" class="collapse show">
             <li><span><i class="far fa-file"></i><a href="/Banking"> Account Opening</a></span></li>
             <li><span><i class="far fa-file"></i><a href="/Banking"> Loan Automation</a></span></li>
             <li><span><i class="far fa-file"></i><a href="/Banking"> Re-KYC</a></span> </li></div>
           </ul>
          </li>
    
          <li><span><a data-bs-toggle="collapse" data-bs-target="#insurance" aria-expanded="false" aria-controls="insurance"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder"></i></i> Insurance</a></span>
           <ul><div id="insurance" class="collapse show">
            <li><span><i class="far fa-file"></i><a href="/Insurance"> Customer Onboarding</a></span></li>
            <li><span><i class="far fa-file"></i><a href="/Insurance"> Service Automation</a></span></li>
            <li><span><i class="far fa-file"></i><a href="/Insurance"> Video-KYC</a></span> </li></div>
           </ul>
          </li>
                            
         <li><span><a  data-bs-toggle="collapse" data-bs-target="#health" aria-expanded="false" aria-controls="health"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
    <i class="expanded"><i class="far fa-folder"></i></i> Healthcare</a></span>
           <ul><div id="health" class="collapse show">
            <li><span><i class="far fa-file"></i><a href="/Healthcare"> Patient Data Storage</a></span></li>
            <li><span><i class="far fa-file"></i><a href="/Healthcare"> Document Detection</a></span></li>
            <li><span><i class="far fa-file"></i><a href="/Healthcare"> Patient Onboarding</a></span></li></div>
           </ul>
         </li>
         <li><span><a   data-bs-toggle="collapse" data-bs-target="#tele" aria-expanded="false" aria-controls="tele"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
            <i class="expanded"><i class="far fa-folder"></i></i> Tele-Communication</a></span>
                   <ul><div id="tele" class="collapse show">
                    <li><span><i class="far fa-file"></i><a href="/Tele-com"> Security and Fraud Detection</a></span></li>
                    <li><span><i class="far fa-file"></i><a href="/Tele-com"> Real-Time Analytics</a></span></li>
                    <li><span><i class="far fa-file"></i><a href="/Tele-com"> Preventive Maintenance</a></span></li></div>
                   </ul>
                 </li>


       
         
         </div>
      </ul>
    </li>
    
    <li><span><a   data-bs-toggle="collapse" data-bs-target="#Company" aria-expanded="false" aria-controls="Company"><i class="collapsed"><i class="fas fa-folder-open"></i></i>
        <i class="expanded"><i class="far fa-folder"></i></i> Company</a></span>
               <ul><div id="Company" class="collapse show">
                <li><span><i class="far fa-file"></i><a href="/AboutUs"> About Us</a></span></li>
                <li><span><i class="far fa-file"></i><a href="/Career"> Career</a></span></li>
                <li><span><i class="far fa-file"></i><a href="/Press-Release"> Press Release</a></span></li></div>
               </ul>
             </li>
     <li><span><i class="far fa-file"></i><a class="burger-menu"> Request Demo</a></span></li>
    
    
    
    </ul>
    </div>
    </li>
    </ul>
    
    </div>
</div><div class="col-lg-4"><div class="widget-area">
    <section class="widget widget_categories">
        <h3 class="widget-title">Broken Link</h3>

        <ul>
           
            <li><a routerLink="/register">Register</a></li>
            <li><a routerLink="/sitemap">Sitemap</a></li>
        </ul>
    </section></div></div></div>
</div>
<!-- End Login Area -->