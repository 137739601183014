<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12"><h4>POPULAR<span> FEATURES & TOOLS</span></h4></div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget pl-5">
                    <h5 class="text-white">Banking & Finance</h5>
                    <ul class="footer-quick-links">
                        <!-- <li><i class="fa-solid fa-chevron-right"></i> <a routerLink="/Banking">Account Opening</a></li> -->
                        <li><i class="fa-solid fa-chevron-right"></i> <a routerLink="/Banking">Loan Automation System</a></li>
                        <li><i class="fa-solid fa-chevron-right"></i><a routerLink="/Banking">Cheque- NACH Automation </a></li>
                        <li><i class="fa-solid fa-chevron-right"></i> <a routerLink="/Banking">Financial Fraud Prevention (FCPS)</a></li>
                        <li><i class="fa-solid fa-chevron-right"></i> <a routerLink="/Banking">Legal Document Review (LDR)</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget">
                    <h5 class="text-white">Insurance</h5>
                    <ul class="footer-quick-links">
                        <!-- <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Insurance">Customer Onboarding</a></li> -->
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Insurance">Service Automation</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Insurance">Video-KYC</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Insurance">Financial Underwriting</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Insurance">Vehicle Onboarding</a></li>
                        
                    
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget">
                    <h5 class="text-white">Healthcare</h5>
                    <ul class="footer-quick-links">
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Healthcare">Patient Data Storage</a></li>
                        <!-- <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Healthcare">Document Detection</a></li> -->
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Healthcare">Patient Onboarding</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Healthcare">Medical Underwriting</a></li>
                          <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Healthcare">Claim Automation</a></li>
                   </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-widget no-border">
                    <h5 class="text-white">Tele-communication</h5>
                    <ul class="footer-quick-links">
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Tele-com">Security and Fraud Detection</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Tele-com">Real-Time Analytics</a></li>
                        <li><i class="fa-sharp fa-solid fa-chevron-right"></i> <a routerLink="/Tele-com">Preventive Maintenance</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-footer-widget pl-5">
                 <p><img src="assets/img/Logo.svg" width="80" alt="X-biz Venture"></p>
                 <p class="text-white"> A transformational fintech  company since 2012 of financial services by delivering the best of practices in fintech software development
                    for a decade now. </p><p class="text-white"> We've been  helping banks & businesses  with   the digital transformation.</p> 
                    <p><a href="https://dunsregistered.dnb.com/DunsRegisteredProfileAnywhere.aspx?Key1=3169390&PaArea=Email" id="duns" title="DUNS"><img src="assets/img/DUNS.png"></a></p>
                    
                    <!-- <p><a href="javascript://" id="duns" title="DUNS"><img src="assets/img/DUNS.png"></a></p> -->
                    
                </div>
            </div>
            <div class="col-lg-1 d-none d-lg-block"></div>
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="single-footer-widget gx-5">
                    <h3>GET IN TOUCH</h3><div class="row">
                   <div class="col-md-7"> <ul class="footer-contact-info">
                    <li><i class="flaticon-marker"></i>
                        <span>Corporate Office</span>
                        <a href="https://www.google.com/maps/place/x-Biz+Techventures+Private+Limited/@19.1838265,72.8293074,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b6f27853b463:0xef80ba1af739314d!8m2!3d19.1838265!4d72.8318823!16s%2Fg%2F11dynzlld3?entry=ttu" target="_blank"><small>118-120 IJMIMA Complex, Mindspace, Malad West, Mumbai - 400 064</small></a></li>  
                   <li><i class="flaticon-marker"></i><span>Development Center</span>
        
                <a href="https://www.google.com/maps/place/xBiz+Techventures+Private+Limited+-+Pune/@18.5446599,73.911187,15z/data=!4m6!3m5!1s0x3bc2c1211d2272ef:0xcacc3d4342a41a90!8m2!3d18.5446599!4d73.911187!16s%2Fg%2F11kq29mh_g?entry=ttu"  target="_blank"> <small> Unit 2-B, 1st Floor, B-3, Cerebrum IT Park, Vadgaon Sheri, Kalyani Nagar, Pune 411 014</small></a>
                </li>
                     </ul>
                    </div>
                    <div class="col-md-5"> <ul class="footer-contact-info">
                          <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Mon to Fri : 09:30AM - 06:30PM</span>
                            <a href="tel:+1800-266-8575"><small>1800-266-8575</small></a>
                        </li>
                        <li>
                            <i class="flaticon-social-media"></i>
                            <span>Social Networks</span>
                            <ul class="social">
                                <li><a href="https://twitter.com/xbiztechventure" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.facebook.com/xbizventures/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/xbiz_ventures/?hl=en" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://in.linkedin.com/company/xbiztechventures" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                <li><a href="https://www.youtube.com/@xbiztechventures9590" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            </ul>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:info@xbizventures.com"><small>info@xbizventures.com</small></a>
                        </li>

                        <li>
                            <i class="flaticon-email"></i>
                            <span>Contact for Sales</span>
                            <a href="mailto:Sales@xbizventures.com"><small>sales@xbizventures.com</small></a>
                        </li>
                    </ul></div></div>
                </div>
            </div>
           
           
        </div>
        <div class="copyright-area ">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}}. <a href="https://xbizventures.com/" target="_blank">XBiz Techventures Pvt. Ltd</a>. All rights reserved</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        
                        <li class="d-none"><a routerLink="/">Terms & Conditions</a></li>
                        <li class="d-none"><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/sitemap">Sitemap</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->

