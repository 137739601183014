<app-navbar></app-navbar>
<section class="contact-area mt-5">
    <div class="container mt-5">

        <div class="row align-items-center" style="padding: 40px;">
            

            <div class="col-lg-12 col-md-8" style="align-items: center;">
                <div class="contact-form" *ngIf="!formSubmitted || successMessage">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="section-title mt-5">

                            <h3>Connect with Us at GITEX 2024</h3>
                            <p>Thanks for scanning our code! Whether you're curious about our innovations, products, or partnership opportunities, we're here to help.</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="firstName" name="firstName" id="firstName" class="form-control" placeholder="First Name *">
                                    <div *ngIf="contactForm.controls.firstName.touched && contactForm.controls.firstName.invalid" class="error-message">
                                        First Name is required.
                                      </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="lastName" name="lastName" id="lastName" class="form-control" placeholder="Last Name *">
                                    <div *ngIf="contactForm.controls.lastName.touched && contactForm.controls.lastName.invalid" class="error-message">
                                        Last Name is required.
                                      </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" name="email" id="email" class="form-control" placeholder="Email *">
                                    <div class="error-message" *ngIf="contactForm.controls.email.touched && contactForm.controls.email.invalid">
                                        <span *ngIf="contactForm.controls.email.errors.email">
                                            Email is invalid
                                        </span>
                                        <span *ngIf="contactForm.controls.email.errors.required">
                                            Email is required.
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="mobile" name="mobile" id="mobile" class="form-control" placeholder="Phone">
                                    <div *ngIf="contactForm.controls.mobile.touched && contactForm.controls.mobile.invalid" class="error-message">
                                        Phone is required.
                                      </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3 pos-rel">
                                    
                                   <!-- Dropdown for productsServices -->
                                    <!-- <select formControlName="productsServices" class="form-control"  (ngModelChange)="onProductsServicesSelection($event.target.value)">
                                        <option value="" disabled selected>Select Product or Service</option>
                                        <option value="Digivision - OCR">Digivision - OCR</option>
                                        <option value="Digidrive - DMS">Digidrive - DMS</option>
                                        <option value="Secure - Service">Secure - Service</option>
                                    </select> -->

                                    <ng-select class="form-control" formControlName="productsServices" (change)="onProductsServicesSelection($event)">
                                        <ng-option value="" disabled selected>Select Product or Service</ng-option>
                                        <ng-option value="Digivision - OCR">Digivision.ai - AI & Computer Vision</ng-option>
                                        <ng-option value="Digidrive - DMS">Digidrive.ai - Disrupting DMS</ng-option>
                                        <ng-option value="Secure - Service">Secure Nexus - Cyber Security </ng-option>
                                        <ng-option value="Digivision -Masking">Digivision.ai - Aadhaar & Pan Masking</ng-option>
                                        <ng-option value="Digivision - OfflineVisionengine">Digivision.ai - Offline Vision Engine</ng-option>
                                        <ng-option value="Digivision - IdentifyVefication">TrueCheck.ai - Identity Verifiaction Plateform</ng-option>
                                        <ng-option value="Digivision - Automationtool">Digivision.ai - Document Automation System</ng-option> 
                                        <ng-option value="Digivision - Objectdetection">Digivision.ai - Object Detection (AI Camera)</ng-option>   
                                        <ng-option value="Digivision - KYCsuite">TrueCheck.ai - Digi KYC Suite</ng-option>
                                     </ng-select> <div class="arrow-box"><i class="fas fa-chevron-down"></i></div>

                                    <!-- Add any necessary validation messages for the dropdown -->
                                    <div *ngIf="contactForm.get('productsServices').invalid && contactForm.get('productsServices').touched">
                                        <div *ngIf="contactForm.get('productsServices').errors.required" class="error-message">
                                        Please select a product or service.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="company" name="company" id="company" class="form-control" placeholder="Company">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="message" name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                    <div *ngIf="contactForm.controls.message.touched && contactForm.controls.message.invalid" class="error-message">
                                        Message is required.
                                      </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <div #recaptchaRef></div>
                           <!-- <div class="g-recaptcha"  data-callback="handleRecaptchaResponse"></div> -->
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3 float-end">
                                    <button type="submit" [class.valid-btn]="isFormValid()" [class.invalid-btn]="!isFormValid()">Send Message<span></span></button>

                                    </div>
                            </div>
                            
                        </div>
                    </form>
                </div>
                <div *ngIf="successMessage" class="success-message">
                    {{ successMessage }}
                  </div>
            </div>
            
            <!-- <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <img src="assets/img/form.svg" alt="image">
                </div>
            </div>  -->
        </div>
    </div>
</section>