


<app-navbar></app-navbar>

<style>
   .features_img {
    /* height: 120px; */
    width: 153px;
    max-width: 100%;
   }
   .features-card {
    height: 315px;
}

    .pt-100 {
    padding-top: 50px;
}
.bg-maincolor {
    font-size: 14px;
    font-family: poppins;
}
.single-pricing-table .pricing-features {
    /* margin: 0 0 0 120px; */
    padding: 0px;
    list-style-type: none;
}


.flex {
    display: flex;
}

.bg-gray-900 {
    background-color: white; 
}

.text-gray-100 {
    color: #636567; 
}

.p-8 {
    padding: 2rem; 
}

.max-w-4xl {
    max-width: 64rem; 
    margin: 0 auto; 
}

.w-1\/3 {
    width: 33.333%; 
    margin-right: 2rem; 
}

.w-2\/3 {
    width: 66.666%; 
}

.bg-gradient-to-br {
    background: linear-gradient(to bottom right, #2d3748, #4a5568); 
}

.h-72 {
    height: 18rem; 
}

.w-56 {
    width: 14rem; 
}

.rounded-lg {
    border-radius: 0.5rem; 
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* Shadow */
}

.items-center {
    align-items: center; 
}

.justify-center {
    justify-content: center; 
}

.text-gray-400 {
    color: #cbd5e0; 
}

.object-cover {
    object-fit: cover; 
}

.text-3xl {
    font-size: 1.875rem; 
}

.font-bold {
    font-weight: 700; 
}

.mb-4 {
    margin-bottom: 1rem; 
}

.text-blue-400 {
    color: red; 
}

.leading-relaxed {
    line-height: 1.625; 
}

.list-disc {
    list-style-type: disc; 
}

.pl-5 {
    padding-left: 1.25rem; 
}

.mb-4 {
    margin-bottom: 1rem; 
}

.space-y-2 > :not(:last-child) {
    margin-bottom: 0.5rem; 
}

.italic {
    font-style: italic; 
}

.text-gray-300 {
    color: #16171a; 
}
.animation-none
{
    animation: none !important;
}
.hover-card:hover {
        transform: scale(1.02);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
    }
    .card-height {
        min-height: 250px;
    }
    .overview-box .overview-image .image-trusttier img {
    width: 100%;
    margin-top: 30px;
}
.logo-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .rounded-circle {
        border-radius: 50%;
    }
    .ms-3 {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .ms-5 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .d-flex {
        display: flex;
    }
    .align-items-center {
        align-items: center;
    }
    .img-fit {
        object-fit: contain;
        width: 50px;
        height: 50px;
    }
    .img-fit-payapp {
        object-fit: contain;
        width: 40px;
        height: 50px;
    }
    .img-fit-digivision {
        object-fit: contain;
        width: 60px;
        height: 60px;
    }

</style>

<style>


    .client-logos {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    
    .marquee {
        display: flex;
        align-items: center;
        white-space: nowrap;
        animation: marquee 500s linear infinite;
    }
    
    
    .marquee:hover {
        animation-play-state: paused;
    }
    
    .marquee-content {
        display: flex;
    }
    /* Duplicate the content to make it scroll smoothly */
    .marquee-content::after {
        content: '';
        display: block;
        width: 0%; /* Duplicating the width of the original content */
        height: 100%;
    }
    
    .logo-box {
        width: 150px; /* Adjust the width for a more rectangular shape */
        height: 80px; /* Adjust the height */
        border: 2px solid red;
        border-radius: 15px; /* Rounded corners */
        margin: 0 15px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }
    
    .logo-box img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.3s ease-in-out;
        padding: 2px;
    }
    
    .logo-box:hover {
        transform: scale(1.2);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
            transition: all 0.3s ease;
    }
    
    @keyframes marquee {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-5000%);
        }
    }
    </style>


<!-- Start Main Banner Area -->
<div class="saas-banner-two hero-banner banner-bg2 mb-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1><span class="highlight">Driving Excellence in</span> AI/ML,  Computer Vision <span class="highlight">and</span> Cybersecurity</h1>
                            <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                            
                            <div class="banner-btn">
                              
    
                                <a class="default-btn-two  burger-menu">
                                    Try It Now <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        
                       <div class="saas-banner-image animate-tb">
                            <img src="assets/img/Asset 1.svg" alt="image">

                            <div class="solution-video d-none">
                                <a href="https://www.youtube-nocookie.com/embed/jzYxbpId2sI?start=9" class="video-btn popup-youtube">
                                    <i class="flaticon-play-button"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
 
</div>
<!-- End Main Banner Area -->

<!-- About Xbiz Section -->
<section class="choose-area-two mb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About X-BIZ TECHVENTURES</span>
                        <h2>We Innovate, <br /><span class="highlight">Incubate & Invest</span></h2>

                        <!-- <p>Xbiz techventures private limited are Information Management professionals who have been at leadership roles as <b class="text-black">CEO / CIO / CXO with Global organizations in FMCG, Retail, Financial Services, Private Security, Renewable Energy sectors</b>.</p> 
                        <p>xbiz aims to create an eco-system of socially relevant products & services that touch people, that are unique, that address unstated needs, which are easy to use. xbiz explores un-chartered waters and fosters a spirit of Adventure in the Ventures.</p> -->
                        <p> <b>X-Biz TechVentures Pvt. Ltd.</b> is a 12+ year leader in<b> OCR, Image Processing, AI/ML, Information Security Services, Governance Risk and compliance (GRC), and Generative AI.</b> We are focused on empowering the <b>Banking, Financial Services, and Insurance (BFSI) sectors.</b> We specialize in building innovative automation solutions that streamline operations, improve compliance, and enhance customer experiences.</p>

                            <p>
                                X-BIZ aims to create an ecosystem of socially relevant products and services that touch people, are unique, address unstated needs, and are easy to use. XBIZ is always exploring new opportunities and fostering a spirit of innovation and adventure in its ventures. 
                            </p>

                    </div>

                    <div class="choose-btn">
                        <a class="default-btn-two" routerLink="/AboutUs">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/about.png" class="" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End -->

<!-- Start client List -->

<!-- <div class="container">
    <div class="row justify-content-center" style="padding-top:50px;">
        <h1 class="text-white"> <strong class="highlight">Trusted by 40+ renowned global clients</strong> </h1>
        <h3 class="text-white"> <strong class="highlight">Featuring top banks in India and the UAE, along with leading Indian insurance companies 
        </strong> </h3>
        <marquee width="60%" direction="left" height="200px" style="padding-top:30px;">
            <img src="assets/img/Clients/row1.png" alt="x-Biz Client List 2024">
            <img src="assets/img/Clients/row2.png" alt="x-Biz Client List 2024">
            <img src="assets/img/Clients/row3.png" alt="x-Biz Client List 2024">
            <img src="assets/img/Clients/row4.png" alt="x-Biz Client List 2024">
            <img src="assets/img/Clients/row5.png" alt="x-Biz Client List 2024">
            <img src="assets/img/Clients/row6.png" alt="x-Biz Client List 2024">
        </marquee>
    </div>
    </div> -->

    <section class="client-logos">
        <div class="container-fluid">
            <div class="row justify-content-center pb-5">
                <h1 class="text-white justify-content-center"  style="text-align: center; width: 100%;"><strong class="highlight">Trusted by 40+ renowned global clients</strong></h1>
                <h3 class="text-black"  style="text-align: center; width: 100%;"><strong class="">Featuring top banks in India and the UAE, along with leading Indian insurance companies</strong></h3>
                <div class="marquee mt-5" direction="left" scrollamount="15">
                    <div class="marquee-content">
                        <!-- Original client logos -->
                        <div class="logo-box"><img src="assets/img/Clients/ICICI_Bank_Logo.png" alt="Client 1"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Kotak_Mahindra_Group_logo.png" alt="Client 2"></div>
                        <div class="logo-box"><img src="assets/img/Clients/SBI_Life_Insurance_Company_Limited.png" alt="Client 3"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tata_aig.png" alt="Client 4"></div>
                        <div class="logo-box"><img src="assets/img/Clients/godrej.png" alt="Client 5"></div>
                        <div class="logo-box"><img src="assets/img/Clients/aditya_birla.jpg" alt="Client 6"></div>
                        <div class="logo-box"><img src="assets/img/Clients/ICICI_Prudential_Life_Insurance_logo.png" alt="Client 7"></div>
                        <div class="logo-box"><img src="assets/img/Clients/indiafirst-life-insurance.png" alt="Client 8"></div>
                        <div class="logo-box"><img src="assets/img/Clients/kotak_investment.jpg" alt="Client 9"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tatamotors_insurance.jpeg" alt="Client 10"></div>
                        <div class="logo-box"><img src="assets/img/Clients/shreeram.jpeg" alt="Client 11"></div>
                        <div class="logo-box"><img src="assets/img/Clients/HDFC_Life_Logo.png" alt="Client 12"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Tata_Capital.jpg" alt="Client 13"></div>
                        <div class="logo-box"><img src="assets/img/Clients/HDFC-Ergo.png" alt="Client 14"></div>
                        <div class="logo-box"><img src="assets/img/Clients/logo_lipaquick.png" alt="Client 15"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tata_aia.jpg" alt="Client 16"></div>
                        <!-- <div class="logo-box"><img src="assets/img/Clients/bandhan_logo.png" alt="Client 17"></div> -->
                        <div class="logo-box"><img src="assets/img/Clients/bajaj_allianz_logo.png" alt="Client 18"></div>
                        <div class="logo-box"><img src="assets/img/Clients/bharti_axa.png" alt="Client 19"></div>
                        <div class="logo-box"><img src="assets/img/Clients/svatantra.png" alt="Client 20"></div>
                        <div class="logo-box"><img src="assets/img/Clients/ucare.jpeg" alt="Client 21"></div>
                        <div class="logo-box"><img src="assets/img/Clients/niva_bupa.png" alt="Client 22"></div>
                        <div class="logo-box"><img src="assets/img/Clients/alliance_insurance_brokers.png" alt="Client 23"></div>
                        <div class="logo-box"><img src="assets/img/Clients/universal_sompo.png" alt="Client 24"></div>
                        <div class="logo-box"><img src="assets/img/Clients/pramerica-logo.png" alt="Client 25"></div>
                        <div class="logo-box"><img src="assets/img/Clients/walko.png" alt="Client 26"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Utkarsh_Bank_Logo.jpg" alt="Client 27"></div>
                        <div class="logo-box"><img src="assets/img/Clients/rakbank-logo.jpg" alt="Client 28"></div>
                        <div class="logo-box"><img src="assets/img/Clients/oona_insurance.png" alt="Client 29"></div>
    
                        <!-- Duplicated logos for continuous scroll -->
                        <div class="logo-box"><img src="assets/img/Clients/ICICI_Bank_Logo.png" alt="Client 1"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Kotak_Mahindra_Group_logo.png" alt="Client 2"></div>
                        <div class="logo-box"><img src="assets/img/Clients/SBI_Life_Insurance_Company_Limited.png" alt="Client 3"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tata_aig.png" alt="Client 4"></div>
                        <div class="logo-box"><img src="assets/img/Clients/godrej.png" alt="Client 5"></div>
                        <div class="logo-box"><img src="assets/img/Clients/aditya_birla.jpg" alt="Client 6"></div>
                        <div class="logo-box"><img src="assets/img/Clients/ICICI_Prudential_Life_Insurance_logo.png" alt="Client 7"></div>
                        <div class="logo-box"><img src="assets/img/Clients/indiafirst-life-insurance.png" alt="Client 8"></div>
                        <div class="logo-box"><img src="assets/img/Clients/kotak_investment.jpg" alt="Client 9"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tatamotors_insurance.jpeg" alt="Client 10"></div>
                        <div class="logo-box"><img src="assets/img/Clients/shreeram.jpeg" alt="Client 11"></div>
                        <div class="logo-box"><img src="assets/img/Clients/HDFC_Life_Logo.png" alt="Client 12"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Tata_Capital.jpg" alt="Client 13"></div>
                        <div class="logo-box"><img src="assets/img/Clients/HDFC-Ergo.png" alt="Client 14"></div>
                        <div class="logo-box"><img src="assets/img/Clients/logo_lipaquick.png" alt="Client 15"></div>
                        <div class="logo-box"><img src="assets/img/Clients/tata_aia.jpg" alt="Client 16"></div>
                        <!-- <div class="logo-box"><img src="assets/img/Clients/bandhan_logo.png" alt="Client 17"></div> -->
                        <div class="logo-box"><img src="assets/img/Clients/bajaj_allianz_logo.png" alt="Client 18"></div>
                        <div class="logo-box"><img src="assets/img/Clients/bharti_axa.png" alt="Client 19"></div>
                        <div class="logo-box"><img src="assets/img/Clients/svatantra.png" alt="Client 20"></div>
                        <div class="logo-box"><img src="assets/img/Clients/ucare.jpeg" alt="Client 21"></div>
                        <div class="logo-box"><img src="assets/img/Clients/niva_bupa.png" alt="Client 22"></div>
                        <div class="logo-box"><img src="assets/img/Clients/alliance_insurance_brokers.png" alt="Client 23"></div>
                        <div class="logo-box"><img src="assets/img/Clients/universal_sompo.png" alt="Client 24"></div>
                        <div class="logo-box"><img src="assets/img/Clients/pramerica-logo.png" alt="Client 25"></div>
                        <div class="logo-box"><img src="assets/img/Clients/walko.png" alt="Client 26"></div>
                        <div class="logo-box"><img src="assets/img/Clients/Utkarsh_Bank_Logo.jpg" alt="Client 27"></div>
                        <div class="logo-box"><img src="assets/img/Clients/rakbank-logo.jpg" alt="Client 28"></div>
                        <div class="logo-box"><img src="assets/img/Clients/oona_insurance.png" alt="Client 29"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<!-- Start Overview Area -->
<section class="overview-area" style="padding-bottom: 70px;">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="container">
                <div class="row justify-content-evenly">
                    <!-- Left Side: Statistics Section -->
                    <div class="col-lg-5 col-sm-6 pt-5">
                        <div class="row">
                            <!-- First statistic -->
                            <div class="col-6 animation-none" >
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">3 bn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Documents Processed</p>
                                </div>
                            </div>
                            <!-- Second statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">1.60 bn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Aadhar Masking</p>
                                </div>
                            </div>
                            <!-- Third statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">8.3 mn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Video KYCs</p>
                                </div>
                            </div>
                            <!-- Fourth statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">1.32 bn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Documents Verified</p>
                                </div>
                            </div>
                            <!-- Fifth statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">9.6 mn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Man-Days Saved</p>
                                </div>
                            </div>
                            <!-- Sixth statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">₹ 15.3 bn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Resource Cost Saved</p>
                                </div>
                            </div>
                            <!-- Seventh statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">1.2 mn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Fraud Cases Exposed
                                       </p>
                                </div>
                            </div>
                            <!-- Eighth statistic -->
                            <div class="col-6 animation-none">
                                <div class="stats-fun-fact-box text-left">
                                    <h1 class="highlight">₹1.1 tn+</h1>
                                    <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                    <p>Money Safeguarded
                                    </p>    
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <!-- Right Side: Content Section -->
                    <div class="col-lg-6 col-sm-6">
                        <div class="">
                            <div class="content right-content">
                                <div class="overview-image">
                                    <div class="image mb-3">
                                        <img src="assets/img/digivision-logo.png" class="Digivision-logo" alt="Digivision">
                                        <img src="assets/img/services/it-service5.png" class="opacity-25" alt="image">
                                    </div>
                                </div>
                                <kbd class="bg-maincolor">DIGIVISION.AI</kbd>
                                <h2 class="mt-2">An Intelligent Digital Eye</h2>
                                <p>DigiVision.ai revolutionizes document and video analysis with advanced computer vision and AI-driven data processing solutions. Our specialized framework unveils hidden insights in images and videos, delivering unparalleled accuracy and transformative applications across various industries (Banking, Insurance, Telecommunications, Healthcare, and other Financial Services). With 30+ products and platform capabilities, we empower organizations to unlock the complete digital transformation of their processes and services. Experience the future of visual data analysis, empowering 7X cost savings and 5X time savings with DigiVision.ai.</p>
                                <div class="rm-btn">
                                    <a routerLink="/Digivision" class="default-btn-two">
                                        Read More 
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

            
<!-- End Overview Area -->

<!-- Digivision Products -->
<section class="ml-services-area bg-f2f2f7 pt-100 pb-70" style="background-color: #f4fafd;">
    <div class="container">
        <div class="text-center mb-5">
            <h2 class="mt-2">Star Digivision Products</h2>
        </div>

        <div class="container mt-4">
            <div class="row">
                <!-- First row: Wide card -->
                <div class="col-12">
                    <div class="card mb-3 hover-card">
                        <div class="card-body d-flex align-items-center ms-3">
                            <a href="https://xbizocr.digivision.ai/" target="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/xbiz_ocr.png" alt="x-Biz OCR" class="logo-img rounded-circle">
                            
                                <h5 class="card-title ms-3">x-Biz OCR (Table OCR) Offline</h5>
                            </a>
                        </div>
                        <p class="card-text ms-5 mb-3">
                            Effortlessly capture <span class="fw-bold">printed text, handwriting, layout elements</span>, and <span class="fw-bold">data</span> from any document with <span class="fw-bold">x-Biz OCR/ICR</span>. Leveraging advanced OCR capabilities, our solution accurately interprets both <span class="fw-bold">handwritten</span> and <span class="fw-bold">printed content</span>, significantly enhancing data processing efficiency across industries.
                        </p>
                        <p class="card-text ms-5 mb-3">
                            With <span class="fw-bold">x-Biz Intelligent Table Data Processing</span>, easily extract tables from images and multi-page documents (JPG, JPEG, PNG, PDFs, TIF) to reduce manual effort, minimize errors, and accelerate operations. This robust tool ensures <span class="fw-bold">real-time table extraction with 99% accuracy</span>, enabling precise detection and recognition of table structures and content, even within unstructured documents.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Second row: Four equal-height cards -->
                <div class="col-md-3">
                    <div class="card mb-3 card-height hover-card">
                        <div class="card-body d-flex align-items-center">
                            <a href="https://los.digivision.ai/" target="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/Low_Code_BPM.png" alt="x-Biz BPM" class="logo-img rounded-circle">
                                <h5 class="card-title ms-3">LowCode Platform</h5>
                            </a>
                        </div>
                        <p class="card-text ms-5 mb-3">Build applications fast with our lowcode platform, using drag-and-drop features to turn ideas into reality, without deep coding skills.</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3 card-height hover-card">
                        <div class="card-body d-flex align-items-center">
                            <a href="https://digimagica.digivision.ai/payapp.html" target="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/payapp.png" alt="x-Biz Pay App" class="logo-img rounded-circle img-fit-payapp">
                                <h5 class="card-title ms-3">x-Biz Pay App</h5>
                            </a>
                        </div>
                        <p class="card-text ms-5 mb-3">Transfer money, pay bills, and effortlessly handle B2B/B2C transactions seamlessly through our advanced mobile and web platforms.</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3 card-height hover-card">
                        <div class="card-body d-flex align-items-center">
                            <a href="https://los.digivision.ai/" target="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/LDRS.png" alt="LDRS" class="logo-img rounded-circle">
                                <h5 class="card-title ms-3">LDRS</h5>
                            </a>
                        </div>
                        <p class="card-text ms-5 mb-3">Optimize legal processes with AI-powered tools for document reviews, contract renewals, and more, ensuring accuracy and speed.</p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card mb-3 card-height hover-card">
                        <div class="card-body d-flex align-items-center">
                            <a href="https://demo.digivision.ai/lowcodedemo/" target="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/FCPS.png" alt="FCPS" class="logo-img rounded-circle">
                                <h5 class="card-title ms-3">FCPS</h5>
                            </a>
                        </div>
                        <p class="card-text ms-5 mb-3">Stay compliant with cutting-edge tools to efficiently manage financial crime risks, including fraud, corruption, and money laundering detection.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Third row: Wide card -->
                <div class="col-12">
                    <div class="card mb-3 hover-card">
                        <div class="card-body d-flex align-items-center ms-4">
                            <a href="https://digivision.ai" targer="_blank" class="d-flex align-items-center text-decoration-none">
                                <img src="assets/img/digivision_ai.png" alt="x-Biz gen AI" class="" width="15%;">
                                <h5 class="card-title ms-3">Generative AI</h5>
                            </a>
                            
                        </div>
                        <p class="card-text ms-5 mb-3">
                            Introducing the <span class="fw-bold">DigiVision Gen AI</span> Platform a cutting-edge solution designed to revolutionize complex BFSI process automation. Powered by advanced <span class="fw-bold">Machine Learning (ML)</span> and <span class="fw-bold">Large Language Models (LLMs)</span>, DigiVision optimizes document understanding, extraction, text summarization, and analysis, making it the ultimate tool for automating intricate processes in record time.
                        </p>
                        <p class="card-text ms-5 mb-3">
                            Our platform seamlessly integrates with <span class="fw-bold">x-Biz Table OCR data</span>, enabling transformative automation in areas like <span class="fw-bold">fraud detection, financial risk assessment, claims processing</span>, and <span class="fw-bold">improved decision-making</span>. It’s perfect for accelerating critical tasks such as <span class="fw-bold">process auditing, product management, optimized pricing</span>, and <span class="fw-bold">customized promotions</span>.
                        </p>
                        <p class="card-text ms-5 mb-3">
                            DigiVision also supports building <span class="fw-bold">intelligent supply chains, object tracking (Context AI), vehicle damage detection (Inside)</span>, and <span class="fw-bold">legal enforcement</span> all while digitizing historical documents for <span class="fw-bold">pattern searches</span> and offering deep integration with data lakes for enhanced analytics.
                        </p>
                        <p class="card-text ms-5 mb-3">
                            Unlock superior performance and gain a competitive edge with DigiVision’s ability to deliver <span class="fw-bold">contextual understanding</span> and streamline complex automation processes effortlessly.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- End -->


 
<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-black">
    <div class="container">
        <div class="section-title">
            <P class="text-center mb-2">
                <img src="assets/img/secureNexusicon.png" width="160" alt="Secure Nexus">
            </P>
            <h2 class="text-white">Secure Nexus</h2>
            <p class="text-white">"SECURE THE DIGITAL NEXUS OF FORCES."</p>
        </div>

        <div class="row justify-content-center mb-4">
            <div class="col-lg-4 col-sm-12 d-flex">
                <div class="single-pricing-table text-left h-100">
                    <div class="pricing-header">
                        <div class="icon float-start">
                            <img src="assets/img/icon2.png" alt="Icon">
                        </div>
                    </div>
                    <p class="mb-0"><kbd class="bg-maincolor">Information Security Services</kbd></p>
                    <h3>Securing your Nexus of Forces with precision and expertise</h3>
                    <p>Experts in Application, Infrastructure, and Cloud Security assessments. Protecting your digital assets with precision and expertise.</p>
                    <ul class="pricing-features">
                        <li><i class='bx bx-badge-check'></i> Realtime Attack Surface Monitoring</li>
                        <li><i class='bx bx-badge-check'></i> Advanced Application, API Security Testing</li>
                        <li><i class='bx bx-badge-check'></i> Cloud Security Assessment</li>
                        <li><i class='bx bx-badge-check'></i> Infrastructure Security Assessment</li>
                        <li><i class='bx bx-badge-check'></i> Red Teaming</li>
                        <li><i class='bx bx-badge-check'></i> Cyber Security Advisory</li>
                        <li><i class='bx bx-badge-check'></i> Incident Response & Threat Remediation</li>
                    </ul>
                    <div class="text-center">
                        <a class="default-btn-two bg-black" routerLink="/SecureNexus-ISS">Read More<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 d-flex">
                <div class="single-pricing-table text-left h-100">
                    <div class="pricing-header">
                        <div class="icon float-start">
                            <img src="assets/img/icon1.png" alt="Icon">
                        </div>
                    </div>
                    <p class="mb-0"><kbd class="bg-maincolor">Governance Risk & Compliance</kbd></p>
                    <h3>Empower next-generation governance, risk & compliance.</h3>
                    <p>A CXO friendly tool to manage IT security, Governance & Compliance requirements. Fully customizable with inbuilt Builder & dynamic workflows.</p>
                    <ul class="pricing-features">
                        <li><i class='bx bx-badge-check'></i> Full Attack Surface Management</li>
                        <li><i class='bx bx-badge-check'></i> Software Composition Analysis</li>
                        <li><i class='bx bx-badge-check'></i> Cloud Security Posture Management</li>
                        <li><i class='bx bx-badge-check'></i> IT Risk and Compliance Management</li>
                        <li><i class='bx bx-badge-check'></i> Vulnerability Management</li>
                        <li><i class='bx bx-badge-check'></i> Business Continuity Management</li>
                    </ul>
                    <div class="text-center">
                        <a class="default-btn-two bg-black" routerLink="/SecureNexus-GRC">Read More<span></span></a>
                    </div>
                </div>
            </div>

            <!-- Third Card: Vendor Risk Management -->
            <div class="col-lg-4 col-sm-12 d-flex">
                <div class="single-pricing-table text-left h-100">
                    <div class="pricing-header">
                        <div class="icon float-start">
                            <img src="assets/img/icon3.png" alt="Icon">
                        </div>
                    </div>
                    <p class="mb-0"><kbd class="bg-maincolor">Vendor Risk Management</kbd></p>
                    <h3>Your security is only as strong as your weakest vendor.
                       </h3>
                    <p>Revolutionize vendor management: eliminate email friction and simplify the vendor lifecycle. Enhance security with ASM scans, ensuring comprehensive supply chain oversight.</p>
                    <ul class="pricing-features">
                        <li><i class='bx bx-badge-check'></i> Digital Footprint Analysis (Vendor ASM)</li>
                        <li><i class='bx bx-badge-check'></i> Vendor Contract Management</li>
                        <li><i class='bx bx-badge-check'></i> Onboarding & Periodic assessments</li>
                        <li><i class='bx bx-badge-check'></i> Automated Remediation Workflows</li>
                        <li><i class='bx bx-badge-check'></i> Compliance Gap Identification</li>
                        <li><i class='bx bx-badge-check'></i> Dynamic Questionnaires</li>
                    </ul>
                    <div class="text-center">
                        <a class="default-btn-two bg-black" href="https://www.securenexus.io/tprm">Read More<span></span></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- info sunil Sir -->
<!-- Using Bootstrap classes for a responsive design -->
<!-- <div class="container bg-white text-light py-4">
    <div class="row align-items-center">
        <div class="col-md-4 mb-4 mb-md-0 text-center">
            <div class="">
                <img src="assets/img/sunil_sir.png" alt="Sunil Yadav" class="img-fluid rounded" style="max-width: 11rem;" />
            </div>
        </div>

        <div class="col-md-8 animation-none">
            <h1 class="text-danger fw-bold mb-3">Leader Profile - Sunil Yadav</h1>
            <p class="text-black">
                Founder of SecureNexus, the cyber security division at X-biz Techventures. With <span class="text-danger fw-semibold">15+ years of experience</span>, Sunil is passionate about cutting-edge cyber security breakthroughs.
            </p>
            <ul class="list-unstyled text-black">
                <li class="mb-2">
                    Renowned trainer at <span class="text-danger fw-semibold">Global Black Hat Conference</span> (USA and Europe)
                </li>
                <li class="mb-2">
                    Recognized by <span class="text-danger fw-semibold">Microsoft, LinkedIn, Yahoo, Nokia, Apache, PayPal, and Oracle</span> for identifying security vulnerabilities.
                </li>
            </ul>
            <p class="fst-italic text-muted">
                A leader in cyber security, consistently pushing boundaries in protecting digital assets and infrastructure.
            </p>
        </div>
    </div>
</div> -->

        
        

    </div>
</section>


<!-- End Pricing Area -->



<!--  -->

<!-- DigiImagica Section -->
<section class="py-5" id="" style="background-color: color-mix(in srgb, #e13434, transparent 97%);">
    <div class="container text-center" id="">
        <h1>Digi <strong class="highlight" style="transform: none;">iMagica</strong></h1>
        <p class="mt-4">
            Leverage the power of Imagica's advanced tools: Digi Merger, Digi Splitter, Digi Conversion, and Digi Compression.
            Effortlessly manage your documents by merging, splitting, converting, and compressing files with ease.
        </p>
        <div class="rm-btn mb-3">
            <a routerLink="/iMagica-Suite" class="default-btn-two">
                  Read More 
                  <span></span>
              </a>
          </div>

          <div class="row flex-wrap">
            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3 mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/spliter.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-5 text-center">Document Splitting</h4>
                                <p>
                                    Rule based file splitting into multiple documents - Split by
                                        customer record/identifier, Split by pages, Split by delimiter,
                                        Split by pattern.
                                </p>
                                        
                                 
                                <p></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/Compression.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-4 text-center">Document Compression</h4>
                                <p>
                                    Compress your 100GB video &amp; document to 10GB (90%) in real time.
                                </p>
                                        
                                 
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/mearger.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-5 text-center">Document Merging</h4>
                                <p>
                                    Stitch together images into single .tif/.pdf file for easy storage
                                    of files removing redundancies and clutter.
                                </p>
                                       
                                           
                                        
                               
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/conversionn.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-4 text-center">Document Conversion</h4>
                                <p>
                                    Digi Conversion utility and API converts file formats into desired
                                    formats for downstream usage.
                                </p>
                                       
                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Masking -->
<section class="py-5" id="" style="background-color: #e5dede91">
    <div class="container text-center" id="">
        <h1>Digi <strong class="highlight" style="transform: none;"> PII Masking</strong></h1>
        <p class="mt-4">
            Our Aadhaar masking solution supports formats like PDF, TIFF, JPEG, ZIP, and MSG. Using an offline vision engine powered by AI/ML and image processing, it scans and masks sensitive PII in onboarding documents, KYC forms, and financial records. 
            As the market's first Aadhaar masking solution, now mandated by IRDA and RBI, we’ve processed over 1.6 billion documents, helping businesses improve data security and compliance.  
        </p>
        <div class="rm-btn mb-3">
            <a routerLink="/Redaction-Solution" class="default-btn-two">
                  Read More 
                  <span></span>
              </a>
          </div>

          <div class="row flex-wrap">
            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3 mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/saasimg.jpg" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-3 text-center">SaaS Based Solution</h4>
                                <p>
                                    A SaaS solution offers internet-based software, requiring no installation, with easy access, lower costs and automatic updates.
                                </p>
                                        
                                 
                                <p></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/emailMasking.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head text-center">Email Masking</h4>
                                <p>
                                    Email attachment masking involves hiding or redacting sensitive information in attachments, such as Aadhaar numbers.
                                </p>
                                        
                                 
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/VidioMAsk.png" width="50" alt="Document AI">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-3 text-center">Video Masking</h4>
                                <p>
                                    Video masking involves obscuring or redacting sensitive information in video content, such as documents to protect privacy.
                                </p>
                                       
                                           
                                        
                               
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="custom-card card rounded-3  mb-3 features-card">
                    <div class="card-body">
                        <div>
                            <div class="text-center">
                                <img class="img-fluid features_img" src="assets/img/zipmsg.png" alt="Document AI" style="width: 175px;">
                            </div>
                            <div class="caption" style="height:150px;">
                                <h4 class="prod-head mt-4 text-center">Zip Msg Support</h4>
                                <p>
                                    Aadhaar masking supports masking sensitive data in ZIP archives and MSG email attachments to protect personal information.                                </p>
                                       
                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- End -->

<!-- Trucheck  -->

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <!-- <img src="assets/img/truecheck_home.jpg" class="Digivision-logo" alt="Digivision"> -->
                    <!-- <img src="assets/img/trucheck_home.png"  alt="image"> -->
                    <img src="assets/img/Identity_Management/TCL.png"  alt="image">

                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <kbd class="bg-maincolor">Truecheck.AI</kbd>
                    <h1 style="font-size: 50px; color: red; margin: 0;">Identity Verification & Fraud Prevention Platform</h1>	
                    <!-- <h3></h3> -->
                    <h3 class="mt-2">Onboard Everyone but Not Anyone...!!</h3>
                    <p>Protect your digital environment with built-in spam prevention and auto-classification tools, which simplify form completion and ensure data integrity.
                        Employ advanced technologies like AI-driven tamper detection, Customer Onboarding, Auto underwriting, face matching, Signature AI, and cross-document verification to enhance security measures and user unauthorized access attempts.</p>
                        <div class="stats-area pt-4">
                            <div class="container">
                                <div class="row justify-content-left">
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="stats-fun-fact-box text-left">
                                            <h1 class="highlight">200 Mn+</h1>
                                            <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                            <p>Identities Verified</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="stats-fun-fact-box text-left">
                                            <h1 class="highlight">6.4 Mn+</h1>
                                            <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                            <p>Man-Days Saved</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="stats-fun-fact-box text-left">
                                            <h1 class="highlight">10.2 bn+</h1>
                                            <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                            <p>Resource Cost Saved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    
                  
                    <!-- <div class="rm-btn">
                      <a href="https://www.truecheck.ai/" class="default-btn-two" target="_blank">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                    <div class="rm-btn">
                        <a routerLink="/TrueCheck" class="default-btn-two">
                              Read More 
                              <span></span>
                          </a>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check -->



<!-- Trust Tier -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            

            <div class="overview-content">
                <div class="content right-content">
                    <kbd class="bg-maincolor">TrustTier.AI</kbd>
                    <h1 style="font-size: 50px; color: red; margin: 0;">Proactive Assurance for Audit & Compliance Management</h1>
                    <!-- <h3></h3> -->
                    <h3 class="mt-2">Empower Security without any compromises...!!</h3>
                    <p>TrustTier AI revolutionizes risk management in Banking, Financial Services, and Insurance with advanced data analysis, detecting fraud and compliance issues, securely masking Aadhaar and other PII information, and ensuring robust security and compliance with AI based Automation.</p>
                    <div class="stats-area pt-4">
                        <div class="container">
                            <div class="row justify-content-left">
                                <div class="col-lg-4 col-sm-6">
                                    <div class="stats-fun-fact-box text-left">

                                        <!-- <h1 class="highlight">200 Mn+</h1>
                                        <hr class="border border-danger border-2 opacity-75 mt-0 mb-2">
                                        <p>Documents Protected</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="rm-btn">
                        <a routerLink="/TrustTier" class="default-btn-two">
                            Read More
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image-trusttier">
                    <!-- <img src="assets/img/truecheck_home.jpg" class="Digivision-logo" alt="Digivision"> -->
                    <img src="/assets/img/TrustTier/trusttier_diagram 1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<!-- End -->

<!-- Why Choose Us -->
<section class="choose-area-two ptb-100 bg-f2f2f7">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <!-- <img src="assets/img/DigiDrive.png"  class="Digivision-logo" alt="image"> -->
                    <img src="assets/img/services/it-service3.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <kbd class="bg-maincolor">NextGen DMS - DIGIDRIVE.AI</kbd>
                        <h2>Making paradigmatic shift in Document - Management  </h2>
                        <p>Powered with potential & promise to streamline the content &  Document - Management .</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i> 
                        <h4>AI-based Content Extraction & Metadata Creation</h4>
                        <p>Unlock insights and efficiency with AI-driven content extraction and accurate metadata generation.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Large Object Archive - Audio & Video</h4>
                        <p>Effortlessly manage and access large audio and video files with our scalable archive solution.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Supremely Efficient Compression</h4>
                        <p>Optimize storage space with our proprietary compression technology, reducing file sizes without quality loss.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn-two" routerLink="/Digidrive">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<!-- End Why Choose Us -->







<!-- Start Featured Services Area -->
<section class="seo-featured-services pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-us-content text-start">
                    <span class="sub-title"> Use Cases and Solutions</span>
                    <h3>Explore our portfolio of <span> Industry-Driven</span> use cases  &  Solutions <!--to witness the transformative power of our solutions.--></h3>
                    <p>We work hand-in-hand with industry to help redefine the possibilities and potential of digital engagements.</p>
</div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/bank.svg" width="50" alt="image">
                                
                            </div>
                            <h3>
                                <a routerLink="/Banking">Banking & Finance</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/insurance.svg" width="50" alt="image">
                            </div>
                            <h3>
                                <a routerLink="/Insurance">Insurance</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <img src="assets/img/healthcare.svg" width="50" alt="image">
                            </div>
                            <h3>
                                <a routerLink="/Healthcare">Healthcare </a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">  
                                <img src="assets/img/tele-presence.svg" width="50" alt="image">  
                            </div>
                            <h3>
                                <a routerLink="/Tele-com">Tele-Communication</a>
                            </h3>
                            <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->


<!-- About Us -->
<!-- <section class="choose-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/aboutus.svg" class="opacity-75" alt="image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About X-BIZ TECHVENTURES</span>
                        <h2>We Innovate, <br /><span class="highlight">Incubate & Invest</span></h2>

                        <p>Xbiz techventures private limited are Information Management professionals who have been at leadership roles as <b class="text-black">CEO / CIO / CXO with Global organizations in FMCG, Retail, Financial Services, Private Security, Renewable Energy sectors</b>.</p> <p>xbiz aims to create an eco-system of socially relevant products & services that touch people, that are unique, that address unstated needs, which are easy to use. xbiz explores un-chartered waters and fosters a spirit of Adventure in the Ventures.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn-two" routerLink="/AboutUs">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

          
        </div>
    </div>
</section> -->
<!-- End About Us -->



<!-- Lets work Area -->
<section class="subscribe-area bg-f2f2f7">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center subscribe-content">
                <div class="col-lg-12">
                   <img src="assets/img/iso.png" class="iso-img">
                   
                </div>

            
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC d-none">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center subscribe-content">
                <div class="col-lg-6">
                    <h2>Join Our Team</h2>
                    <span class="sub-title">Share your profile to be a part of innovative tech team.</span>
               
                    <form class="newsletter-form" data-toggle="validator">
                        <input type="file" class="input-newsletter form-control pt-4" placeholder="Upload your resume" name="EMAIL" required autocomplete="off">
    
                        <button type="submit">Send Now</button>
                        <div id="validator-newsletter" class="form-result"></div>
                    </form>
                   
                </div>

                <div class="col-lg-6">
                    <h2>Keep in Touch</h2>
                    <span class="sub-title">We will send you our latest insights and updates</span>
               
                    <form class="newsletter-form" data-toggle="validator">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
    
                        <button type="submit">Subscribe</button>
                        <div id="validator-newsletter" class="form-result"></div>
                    </form>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->