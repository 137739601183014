<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>TELE-COMMUNICATION</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Use Cases</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Overview Area -->
<section class="overview-area">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Security and Fraud Detection</h2>
                    <p>Implementing a centralised and secure storage solution for enhancing security and fraud detection. It is crucial for safeguarding sensitive data and streamlining fraud prevention measures.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Create secure centralised client records storage
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Efficient client information retrieval system

                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Maintain data privacy and compliance
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/GRC2.png" width="60%" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/patient2.jpg" width="60%" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Real-Time Analytics</h2>
                    <p>Introduce automated procedures to identify and extract data from documents, streamlining information retrieval and analysis. This enhances efficiency and accuracy in document processing.

                    </p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate data extraction from telecom documents

                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate telecom document detection & processing

 
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Streamline administrative workflows and save valuable time

                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Preventive Maintenance</h2>
                    <p>Transform the customer/employee registration and intake processes into digital workflows, enhancing efficiency and accessibility.

                    </p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Digitise customer/employee registration for efficiency


                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Streamline onboarding for efficiency improvement


                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Digitise registration, boost efficiency & satisfaction

                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/fraud.jpg" width="60%" alt="image">
                </div>
            </div>
        </div>


    </div>
</section>
<!-- End Overview Area -->

