<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="seo-banner-slider owl-carousel owl-theme">
    <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Digital Agency with Excellence Service</h1>
                                <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-3">
                                        Get Started <span></span>
                                    </a>
        
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i> 
                                        Play Video
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img src="assets/img/seo-banner/main-banner1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="assets/img/shape/shape14.png" alt="image"></div>
    </div>

    <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Creative & Strategic Digital Marketing Agency</h1>
                                <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-3">
                                        Get Started <span></span>
                                    </a>
        
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i> 
                                        Play Video
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img src="assets/img/seo-banner/main-banner2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="assets/img/shape/shape14.png" alt="image"></div>
    </div>

    <div class="seo-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-50">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <h1>Creative Solutions to Improve your Business!</h1>
                                <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                                
                                <div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-3">
                                        Get Started <span></span>
                                    </a>
        
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i> 
                                        Play Video
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="seo-banner-image animate-tb">
                                <img src="assets/img/seo-banner/main-banner3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
        <div class="shape-img13"><img src="assets/img/shape/shape13.png" alt="image"></div>
        <div class="shape-img14"><img src="assets/img/shape/shape14.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Services Area -->
<section class="seo-featured-services pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="section-title text-start">
                    <span class="sub-title">Featured services</span>
                    <h2>Create Awesome Service with our Tools</h2>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class="bx bx-conversation"></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                <a routerLink="/service-details">SEO Consultancy</a>
                            </h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class='bx bx-bar-chart-alt-2'></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                <a routerLink="/service-details">Marketing Analysis</a>
                            </h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class='bx bx-search-alt'></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                <a routerLink="/service-details">Website Optimization</a>
                            </h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="service-card-one bg-white center">
                            <div class="icon">
                                <i class='bx bx-mail-send'></i>
                                <i class='bx bxs-badge-check check-icon'></i>
                            </div>
                            <h3>
                                <a routerLink="/service-details">Email Marketing</a>
                            </h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Services Area -->

<!-- About Area Two -->
<section class="about-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img2.jpg" alt="image" class="rounded-10">

                    <div class="solution-video">
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title text-start">
                        <span class="sub-title">About Us</span>
                        <h2>We are Dynamic Team of SEO Agency</h2>
                        <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                    </div>
                    <div class="about-text">
                        <h4>Who We Are</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    </div>
                    <div class="about-text">
                        <h4>Our History</h4>
                        <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth.</p>
                    </div>
                    <div class="about-text">
                        <h4>Our Mission</h4>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area Two -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Completed Projects</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Happy Clients</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Services Area -->
<section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bullseye'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Target Analysis</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Keyword Research</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-check-shield' ></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Continuous Testing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Marketing Analysis</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-search-alt'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Content Optimization</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="icon">
                        <i class='bx bx-mail-send'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Email Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Project area -->
<div class="works-area ptb-100 gray-bg">
    <div class="container-fluid">
        <div class="section-title">
            <span class="sub-title">Projects</span>
            <h2>Our Case Studies</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="work-slider owl-carousel owl-theme">
            <div class="work-card">
                <img src="assets/img/works/work1.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">SEO Optimization</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">Designing a better cinema experience</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>
    
            <div class="work-card">
                <img src="assets/img/works/work2.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">Digital Marketing</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">Building design process within teams</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>
        
            <div class="work-card">
                <img src="assets/img/works/work3.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">Keyword Targeting</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">How intercom brings play eCommerce</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>

            <div class="work-card">
                <img src="assets/img/works/work4.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">Email Marketing</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">How to start a project with Reactjs</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>
    
            <div class="work-card">
                <img src="assets/img/works/work5.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">Marketing & Reporting</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">Examples of different types of sprints</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>
        
            <div class="work-card">
                <img src="assets/img/works/work6.jpg" alt="image">
                
                <div class="content text-center">
                    <span>
                        <a routerLink="/projects">Development</a>
                    </span>
                    <h3>
                        <a routerLink="/projects-details">Redesigning the New York times app</a>
                    </h3>
                        
                    <a class="work-btn" routerLink="/projects-details">Case Study</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End project area -->

<!-- Why Choose Us -->
<section class="choose-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <span class="sub-title">Why Choose Us</span>
                        <h2>Outstanding Digital Experience</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Best Performence</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Dedicated Team Member</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>24/7 Support!</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn" routerLink="/">
                            Discover More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us -->

<!-- Start Features Area -->
<section class="features-area ptb-100 gray-bg">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Features</span>
            <h2>We Always try to Understand Customers Expectation</h2>
        </div>

        <div class="tab features-list-tab">
            <ul class="tabs">
                <li>
                    <a href="#" class="bg-fa7070">
                        <i class="flaticon-achievement"></i>
                        <span>User Experience</span>
                    </a>
                </li>
                <li>
                    <a href="#" class="bg-00aeff">
                        <i class="flaticon-architecture"></i>
                        <span>Product Design</span>
                    </a>
                </li>
                <li>
                    <a href="#" class="bg-c679e3">
                        <i class="flaticon-digital-marketing"></i>
                        <span>Digital Marketing</span>
                    </a>
                </li>
                <li>
                    <a href="#" class="bg-eb6b3d">
                        <i class="flaticon-analytics"></i>
                        <span>Branding</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="flaticon-data"></i>
                        <span>Development</span>
                    </a>
                </li>
                <li>
                    <a href="#" class="bg-f78acb">
                        <i class="flaticon-research"></i>
                        <span>Marketing</span>
                    </a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>User Experience</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features2.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Product Design</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Digital Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features4.png" alt="image">
                            </div>
                        </div>
                        
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Branding</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Development</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tabs_item bg-white">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features6.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
</section>
<!-- End Features Area -->

<!-- Start Feedback Area -->
<section class="feedback-area feedback-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Affordable Pricing Plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Weekly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 120 <sub>/ Per week</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-x-circle red'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle red'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Month plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 840 <sub>/ Per month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle red'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <h3>Yearly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 3,600 <sub> / Per yearly</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            SEO & Branding
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Marketing
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Google Analytics
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Digital Accounts
                        </li>
                        <li>
                            <i class='bx bxs-badge-check'></i>
                            Pay-per-Click
                        </li>
                        <li> 
                            <i class='bx bxs-badge-check'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- SEO Analysis Section -->
<div class="seo-analysis-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="analysis-img">
                    <img src="assets/img/seo-analysis.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="analysis-form">
                    <div class="section-title text-start">
                        <span class="sub-title">ANALYSIS</span>
                        <h2>Get Free SEO Analysis</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <form>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="your_name" placeholder="Your Name">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" id="your_email" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="your_name" placeholder="Phone Number">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="website_rtl" placeholder="Website RTL">
                                </div>
                            </div>
                        </div>
                        
                        <div class="text-center">
                            <button type="submit" class="default-btn">
                                Check Now 
                                <span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SEO Analysis Section -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="sub-title">READY TO DO THIS</span>
                    <h2>Let's get to work!</h2>
                </div>

                <div class="col-lg-6">
                    <div class="contact-btn">
                        <a routerLink="/contact" class="default-btn">
                            Contact Us <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Start Partner Area -->
<div class="partner-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Latest News</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Boost Your Digital Marketing Agency</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">The Rise of Smarketing and Why You Need It</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Use Music to Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="email" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->