<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Compression Solution</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="row"><div class="col-md-12"> <h2>Compressed 430 mn+ Files & Counting</h2>
            <p>AI-enabled lossless compression utilizes advanced algorithms and machine learning
                techniques to reduce the size of data files without losing any information or quality. This
                technology is especially useful in applications where data integrity and preservation are
                crucial. It enables the storage and transmission of large volumes of data while maintaining
                fidelity and reducing storage costs.</p></div></div>
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                   
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Hassel-Free Integration
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Multi-platform support
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Offline Mobile SDKs
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                No change to native file Formats
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                File compression up to 90% without loss in Quality
                            </span>
                        </li>

                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Saves Bandwidth (Works well even with 2G/3G network)
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Real-time and Bulk Processing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Compress Images, Videos, Document & Medical Reports (DICOM)
                            </span>
                        </li>
                     </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/file-compress.png" alt="image">
                </div>
            </div>
        </div>

     

    </div>
</section>
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Redaction-Solution" ><i class="flaticon-left-chevron"></i>Redaction Solution  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Location-Based-Solution">Location Based Solution<i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>