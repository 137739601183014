<app-navbar></app-navbar>



<style>
.animation-none {
    animation: none !important;
}
</style>



<!-- Start Pocket Office -->

<div class="bd-about-area pb-70" style="margin-top: 100px;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="section-title with-line-text-with-white-color text-center" style="max-width: 925px;">
                    <h2 class=" text-center mb-3" >Pocket Office</h2>
                    <!-- <h3 class="sub-title" style="font-size: 25px;">Pocket Office</h3> -->
                    <p style="font-size: 20px;">Robust mobile app platform for seamless on-site and off-site task management. Seamlessly integrate third-party services & content.</p>
                </div>
            </div>
        </div>

        <div class="row align-items-start">
            <!-- Left Column: 3 Items -->
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 55px; margin-top: 6px;" src="assets\img\Innovation_Lab\Command Center.png" alt="Command Center">
                        </div>
                        <h4>Command Center</h4>
                        <p>Information hub for managing all field assets and resources in real-time.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets\img\Innovation_Lab\CRM.png" alt="mCRM">
                        </div>
                        <h4>mCRM</h4>
                        <p>Empower your customer engagements by scheduling visits, managing updates in real-time, and using a native CRM or integrating with your existing CRM system.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 48px;" src="assets\img\Innovation_Lab\complain.png" alt="Complaint Management">
                        </div>
                        <h4>Complaint Management</h4>
                        <p>A mobile-based service management platform with ticket-based tracking from start to closure, ideal for complaint and other service management.</p>
                    </div>
                </div>
            </div>

            <!-- Right Column: 3 Items -->
            <div class="col-lg-6 col-md-12">
                <div class="bd-about-content">
                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 51px; margin-left: 11px;" src="assets\img\Innovation_Lab\attendance.png" alt="Attendance System">
                        </div>
                        <h4>Attendance System</h4>
                        <p>Employees can mark attendance on the go via mobile devices, preventing misuse.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="height: 50px;" src="assets\img\Innovation_Lab\Employee Self Service.png" alt="">
                        </div>
                        <h4>Employee Self Service</h4>
                        <p>A mobile workflow-based employee self-service system for managing leave and other essentials. It supports employees and helps improve productivity.</p>
                    </div>

                    <div class="inner-content-card">
                        <div class="icon-image">
                            <img style="width: 55%" src="assets\img\Innovation_Lab\task.png" alt="">
                        </div>
                        <h4>Corporate Task Tracker</h4>
                        <p>A single platform that serves various organizational needs, helps manage individual initiatives and cross-functional corporate projects.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






<!--Constituent Technologies Start-->
<!-- <div class="bd-about-area pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="row align-items-center">
           
                
                <h2 class=" text-center mb-3" >Constituent Technologies
                </h2>
            
                </div>
                <div class="row">
                    <div class="row">
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const1.png" width="50" alt="image">
                            </div>
                            <h6>Face-AI</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const2.png" width="50" alt="image">
                            </div>
                            <h6>GPS</h6>
                        </div></div>
                        <div class="col-md-2"> <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const3.png" width="50" alt="image">
                            </div>
                            <h6>Communication Platform</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const4.png" width="50" alt="image">
                            </div>
                            <h6>IOT sensors</h6>
                        </div></div>
                        <div class="col-md-2"> <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const5.png" width="50" alt="image">
                            </div>
                            <h6>Wearables</h6>
                        </div></div>
                        <div class="col-md-2"><div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const6.png" width="50" alt="image">
                            </div>
                            <h6>Workflow Engine</h6>
                        </div></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-6"></div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const7.png" width="50" alt="image">
                            </div>
                            <h6>Data Lake</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const8.png" width="50" alt="image">
                            </div>
                            <h6>Starlink Internet</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const9.png" width="50" alt="image">
                            </div>
                            <h6>Private 5G</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\const10.png" width="50" alt="image">
                            </div>
                            <h6>Azure Integration</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                       
                    </div>
                    
               
        </div>
    </div>
</div> -->

<!--Constituent Technologies End-->




<!--Card Section Start-->

<div class="bd-about-area pb-70">
    <div class="container">
        <h1 class="text-center mb-5">Pocket Office</h1>
        <div class="row">
            <!-- Image Section -->
            <div class="col-md-7 mt-2">
                <img src="assets/img/Innovation_Lab/Pocket_Office.gif" alt="Pocket Office Image" class="img-fluid">
                
                <!-- Constituent Technologies Section -->
                <div class="service-card-one mt-4 bg-white p-3" style="border-radius: 10px; box-shadow: 0 2px 10px rgba(0,0,0,0.5);">
                    <h4 class="text-center">Constituent Technologies</h4>
                    
                    <div class="row">
                        <!-- First row with 5 columns -->
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const1.png" width="40" alt="Face AI">
                            <span class="ml-2">Face AI</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const2.png" width="40" alt="GPS">
                            <span class="ml-2">GPS</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const3.png" width="40" alt="Comms. Platform">
                            <span class="ml-2">Comms. Platform</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const4.png" width="40" alt="IoT Sensors">
                            <span class="ml-2">IoT Sensors</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const5.png" width="40" alt="Wearables">
                            <span class="ml-2">Wearables</span>
                        </div>
                    </div>
                
                    <div class="row">
                        <!-- Second row with 5 columns -->
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const6.png" width="40" alt="Workflow Engine">
                            <span class="ml-2">Workflow Engine</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const7.png" width="40" alt="Data Lake">
                            <span class="ml-2">Data Lake</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const8.png" width="40" alt="Starlink Internet">
                            <span class="ml-2">Starlink Internet</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const9.png" width="40" alt="Private 5G">
                            <span class="ml-2">Private 5G</span>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center mb-3">
                            <img src="assets/img/Innovation_Lab/const10.png" width="40" alt="Azure Integration">
                            <span class="ml-2">Azure Integration</span>
                        </div>
                    </div>
                </div>
                
                
                
            </div>

            <!-- Right Content Section (Shrunk slightly to fit) -->
            <div class="col-md-5 mt-4">
                <div class="service-card-one bg-white p-2" style="border-radius: 20px; border-color: red; box-shadow: 0 2px 10px rgba(0,0,0,0.5);">
                    <h4 class="text-center mt-4 mb-3">Messaging & Communication Suite</h4>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs1.png" width="35" alt="Video Conf. & Chat">
                            <span class="ml-2">Video Conf. & Chat</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs2.png" width="35" alt="Push to Talk (PTT) Walkie Talkie">
                            <span class="ml-2">Push to talk (PTT) Walkie Talkie</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs3.png" width="35" alt="Enterprise Command & Control">
                            <span class="ml-2">Enterprise Command & Control</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs4.png" width="35" alt="Sub-Group Chats">
                            <span class="ml-2">Sub-Group Chats</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs5.png" width="35" alt="Broadcast Messages">
                            <span class="ml-2">Broadcast Messages</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs6.png" width="35" alt="Project Progress Reporting">
                            <span class="ml-2">Project Progress Reporting</span>
                        </div>
                    </div>

                    <h4 class="text-center mt-4 mb-3">Work Related</h4>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs6.png" width="35" alt="Task Management">
                            <span class="ml-2">Task Management</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs7.png" width="35" alt="Site Inspections">
                            <span class="ml-2">Site Inspections</span>
                        </div>
                    </div>

                    <h4 class="text-center mt-4 mb-3">Human Resource Related</h4>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs8.png" width="35" alt="Attendance Marking">
                            <span class="ml-2">Attendance Marking</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs9.png" width="35" alt="H.R.M.S Self-Service">
                            <span class="ml-2">H.R.M.S Self-Service</span>
                        </div>
                    </div>

                    <h4 class="text-center mt-4 mb-3">Workforce Health & Safety Related</h4>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center mb-3 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs10.png" width="35" alt="Safety Interfaces">
                            <span class="ml-2">Safety Interfaces</span>
                        </div>
                        <div class="col-6 d-flex align-items-center mb-5 animation-none">
                            <img src="assets/img/Innovation_Lab/mcs11.png" width="35" alt="Health Monitors">
                            <span class="ml-2">Health Monitors</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Card Section End-->