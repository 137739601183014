import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-demo-four',
    templateUrl: './home-demo-four.component.html',
    styleUrls: ['./home-demo-four.component.scss']
})
export class HomeDemoFourComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}