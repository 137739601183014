import { Component } from '@angular/core';

@Component({
  selector: 'app-home-demo-thirteen',
  templateUrl: './home-demo-thirteen.component.html',
  styleUrls: ['./home-demo-thirteen.component.scss']
})
export class HomeDemoThirteenComponent {

}
