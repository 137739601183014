
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './digi-eleven.component.html',
  styleUrls: ['./digi-eleven.component.scss']
})
export class DigielevenComponent implements OnInit {
  isCollapsed: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  
}



