import { Component } from '@angular/core';

@Component({
  selector: 'app-home-demo-fifteen',
  templateUrl: './home-demo-fifteen.component.html',
  styleUrls: ['./home-demo-fifteen.component.scss']
})
export class HomeDemoFifteenComponent {

}
