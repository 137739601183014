<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Career Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                   

                    <div class="article-content">
                       
                        <h3>Graphic Designer / Ux And Ui Designer</h3>
                        <div class="entry-meta">
                            <ul>
                                <li><span>Experience</span> <a>
                                    1 - 6 years</a></li>
                                <li><span>CTC :</span> <a >
                                    1.5-2.5 Lacs P.A.</a></li>
                                    <li><span>Location : </span> <a>
                                        Remote</a></li>
                            </ul>
                        </div>
                        <p class="mt-20">Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                       
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                       
                        <h3>Role & responsibilities</h3>
                        <ul class="features-list">
                            <li><i class="fas fa-check"></i> Create visually captivating designs for a wide range of mediums</li>
                            <li><i class="fas fa-check"></i> Design engaging graphics for various digital platforms, including websites, social media, and email marketing campaigns.</li>
                            <li><i class="fas fa-check"></i> Stay up-to-date with design trends, industry best practices, and software updates.</li>
                            <li><i class="fas fa-check"></i> Manage multiple design projects simultaneously, ensuring timely and high-quality deliverables.
                            </li>
                        </ul>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fas fa-bookmark"></i></span>
                            <a routerLink="/">IT</a>,
                            <a routerLink="/">Softwar Engineer</a>,
                            <a routerLink="/">.net Developer</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation d-none">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class="flaticon-left-chevron"></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class="flaticon-right-chevron"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Apply Now!</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                               
                                <p class="comment-form-author">
                                    <label>Full Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email ID<span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>
                                <p class="comment-form-author">
                                    <label>Mobile No <span class="required">*</span></label>
                                    <input type="text" id="Mobile" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Resume<span class="required">*</span></label>
                                    <input type="file"  required="required">
                                </p>

                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                </p>

                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and Mobile in this browser for the next time I apply.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Send">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_xbiz_posts_thumb">
                        <h3 class="widget-title">Popular Jobs</h3>
                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/Career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article> 
                    </section>

                  

                    <section class="widget widget_meta d-none">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Tester <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Software Engineer <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Lead Developer <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">.net Developer <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Website Designer <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing/Sales <span class="tag-link-count"> (1)</span></a>
                           
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->
