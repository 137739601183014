<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>INSURANCE</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Use Cases</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Overview Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Customer Onboarding</h2>
                    <p>Enhance the onboarding experience for insurance customers by streamlining data collection and verification processes, ensuring a seamless and efficient enrollment journey.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Simplify customer data collection during onboarding
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate verification procedures for faster processing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Improve customer satisfaction with a smooth enrollment process
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/onboarding.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/automation.jpg" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Service Automation</h2>
                    <p>Automate various insurance services, such as claims processing and policy management, to streamline operations, reduce manual effort, and provide quicker and more accurate services to policyholders.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate claims processing for faster settlements
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Simplify policy management and updates
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Improve operational efficiency and enhance customer experience
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Video-KYC</h2>
                    <p>Leverage video-based KYC solutions to enhance the onboarding process for insurance customers, ensuring secure and compliant identity verification through remote video interactions.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Enable secure and convenient KYC through video verification
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Verify customer identities remotely while complying with regulations

                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Simplify the onboarding process and reduce paperwork
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/video-kyc.jpg" alt="image">
                </div>
            </div>
        </div>


    </div>
</section>
<!-- End Overview Area -->

