<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Banking & Finance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li> Use Cases</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->



<!-- Start Overview Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview" id="Account" name="Account">
            <div class="overview-content">
                <div class="content">
                    <h2>Account Opening & KYC</h2>
                    <p> Simplify the process of opening accounts and ensure regulatory compliance through efficient automation of Know Your Customer (KYC) procedures.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Streamline account opening process for customers
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate KYC verification for faster onboarding
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Maintain compliance with regulatory requirements
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/bank.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box it-overview"  id="Loan" name="Loan">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service2.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Loan Automation</h2>
                    <p>Improve the efficiency of loan application and approval processes by implementing automation technologies, reducing paperwork and enhancing customer experience.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Simplify loan applications for customers

                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Automate loan approval processes
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Enhance customer satisfaction through faster turnaround
                            </span>
                        </li>
                       
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="overview-box it-overview" id="Fraud" name="Fraud">
            <div class="overview-content">
                <div class="content">
                    <h2>Fraud Detection</h2>
                    <p>Implement advanced algorithms and fraud detection mechanisms to identify and prevent fraudulent activities, protecting customer assets and maintaining the integrity of financial operations.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Deploy robust fraud detection algorithms
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Identify suspicious transactions and activities
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Safeguard customer funds and maintain trust
                            </span>
                        </li>
                        
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/GRC.png" alt="image">
                </div>
            </div>
        </div>

   
    </div>
</section>
<!-- End Overview Area -->


