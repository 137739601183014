<app-navbar></app-navbar>
<!-- Event snippet for Lead Form conversion page -->

<script>

    gtag('event', 'conversion', {'send_to': 'AW-11332866526/G1nECNr93OwYEN6j95sq'});
  
  </script>
<!-- Start Thank you Area -->
<section class="error-area">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/thank-you.png" alt="error">
            <h3>Thank you for your response.</h3>
            <p>We have noted your input, and our team will be in touch with you promptly. We appreciate your patience and look forward to further assisting you.</p>
            <a href="/" class="default-btn">Go to Home <span></span></a>
        </div>
    </div>
</section>
<!-- End Error Area -->