<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Document Detection Solution</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Document Detection Solution</h2>
                    <p>AI-enabled document detection leverages machine learning and computer vision algorithms
                        to automatically identify and extract information from documents. By analysing the visual
                        and textual elements of the documents. AI algorithms can accurately detect various
                        document types. It eliminates the need for manual document sorting and greatly
                        accelerates the document processing workflow in various industries, including banking,
                        finance, legal, education and administrative sectors.</p>
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Detects document type at source.
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Deciphers Bar/QR Code.
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Eliminate intentional / unintentional wrong / bogus document submission.
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                On demand custom template configuration.
                            </span>
                        </li>
                          <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                20+ Preconfigured document templates for BFSI's.
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/digi-5.png" alt="image">
                </div>
            </div>
        </div>



    </div>
</section>
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Document-Indexing" ><i class="flaticon-left-chevron"></i>Document Indexing & Classification  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Identity-Management">Identity Management & Authentication <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>