import { Component } from '@angular/core';

@Component({
  selector: 'app-home-demo-fourteen',
  templateUrl: './home-demo-fourteen.component.html',
  styleUrls: ['./home-demo-fourteen.component.scss']
})
export class HomeDemoFourteenComponent {

}
