<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Career</li>
                    </ul>
                </div>
               

            </div>
        </div>
    </div>

    
</div>

<!-- End Page Title Area -->

<!-- Start Blog Area -->

<section class="blog-area ptb-25">

    <div class="container">
    <div class="row" style="height: auto;">
        <div class="col-lg-4 col-sm-6">
            <img src="assets/img/career/xBiz_Career_dotnet.jpg" class="img-fluid rounded-5"  title="xBiz Job Opening for Sales and Dot Developer" >
        </div>
        <div class="col-lg-4 col-sm-6">
            <div >
                <img src="assets/img/career/Xbiz_hiring_marketing.jpg" class="img-fluid rounded-5"  title="xBiz Job Opening for Sales and Marketing" >
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
                <img src="/assets/img/career/Xbiz_Career_CyberSecurity.jpg" class="img-fluid rounded-5"  title="xBiz Job Opening for Cyber Security" >
        </div> 
        
    </div>

    <div class="row" style="height: auto;">
        <br>
    </div>
    <div class="row" style="height: auto;">



        <div class="clear"></div>
        <div class="col-lg-4 col-sm-6">
            <img src="/assets/img/career/xBiz_Hr_Career.jpg" class="img-fluid rounded-5"  title="xBiz Job Opening for HR">
    </div> 
    <div class="col-lg-4 col-sm-6">
        <img src="/assets/img/career/Career_Tester.jpg" class="img-fluid rounded-5"  title="xBiz Job Opening for Tester">
</div> 
   
   
    </div>

</div>

   
    
    <div class="container">
        <div class="row">
          

            <div class="col-lg-12 col-md-12">
                <aside class="widget-area" id="secondary">
                    <!-- <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section> -->
 <br>
 
                    <section class="widget widget_xbiz_posts_thumb">
                        <h3 class="widget-title">  Jobs (1 - 8 )</h3>

                        <article class="item">
                           
                            <div class="info">
                               <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">SENIOR DOT NET DEVELOPERS </a></h4>
                                <p>5+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE TECHNOLOGY.  </p> <p> 
                                    <b>SKILLS: </b> Dot Net. Dot Net Core, MVC., REST/Web API. MS SQL- 2019 And Above <br> OCR Domain Expertise, AI-ML, Data Analytics, C++ HTML 5, CSS3, JQuery 3.3 or Above <br>
                                     Node JS 10x or Above, Database (MongoDB, Casandra, SQL lite, Postgres). <br> <b>PLATFORM SKILLS: </b> AWS, AZURE, CLOUD, OS (WINDOWS AND LINUX). <br>
                              </p>  <p> <span> Email us your Updated Profile : career@xbizventures.com</span></p>
                                
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                               <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">SENIOR PYTHON DEVELOPERS </a></h4>
                                <p>2+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE TECHNOLOGY.  </p> <p> <b>SKILLS: </b> Python Framework, OCR Domain Expertise, Image Processing, Data Analytics, AI-ML, Flask API. <br>
                                      <b>PLATFORM SKILLS: </b> AWS, AZURE, CLOUD, OS (WINDOWS AND LINUX). <br>
                              </p>  <p> <span> Email us your Updated Profile : career@xbizventures.com</span></p>
                                
                            </div>
                            <div class="clear"></div>
                        </article>
                        
                        <article class="item">
                           
                            <div class="info">
                               <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">Cloud Computing Engineer </a></h4>
                                <p>2+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE TECHNOLOGY.  </p> <p> 
                                      <b>PLATFORM SKILLS:</b> AWS, AZURE, CLOUD, OS (WINDOWS AND LINUX). <br>
                              </p>  <p> <span> Email us your Updated Profile : career@xbizventures.com</span></p>
                                
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">current Opening -2024</time>
                                 <h4 class="title usmall"><a routerLink="/Career">MARKETING MANAGER</a></h4>
                                 <p>4+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE TECHNOLOGY.  </p>
                                    <p><b>SKILLS:</b> Content Creation & Management, Website Management & SEO, Social Media Marketing 
                                     <br>Email Marketing, Event/Exhibition Management, Strong understanding of Digital Marketing Strategies and tools.
                                    </p><p>Email us your Updated Profile : career@xbizventures.com </p>
                             </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">current Opening -2024</time>
                                 <h4 class="title usmall"><a routerLink="/Career">SALES EXECUTIVE</a></h4>
                                 <p>3+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE TECHNOLOGY.  </p>
                                    <p><b>SKILLS: </b> Domain Sales experience in at least these one area - Software Product, DMS, Cyber Security, AIML/Automation, 
                                     
                                    </p><p>Email us your Updated Profile : career@xbizventures.com </p>
                             </div>
                            <div class="clear"></div>
                        </article>
                        
                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">CYBER SECURITY ANALYSTS</a></h4>
                                <p>2+ YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE.  </p> <p> 
                                <p><b>SKILLS:</b> Web, Mobile, network, and Cloud Security Assessments Advance Vulnerability assessment Pen testing methodologies Understanding of attack surface, threat modelling, OWASP top 10, ASVS, Source Code reviews. Familiar with both automated and manual testing of applications Hands-on knowledge of Tools: Burp Suite, Kali Linux, Metasploit, NMAP, Nessus, Nexpose, Wireshark,sqlmap, etc</p>
                                <p><b>Programming Language : </b> Java, Python, Golang</p>
                                <p>Email us your Updated Profile : career@xbizventures.com </p>
                            </div>
                            <div class="clear"></div>
                        </article>
                        
                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">SENIOR HR EXECUTIVE</a></h4>
                                <p>2-3 YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE.  </p> 
                                <p><b>SKILLS:</b> End-to-end Payroll Processing, Compliance Management & Process Improvement, Employee Query and Issue Resolutions, Benefits Administration, HR Data Management, Office Administration Assistance in HR Operations.</p>
                                
                                <p>Email us your Updated Profile : career@xbizventures.com </p>
                            </div>
                            <div class="clear"></div>
                        </article>
                        
                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">current Opening -2024</time>
                                <h4 class="title usmall"><a routerLink="/Career">SOFTWARE TESTER </a></h4>
                                <p>2-3 YEARS OF EXPERIENCE. LOCATION: MUMBAI/PUNE.  </p>
                                <p><b>SKILLS:</b> Develop and execute test cases and test plans according to project requirements.<br> Document and report bugs, errors, and other issues encountered during testing. <br> Proficiency in writing clear and concise test cases and test plans</p>
                                 <p><b>Experience :</b>  Automated testing tools and frameworks. Excellent communication skills with the ability to effectively collaborate with team members</p>
                                <p>Email us your Updated Profile : career@xbizventures.com </p>
                            </div>
                            <div class="clear"></div>
                        </article>
                        
                       

                        <!-- <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/Career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article> <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/Career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article> <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                           
                            <div class="info">
                                <time datetime="2023-06-30">June 10, 2023</time>
                                <h4 class="title usmall"><a routerLink="/Career-details">Graphic Designer / Ux And Ui Designer</a></h4>
                                <p>Coordinate with News Shift Editors and Senior CBR News Editor for assignm</p>
                            </div>
                            <div class="clear"></div>
                        </article> -->
                    </section>

                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/" class="page-numbers">3</a>
                            <a routerLink="/" class="page-numbers">4</a>
                            <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div> -->
                </aside>
            </div>
        </div>
    </div>



</section>
<!-- End Blog Area -->