<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Document Indexing & Classification</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Document Indexing & Classification</h2>
                    <p>AI-enabled document detection and indexing is a technology that utilizes artificial
intelligence algorithms to automatically detect and classify documents, and then index them
into their respective folders or categories. By analysing the content and visual features of
the documents, AI algorithms can accurately identify document types and organize them

accordingly. This process streamlines document management, improves searchability, and
enhances efficiency by eliminating the need for manual sorting and filing, making it easier to
locate and retrieve specific documents when needed.</p>
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                              Using Al technology, it reads document, identifies, categorize, classify & index them
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Tag and Index files with keywords which can be used for searching and fetching documents
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Organize files stashed overtime in Document Management Systems/ File folders/ scattered across
storage devices
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Restructure documents as needed into new well-organized folders
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Benefits in Customer Service and post-sale validation use cases
                            </span>
                        </li>
                         <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Bulk processing of Historic / Existing Images
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/digi-4.png" alt="image">
                </div>
            </div>
        </div>

 


    </div>
</section>
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Video-KYC" ><i class="flaticon-left-chevron"></i>Video KYC </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Document-Detection"> Document Detection Solution <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>