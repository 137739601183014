import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-demo-two',
    templateUrl: './home-demo-two.component.html',
    styleUrls: ['./home-demo-two.component.scss']
})
export class HomeDemoTwoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}