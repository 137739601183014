<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Identity Management & Authentication</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Identity Management & Authentication</h2>
                    <p>AI-enabled face recognition system utilizes advanced machine learning algorithms to
                        accurately identify and authenticate individuals based on their facial features. By analysing
                        key facial landmarks, patterns, and unique characteristics, the system can match faces
                        against a database. This technology offers a secure and convenient solution for identity
                        verification, access control, and surveillance applications.</p>
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Facial Recognition and Authentication service.
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Verification service for matching pic vs submitted documents (Upfront rejection/ Fraud detection and
reporting).
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Liveliness check and Emotion detection.
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Employee/ Visitor Touchless Access Control (Door Open Operation).
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/digi-6-f.svg" alt="image">
                </div>
            </div>
        </div>

   

    </div>
</section>
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Document-Detection" ><i class="flaticon-left-chevron"></i>Document Detection Solution  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Redaction-Solution">Redaction Solution <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>