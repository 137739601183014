<app-navbar-style-two></app-navbar-style-two>

<!-- Start Cryptocurrency Banner Area -->
<div class="cryptocurrency-banner-section">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="cryptocurrency-banner-content">
                    <span class="sub-title">TRADE OVER 60 CRYPTOCURRENCIES</span>
                    <h1>Buy, Sell, and Trade <span>Cryptocurrencies</span></h1>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                    <ul class="banner-btn">
                        <li>
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="flaticon-play-button"></i> 
                                Play Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="cryptocurrency-banner-image">
                    <img src="assets/img/cryptocurrency-home/banner/main.png" alt="image">

                    <div class="cart1">
                        <img src="assets/img/cryptocurrency-home/banner/cart1.png" alt="cart1">
                    </div>
                    <div class="cart2">
                        <img src="assets/img/cryptocurrency-home/banner/cart2.png" alt="cart2">
                    </div>
                    <div class="graph">
                        <img src="assets/img/cryptocurrency-home/banner/graph.png" alt="graph">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cryptocurrency-shape-1">
        <img src="assets/img/cryptocurrency-home/banner/icon1.png" alt="image">
    </div>
    <div class="cryptocurrency-shape-2">
        <img src="assets/img/cryptocurrency-home/banner/icon2.png" alt="image">
    </div>
    <div class="cryptocurrency-shape-3">
        <img src="assets/img/cryptocurrency-home/banner/icon3.png" alt="image">
    </div>
    <div class="cryptocurrency-shape-4">
        <img src="assets/img/cryptocurrency-home/banner/icon4.png" alt="image">
    </div>
</div>
<!-- End Cryptocurrency Banner Area -->

<!-- Start Stats Area -->
<div class="stats-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="stats-fun-fact-box text-center">
                    <div class="stats-image">
                        <img src="assets/img/cryptocurrency-home/stats/stats-1.png" alt="image">
                    </div>
                    <h3>$500m</h3>
                    <span>Quarterly Volume Traded</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="stats-fun-fact-box text-center">
                    <div class="stats-image">
                        <img src="assets/img/cryptocurrency-home/stats/stats-2.png" alt="image">
                    </div>
                    <h3>100K+</h3>
                    <span>Increased Verified Users</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="stats-fun-fact-box text-center">
                    <div class="stats-image">
                        <img src="assets/img/cryptocurrency-home/stats/stats-3.png" alt="image">
                    </div>
                    <h3>95%</h3>
                    <span>Our Conversion Rate</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="stats-fun-fact-box text-center">
                    <div class="stats-image">
                        <img src="assets/img/cryptocurrency-home/stats/stats-4.png" alt="image">
                    </div>
                    <h3>2.7 Million</h3>
                    <span>Our Total Happy Users</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Stats Area -->

<!-- Start Crypto About Area -->
<div class="crypto-about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="crypto-about-image">
                    <img src="assets/img/cryptocurrency-home/about.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="crypto-about-content">
                    <span class="sub-title">ABOUT OUR COMPANY</span>
                    <h3>Start <span>Mining  Crypto</span> Currency Today With Us</h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>
                    <ul class="list">
                        <li><i class="fa-solid fa-check"></i> Weekly Payouts To Your Wallet</li>
                        <li><i class="fa-solid fa-check"></i> Deposit And Withdraw Anytime</li>
                        <li><i class="fa-solid fa-check"></i> No Lock-in Periods Or Minimum Deposits</li>
                        <li><i class="fa-solid fa-check"></i> Optional <span>Nexus Mutual's Custody Cover</span></li>
                    </ul>
                    <div class="about-btn">
                        <a href="contact.html" class="default-btn">
                            Get Started <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Crypto About Area -->

<!-- Start Earning Platform Area -->
<div class="earning-platform-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-linear-gradient-text">
            <span class="sub-title">CRYPTO EARNING PLATFORM</span>
            <h2>APY Rates And Custody <span>Cover Puts</span> Your Crypto To Work</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="earning-platform-card text-center">
                    <div class="earning-image">
                        <img src="assets/img/cryptocurrency-home/earning/earning-1.png" alt="image">
                    </div>
                    <h3>Easy To Transact</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="earning-platform-card text-center">
                    <div class="earning-image">
                        <img src="assets/img/cryptocurrency-home/earning/earning-2.png" alt="image">
                    </div>
                    <h3>Trusted Security</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="earning-platform-card text-center">
                    <div class="earning-image">
                        <img src="assets/img/cryptocurrency-home/earning/earning-3.png" alt="image">
                    </div>
                    <h3>No Fear Of Loss</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="earning-platform-card text-center">
                    <div class="earning-image">
                        <img src="assets/img/cryptocurrency-home/earning/earning-4.png" alt="image">
                    </div>
                    <h3>No Expensive Software</h3>
                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Earning Platform Area -->

<!-- Start Crypto Get Strated Area -->
<div class="crypto-get-strated-area">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="crypto-get-strated-image">
                    <img src="assets/img/cryptocurrency-home/get-strated/get-strated.png" alt="image">
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="crypto-get-strated-content">
                    <div class="content">
                        <span>GET STARTED IN MINUTES</span>
                        <h3>Get Strated In A Few Minute With Our Crypto Platform</h3>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="crypto-get-strated-card">
                                <div class="get-image">
                                    <img src="assets/img/cryptocurrency-home/get-strated/icon1.png" alt="image">
                                </div>
                                <h3>Create Account</h3>
                                <p>Lorem ipsum dolor sit amet is adipiscing elit sed do eiusmo.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="crypto-get-strated-card">
                                <div class="get-image">
                                    <img src="assets/img/cryptocurrency-home/get-strated/icon2.png" alt="image">
                                </div>
                                <h3>Link Bank Account</h3>
                                <p>Lorem ipsum dolor sit amet is adipiscing elit sed do eiusmo.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="crypto-get-strated-card">
                                <div class="get-image">
                                    <img src="assets/img/cryptocurrency-home/get-strated/icon3.png" alt="image">
                                </div>
                                <h3>Buying & Selling</h3>
                                <p>Lorem ipsum dolor sit amet is adipiscing elit sed do eiusmo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="crypto-get-strated-shape">
        <img src="assets/img/cryptocurrency-home/get-strated/shape.png" alt="image">
    </div>
</div>
<!-- End Crypto Get Strated Area -->

<!-- Start Key Features Area -->
<div class="key-features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-linear-gradient-text">
            <span class="sub-title">KEY FEATURES</span>
            <h2>Simple & Secure Access To <span>Buy, Sell</span> And Use Cryptocurrency</h2>
        </div>

        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="key-features-card">
                    <div class="key-content">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon1.png" alt="icon">
                        </div>
                        <h3>Bank Transfer</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>

                    <div class="key-content right-gap">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon2.png" alt="icon">
                        </div>
                        <h3>Online Wallets</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>

                    <div class="key-content">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon3.png" alt="icon">
                        </div>
                        <h3>Competitive Mining</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="key-features-card">
                    <div class="key-image">
                        <img src="assets/img/cryptocurrency-home/key-image.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="key-features-card">
                    <div class="key-content">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon4.png" alt="icon">
                        </div>
                        <h3>Cash Payment</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>

                    <div class="key-content left-gap">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon5.png" alt="icon">
                        </div>
                        <h3>Currency Exchange</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>

                    <div class="key-content">
                        <div class="icon-image">
                            <img src="assets/img/cryptocurrency-home/key-features/icon6.png" alt="icon">
                        </div>
                        <h3>Insurance Protected</h3>
                        <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Key Features Area -->

<!-- Start Mining Area -->
<div class="mining-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mining-image">
                    <img src="assets/img/cryptocurrency-home/mining.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mining-content">
                    <span class="sub-title">START MINING CRYPTO</span>
                    <h3>Start <span>Mining  Crypto</span> Currency Today With Us</h3>
                    <p>Bitcoin is a cryptocurrency and worldwide payment system. It is the first decentralized digital currency, as the system works without a central bank or single administrator. The network is peer-to-peer and transactions take place between users directly.</p>

                    <ul class="mining-btn">
                        <li>
                            <a href="contact.html" class="default-btn">
                                Get Started <span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="flaticon-play-button"></i> 
                                Play Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Mining Area -->

<!-- Start Trade Over Area -->
<div class="trade-over-area pb-100">
    <div class="container">
        <div class="section-title with-linear-gradient-text">
            <span class="sub-title">TRADE OVER 50 CRYPTO</span>
            <h2>Start Trading & Earning <span>Interests On</span> Any Amount Of Crypto</h2>
        </div>

        <div class="trade-over-inner-box pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="trade-over-card">
                        <div class="over-image">
                            <img src="assets/img/cryptocurrency-home/trade-over/BTC.png" alt="image">
                        </div>
                        <p><b>BTC</b> Bitcoin <span>+6.87%</span></p>
                        <h3>413.3739</h3>
                        <span>$413.3739</span>

                        <div class="over-shape">
                            <img src="assets/img/cryptocurrency-home/trade-over/vector.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="trade-over-card">
                        <div class="over-image">
                            <img src="assets/img/cryptocurrency-home/trade-over/LINK.png" alt="image">
                        </div>
                        <p><b>LINK</b> Bidr <span class="color-two">-1.87%</span></p>
                        <h3>513.3739</h3>
                        <span>$513.3739</span>

                        <div class="over-shape">
                            <img src="assets/img/cryptocurrency-home/trade-over/chart.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="trade-over-card">
                        <div class="over-image">
                            <img src="assets/img/cryptocurrency-home/trade-over/MATIC.png" alt="image">
                        </div>
                        <p><b>MATIC</b> Bidr <span>+6.87%</span></p>
                        <h3>713.3739</h3>
                        <span>$713.3739</span>

                        <div class="over-shape">
                            <img src="assets/img/cryptocurrency-home/trade-over/vector.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Trade Over Area -->

<!-- Start Why Choose Us Area -->
<div class="why-choose-us-area pt-100 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <span class="sub-title">WHY CHOOSE US</span>
                    <h3>Deposit Your Crypto And <span>Start Earning</span> Immediately</h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                    <ul class="choose-list">
                        <li>Powerfull Api & Vault Protection</li>
                        <li>Manage Your Portfolio Easy Dashboard</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row choose-with-box-style">
                    <div class="col-lg-6 col-sm-6">
                        <div class="why-choose-us-card">
                            <div class="image">
                                <img src="assets/img/cryptocurrency-home/choose/choose-1.png" alt="image">
                            </div>
                            <h3>Safe And Secure</h3>
                            <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="why-choose-us-card">
                            <div class="image">
                                <img src="assets/img/cryptocurrency-home/choose/choose-2.png" alt="image">
                            </div>
                            <h3>Instant Exchange</h3>
                            <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="why-choose-us-card">
                            <div class="image">
                                <img src="assets/img/cryptocurrency-home/choose/choose-3.png" alt="image">
                            </div>
                            <h3>Experts Support</h3>
                            <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="why-choose-us-card">
                            <div class="image">
                                <img src="assets/img/cryptocurrency-home/choose/choose-4.png" alt="image">
                            </div>
                            <h3>Online Wallet</h3>
                            <p>Lorem ipsum dolor sit amet, is consectetur adipiscing elit, sed do eiusmo.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Why Choose Us Area -->

<!-- Start Unique Feedback Area -->
<div class="unique-feedback-area pt-100">
    <div class="container">
        <div class="section-title with-linear-gradient-text">
            <span class="sub-title">CREATING FEEDBACK</span>
            <h2>These People Have Already Invested In Our Cryptocurrency</h2>
        </div>

        <div class="unique-feedback-slides owl-carousel owl-theme">
            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                    <h3>Jason Roy</h3>
                    <span>Manager</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>

            <div class="unique-single-feedback">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
                <div class="client-info">
                    <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                    <h3>James Anderson</h3>
                    <span>Web Developer</span>
                </div>
                <div class="quote">
                    <img src="assets/img/cryptocurrency-home/quote.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Unique Feedback Area -->

<!-- Start Mobile App Area -->
<div class="mobile-app-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mobile-app-image">
                    <img src="assets/img/cryptocurrency-home/mobile-app.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mobile-app-content">
                    <h3>Trade On The <span>Mobile App</span> Best App Features</h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading.</p>

                    <div class="app-btn-box">
                        <a href="#" class="playstore-btn" target="_blank">
                            <img src="assets/img/cryptocurrency-home/google-play.png" alt="image">
                            Get It On
                            <span>Google Play</span>
                        </a>
                        <a href="#" class="applestore-btn" target="_blank">
                            <img src="assets/img/cryptocurrency-home/app-store.png" alt="image">
                            Download on the
                            <span>Apple Store</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Mobile App Area -->

<!-- Start Blog Area -->
<div class="blog-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="section-title with-linear-gradient-text">
            <span class="sub-title">LATEST NEWS</span>
            <h2>Explore, Learn And Stay <span>Up To Date</span> With The Latest In Crypto</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">The Launch Of A Revamped User Referral Program</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user1.jpg" alt="image">

                        <h3>Thomas Adison</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">Best Way To Earn Bitcoin Faster</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user2.jpg" alt="image">

                        <h3>James Anderson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="tag">
                        <span>LATEST NEWS</span>
                    </div>
                    <ul class="post-meta">
                        <li><i class='bx bxs-calendar'></i> 17th January</li>
                        <li><i class='bx bx-message-dots'></i> 0 comment</li>
                    </ul>
                    <h3>
                        <a href="single-blog.html">Buy, Sell, And Trade And Cryptocurrencies</a>
                    </h3>
                    <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work.</p>
                    <div class="info">
                        <img src="assets/img/cryptocurrency-home/user3.jpg" alt="image">

                        <h3>Sarah Taylor</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>

            <div class="subscribe-shape-1">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-1.png" alt="image">
            </div>
            <div class="subscribe-shape-2">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-2.png" alt="image">
            </div>
            <div class="subscribe-shape-3">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-3.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->