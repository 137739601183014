<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="saas-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Manage Your Digital Marketing in One Place xbiz</h1>
                            <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                            
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn me-3">
                                    Get Started <span></span>
                                </a>
    
                                <a routerLink="/login" class="default-btn-two">
                                    Try It Now <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="saas-banner-image animate-tb">
                            <img src="assets/img/saas-banner/dashboard.png" alt="image">

                            <div class="solution-video">
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                    <i class="flaticon-play-button"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Features Area -->
<section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Great Features</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                        <span class="number">1</span>
                    </div>
                    <h3>Easy Customizable </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                        <span class="number">2</span>
                    </div>
                    <h3>Clean Code</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bx-layout'></i>
                        <span class="number">3</span>
                    </div>
                    <h3>Fully Responsive</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bx-check-shield'></i>
                        <span class="number">4</span>
                    </div>
                    <h3>Extreme Security</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bxs-sun'></i>
                        <span class="number">5</span>
                    </div>
                    <h3>Creative Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg center">
                    <div class="icon">
                        <i class='bx bxs-cloud'></i>
                        <span class="number">6</span>
                    </div>
                    <h3>Cloud Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Overview Area -->
<section class="overview-area ptb-100 bg-f2f2f7">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service5.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Why We Do xbiz Template</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                Customer Service
                            </span>
                        </li>
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                UI/UX Design
                            </span>
                        </li>
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                Mobile App Development
                            </span>
                        </li>
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                Web Development
                            </span>
                        </li>
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                Distinctive Brand
                            </span>
                        </li>
                        <li>
                            <span class="bg-white">
                                <i class='bx bxs-badge-check'></i>
                                Adaptive Innovation
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/contact" class="default-btn me-3">
                            Contact Us
                            <span></span>
                        </a>

                        <a routerLink="/services" class="default-btn-two">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Why Choose Us -->
<section class="choose-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="section-title text-start">
                        <h2>Why Choose Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Experienced Developer</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Dedicated Team Member</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>24/7 Support</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor abore et dolore magna aliqua.</p>
                    </div>

                    <div class="choose-btn">
                        <a class="default-btn" routerLink="/about">
                            Discover More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-two pt-100 pb-70 bg-f2f2f7">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-list-check'></i>
                    <h3>
                        <span class="odometer" data-count="524">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-smile'></i>
                    <h3>
                        <span class="odometer" data-count="560">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-user-plus'></i>
                    <h3>
                        <span class="odometer" data-count="128">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Expert Members</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="fun-fact-card">
                    <i class='bx bx-trophy'></i>
                    <h3>
                        <span class="odometer" data-count="29">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Feedback Area -->
<section class="feedback-area feedback-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item border">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <div class="icon">
                            <img src="assets/img/icon1.png" alt="Icon">
                        </div>
                        <h3>Starter</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 120 <sub>/ Per Month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            5 GB Bandwidth
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Highest Speed
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            1 GB Storage
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Website
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Users
                        </li>
                        <li> 
                            <i class='bx bx-badge-check'></i>
                            24/7 Great Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <div class="icon">
                            <img src="assets/img/icon2.png" alt="Icon">
                        </div>
                        <h3>Premium</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 150 <sub>/ Per Month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            10 GB Bandwidth
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Highest Speed
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            2 GB Storage
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Website
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Users
                        </li>
                        <li> 
                            <i class='bx bx-badge-check'></i>
                            24/7 Great Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table center">
                    <div class="pricing-header">
                        <div class="icon">
                            <img src="assets/img/icon3.png" alt="Icon">
                        </div>
                        <h3>Standard</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 200 <sub> / Per Month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            15 GB Bandwidth
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Highest Speed
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            5 GB Storage
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Branding Solutions
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Website
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Unlimited Users
                        </li>
                        <li> 
                            <i class='bx bx-badge-check'></i>
                            24/7 Great Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">FAQ</span>
            <h2>Frequently Asked Questions</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-img pr-3">
                    <img src="assets/img/faq.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
           
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                        
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How to get start with us?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why choose our services in your business?
                            </a>

                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Clients</span>
            <h2>Our Loving Clients</h2>
            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="sub-title">READY TO DO THIS</span>
                    <h2>Let's get to work!</h2>
                </div>

                <div class="col-lg-6">
                    <div class="contact-btn">
                        <a routerLink="/contact" class="default-btn">
                            Contact Us <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Latest News</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Boost Your Digital Marketing Agency</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">The Rise of Smarketing and Why You Need It</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Use Music to Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="email" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->