<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Features</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Features</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/features-image/features1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Our Features</span>
                    <h2>Creative solutions to improve your business!</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Recommender systems</span></li>
                        <li><span><i class="fas fa-check"></i> Demand prediction</span></li>
                        <li><span><i class="fas fa-check"></i> Omnichannel analytics</span></li>
                        <li><span><i class="fas fa-check"></i> Lead generation</span></li>
                        <li><span><i class="fas fa-check"></i> Dedicated Developers</span></li>
                        <li><span><i class="fas fa-check"></i> 24/7 Support</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->

<!-- Start Features Area -->
<section class="features-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Features</span>
            <h2>We always try to understand customers expectation</h2>
        </div>

        <div class="tab features-list-tab">
            <ul class="tabs">
                <li><a href="#" class="bg-fa7070">
                    <i class="flaticon-achievement"></i>
                    <span>User Experience</span>
                </a></li>
                <li><a href="#" class="bg-00aeff">
                    <i class="flaticon-architecture"></i>
                    <span>Product Design</span>
                </a></li>
                <li><a href="#" class="bg-c679e3">
                    <i class="flaticon-digital-marketing"></i>
                    <span>Digital Marketing</span>
                </a></li>
                <li><a href="#" class="bg-eb6b3d">
                    <i class="flaticon-analytics"></i>
                    <span>Branding</span>
                </a></li>
                <li><a href="#">
                    <i class="flaticon-data"></i>
                    <span>Development</span>
                </a></li>
                <li><a href="#" class="bg-f78acb">
                    <i class="flaticon-research"></i>
                    <span>Marketing</span>
                </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>User Experience</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features2.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Product Design</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Digital Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features4.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Branding</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Development</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="features-overview">
                        <div class="overview-image">
                            <div class="image">
                                <img src="assets/img/features-image/features6.png" alt="image">
                            </div>
                        </div>

                        <div class="overview-content">
                            <div class="content">
                                <span class="sub-title">Define Your Choices</span>
                                <h2>Marketing</h2>
                                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                                <ul class="features-list">
                                    <li><span><i class="flaticon-tick"></i> Open Source</span></li>
                                    <li><span><i class="flaticon-tick"></i> Optimal Choice</span></li>
                                    <li><span><i class="flaticon-tick"></i> High Security</span></li>
                                    <li><span><i class="flaticon-tick"></i> Great Advices</span></li>
                                    <li><span><i class="flaticon-tick"></i> Creative Layout</span></li>
                                    <li><span><i class="flaticon-tick"></i> Super Responsive</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
</section>
<!-- End Features Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Partner</span>
            <h2>We partner with companies of all sizes, all around the world</h2>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->