<div class="machine-learning-slider owl-carousel owl-theme ">
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <span class="sub-title">Unveiling Efficiency:</span>
                                <h1> Introducing Our <span class="highlight"><u>DMS SaaS </u> </span> Solution</h1>
                                <p>As we reveal the potential of our all-inclusive Document Management System (DMS) SaaS across all channels and platforms, come experience its power. Use our solution to handle documents with ease and ensure productivity in all areas of your business.
                                    Come along with us as we transform document management for the contemporary age.</p> 
                                <div class="banner-btn">
                                    <a class="default-btn me-4 burger-menu">
                                        Get Started <span></span>
                                    </a> 
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                              <div class="animate-image">
                                <img class="saas-img-size" src="assets/img/saas.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
       <!-- <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>-->
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
       <!-- <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>-->
    </div> 
    <div class="machine-learning-banner ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                 <span class="sub-title">Empowering Mobile Efficiency</span>
                                <h1>Our <span class="highlight"><u> Digi DMS Mobile App </u></span>Unveiled!</h1>
                                <p>Our Digi DriveDMS mobile app revolutionizes document management, enabling offline KYC scans and smooth offline data sync. With advanced features like auto-capture and edge detection, it simplifies KYC processes whether online or offline, offering unparalleled convenience.</p>
                             
                                <div class="banner-btn">
                                    <a class="default-btn me-4 burger-menu">
                                        Get Started <span></span>
                                    </a>
        
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="animate-image">
                                <img src="assets/img/DMS-MobileApp.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    
</div>
