<app-navbar></app-navbar>

<!-- Start Main Banner Area -->
<div class="it-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Secure IT Solutions for a More Secure Environment!</h1>
                            <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements.</p>
                            
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn me-3">
                                    Get Started <span></span>
                                </a>
     
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                    <i class="flaticon-play-button"></i> 
                                    Play Video
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="it-banner-image owl-carousel owl-theme">
                            <div class="animate-image">
                                <img src="assets/img/it-banner/animate-img.jpg" alt="image">
                            </div>
                            <div class="animate-image">
                                <img src="assets/img/it-banner/animate-img2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape-img11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape-img12"><img src="assets/img/shape/shape12.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="pt-100 pb-70 gray-bg">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-conversation"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">IT Consultancy</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bx-laptop"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Web Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class="bx bxs-megaphone"></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Digital Marketing</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-mobile-alt'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">Mobile App Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-cart'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">eCommerce Development</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card-one bg-white center">
                    <div class="icon">
                        <i class='bx bx-list-check'></i>
                        <i class='bx bxs-badge-check check-icon'></i>
                    </div>
                    <h3>
                        <a routerLink="/service-details">IT Solutions</a>
                    </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Digital Marketing</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                SEO Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Email Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Facebook Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Data Scraping
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Social Marketing
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Youtube Marketing
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/services-2" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service1.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service2.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>Web Design & Development</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Responsive Design
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                UI / UX Design
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Mobile App Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Laravel Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                React Development
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Angular Development
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/services-2" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                    <h2>Cloud Storage Service</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Cloud Database
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Hybrid Cloud
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Email Servers
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Website Hosting
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                File Storage
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Backup Systems
                            </span>
                        </li>
                    </ul>

                    <div class="rm-btn">
                        <a routerLink="/services-2" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service3.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/it-service4.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <h2>SEO Consultancy</h2>
                    <p>We believe brand interaction is key to communication. Real innovations and positive customer experience are the heart of success.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Content Marketing</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> SEO Optimization</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Social Marketing</h4>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Solution Area -->
<section class="solution-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="solution-content">
                    <h2>Why Choose us to Watch this Video Know More!</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/contact" class="default-btn">
                        Contact Us <span></span>
                    </a>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<!-- Project area -->
<div class="works-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Works</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work1.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Designing a better cinema experience</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work2.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">Web Design</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Building design process within teams</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work3.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">eCommerce</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">How intercom brings play eCommerce</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work4.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">React</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">How to start a project with Reactjs</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work5.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">Angular</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Examples of different types of sprints</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <img src="assets/img/works/work6.jpg" alt="image">
                    
                    <div class="content text-center">
                        <span>
                            <a routerLink="/projects">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Redesigning the New York times app</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Case Study</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="more-work">
            <a routerLink="/projects-2" class="default-btn">
                View More Project <span></span>
            </a>
        </div>
    </div>
</div>
<!-- End project area -->

<!-- Start Fun Facts Area -->
<!-- <section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Completed Projects</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Happy Clients</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Area -->

<!-- Start Feedback Area -->
<section class="feedback-area feedback-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">

                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">

                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">

                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>

            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">

                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Pricing Area -->
<section class="pricing-area-two pt-100 pb-70 bg-f4f7fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Daily plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 120 <sub>/ Day</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Design
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            IT Consultancy
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Development
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Mobile Apps Development
                        </li>
                        <li>
                            <i class='bx bxs-x-circle'></i>
                            Quick Responses
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Weekly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 840 <sub>/ Week</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Design
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            IT Consultancy
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Development
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Mobile Apps Development
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Quick Responses
                        </li>
                        <li> 
                            <i class='bx bxs-x-circle'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Monthly plan</h3>
                    </div>

                    <div class="price">
                        <sup>$</sup> 3,600 <sub> / Month</sub>
                    </div>

                    <ul class="pricing-features">
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Design
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            IT Consultancy
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Web Development
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Email Integration
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i> 
                            Mobile Apps Development
                        </li>
                        <li>
                            <i class='bx bx-badge-check'></i>
                            Quick Responses
                        </li>
                        <li> 
                            <i class='bx bx-badge-check'></i>
                            24/7 Support
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a class="default-btn" routerLink="/">
                            Select the plan 
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Shape Images -->
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Expert Team</h2>
        </div>

        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team10.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>John Smith</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team11.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Lucy Eva</h3>
                    <span>Backend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team12.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Frontend Team Leader</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team13.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/team14.jpg" alt="team">

                    <div class="social">
                        <a href="#" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                <div class="content">
                    <h3>Williams Smith</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Lets work Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="subscribe-inner-area lets-work jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="sub-title">READY TO DO THIS</span>
                    <h2>Let's get to work!</h2>
                </div>

                <div class="col-lg-6">
                    <div class="contact-btn">
                        <a routerLink="/contact" class="default-btn">
                            Contact Us <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Lets work Area -->

<!-- Start Partner Area -->
<div class="partner-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Latest News</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog1.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Sarah Taylor</a></li>
                                <li>June 24, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Boost your Digital Marketing Agency</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog2.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">James Anderson</a></li>
                                <li>June 26, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">The Rise of Smarketing and Why You Need It</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/blog3.jpg" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <div class="post-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-2">Steven Smith</a></li>
                                <li>June 25, 2023</li>
                            </ul>
                        </div>
                        
                        <h3><a routerLink="/blog-details">How to Use Music to Boost Your Business</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="email" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->