<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Location Based Solution</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box it-overview why-choose-content">
            <div class="overview-content">
                <div class="content features-text">
                    <h2>Location Based Solution</h2>
                    <p>PoketOffice is an Umbrella of Mobility based systems that'll allow you to manage your
                        Office from your Smartphone.</p>
                    <h4><i class="flaticon-tick maincolor"></i>  Command Center</h4>
<p>Information hub for managing all field assets and resources in real time.</p>

<h4><i class="flaticon-tick maincolor"></i> mCRM</h4>
<p>Add power to your Customer engagements; schedule visits; manage updates in real-
    time; use native CRM or interface with your CRM.</p>

<h4><i class="flaticon-tick maincolor"></i> Complaint Management</h4>
<p>A mobile based Services Management platform; ticket based tracking from start to
    closure; good for Complaint Management & other Services.</p>

<h4><i class="flaticon-tick maincolor"></i>  Attendance System</h4>
<p>Employees can mark their attendance while on move or on field using Mobile
    devices; helps prevent misuse.</p>


<h4><i class="flaticon-tick maincolor"></i>  Employee Self Service & Leave Mgmt</h4>
<p>Mobile-workflow based Employee Self Service for managing Leave & other
    essentials; an Employee support system; helps improve productivity.</p>

<h4><i class="flaticon-tick maincolor"></i>  Corporate Task Tracker</h4>
<p>Single platform to service a variety of organization needs; helps manage Individual
    initiatives, service functional needs, manage cross-functional & Corporate projects.</p>


                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/digi-9-f.svg" alt="image">
                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Projects Area -->


<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Compression-Solution" ><i class="flaticon-left-chevron"></i>Compression Solution  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Object-Detection-Solution">Object Detection Solution<i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>