<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
   <!-- <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div> _-->
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>We Are Digital Explorers</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Recommender systems</span></li>
                        <li><span><i class="fas fa-check"></i> Demand prediction</span></li>
                        <li><span><i class="fas fa-check"></i> Omnichannel analytics</span></li>
                        <li><span><i class="fas fa-check"></i> Lead generation</span></li>
                        <li><span><i class="fas fa-check"></i> Dedicated Developers</span></li>
                        <li><span><i class="fas fa-check"></i> 24/7 Support</span></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our History</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Activate Listening</li>
                            <li><i class="flaticon-tick"></i> Brilliant minds</li>
                            <li><i class="flaticon-tick"></i> Better. Best. Wow!</li>
                            <li><i class="flaticon-tick"></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Mission</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Creating. Results.</li>
                            <li><i class="flaticon-tick"></i> Expect more</li>
                            <li><i class="flaticon-tick"></i> Good thinking</li>
                            <li><i class="flaticon-tick"></i> In real we trust</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Who we are</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Stay real. Always.</li>
                            <li><i class="flaticon-tick"></i> We have you covered</li>
                            <li><i class="flaticon-tick"></i> We turn heads</li>
                            <li><i class="flaticon-tick"></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="324">00</span><span class="sign-icon">+</span></h3>
                    <p>Project Completed</p>
                    <div class="back-text">P</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="100">00</span><span class="sign-icon">%</span></h3>
                    <p>Client Satisfaction</p>
                    <div class="back-text">C</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="13">00</span></h3>
                    <p>Awards</p>
                    <div class="back-text">A</div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts">
                    <h3><span class="odometer" data-count="48">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Members</p>
                    <div class="back-text">E</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>The Key To Your Motivation And Success</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start Solution Area -->
<section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Get Better Solution For Your Business</h2>
                    <p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>
                    <a routerLink="/" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Some Lovely Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client1.jpg" alt="image">

                <h3>Jason Roy</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client2.jpg" alt="image">
                <h3>James Anderson</h3>
                <span>Web Developer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client3.jpg" alt="image">
                <h3>Sarah Taylor</h3>
                <span>Designer</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client4.jpg" alt="image">
                <h3>Steven Smith</h3>
                <span>Manager</span>
            </div>
        </div>

        <div class="single-feedback-item">
            <p>“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”</p>
            <div class="client-info">
                <img src="assets/img/client-image/client5.jpg" alt="image">
                <h3>Tom Nessham</h3>
                <span>EnvyTheme</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Meet Our Awesome Team Member</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team1.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team2.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team3.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</section>
<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-F4F7FC">
    <div class="container-fluid p-0">
        <div class="subscribe-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="subscribe-content">
                <span class="sub-title">Get Started Instantly!</span>
                <h2>Get only new update from this newsletter</h2>

                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                    <button type="submit">Subscribe</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
</section>
<!-- End Subscribe Area -->

<!-- Start Partner Area -->
<section class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Partner</span>
            <h2>We partner with companies of all sizes, all around the world</h2>
        </div>

        <div class="row align-items-center">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                    <img src="assets/img/partner-image/partner1.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                    <img src="assets/img/partner-image/partner2.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                    <img src="assets/img/partner-image/partner3.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                    <img src="assets/img/partner-image/partner4.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                    <img src="assets/img/partner-image/partner5.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                    <img src="assets/img/partner-image/partner6.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                    <img src="assets/img/partner-image/partner7.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                    <img src="assets/img/partner-image/partner8.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                    <img src="assets/img/partner-image/partner9.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                    <img src="assets/img/partner-image/partner10.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
</section>
<!-- End Partner Area -->