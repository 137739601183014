<app-navbar></app-navbar>

<style>
    /* Additional mobile responsiveness */
@media (max-width: 767px) {
    .choose-area-two {
        padding-top: 30px;
        text-align: center;
    }
    
    .choose-text {
        margin-bottom: 20px;
    }

    .choose-image img {
        width: 100%;
        height: auto;
    }

    h2 {
        font-size: 1.8rem;
    }

    p {
        font-size: 16px;
    }

    .choose-text h4 {
        font-size: 1.4rem;
    }
}


@media (min-width: 992px) {
    .choose-area-two {
        padding: 60px 0;
    }

    h2 {
        font-size: 2.5rem;
    }

    .choose-text h4 {
        font-size: 1.6rem;
    }
    
    p {
        font-size: 1.1rem;
    }

    .choose-text {
        margin-bottom: 30px;
    }

    .choose-image img {
        max-width: 90%;
    }
}
@media (max-width: 767px) {
    
    .single-pricing-table
    {
        padding: 10px;
    }
    .bx-badge-check
    {
        display: none;
    }
    .bg-f2f2f7
    {
        padding-bottom: 32px;
    }
   
}

</style>

<style>

    .grid-item {
        width: 100%;
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        opacity: 1;
        /* Start visible */
        transition: opacity 1s ease-in-out;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        /* 12 column grid system */
        grid-auto-rows: 100px;
        /* Default row height */
        /* gap: 10px; */
        grid-row-gap: 40px;
        grid-column-gap: 10px;
        /* Gap between the grid items */
        padding: 20px;
    }

    h3 {
        font-size: 20px;
    }

    /* Define the grid items' positions and spans */
    .item-1 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-2 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-3 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-4 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-5 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-6 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-7 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-8 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-9 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-10 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-11 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .item-12 {
        grid-column: span 2;
        grid-row: span 2;
    }

</style>

<!-- mobile-responsive code for fae in fade our animated section -->
<style>


.image-container {
    background-size: cover;
    background-position: center;
    border-radius: 5px; 
    position: relative; 
}

.image-heading {
    position: absolute; 
    bottom: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); 
    padding: 5px;
    border-radius: 3px;
}
.animation-none {
    animation: none !important;
}

@media (min-width: 992px) {
        /* For large screens (min-width 992px) */
         .image-group {
            display: none !important; /* Ensure the small-screen images are hidden */
        }
        .large-screen-image {
            display: block !important; /* Ensure the large-screen image is shown */
        }
    }

    .large-screen-image {
        display: none; /* Hide this by default for small screens */
    }
</style>



<div class="container-fluid d-none d-md-none d-lg-block" style="transform: scale(0.9); margin-top: 100px;">
    <div class="section-title" style="max-width: 800px !important; margin-bottom: 30px; margin-top: 52px;">
        <h2 class="text-center mt-5" style="max-width: 800px !important;">Emerging & Advanced Technologies</h2>
    </div>
    <div class="grid-container">

        <!-- Grid items with images and titles below each image -->
        <div class="grid-item item-1">
            <img src="/assets/img/Innovation_Lab/5g_1.png" alt="5G" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">5G</h3>
        </div>
        
        <div class="grid-item item-2">
            <img src="/assets/img/Innovation_Lab/AI_1.png" alt="AI" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">AI</h3>
        </div>
        
        <div class="grid-item item-3">
            <img src="/assets/img/Innovation_Lab/CCTV_1.jpg" alt="CCTV" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">CCTV</h3>
        </div>
        
        <div class="grid-item item-4">
            <img src="/assets/img/Innovation_Lab/cctvCamera_1.jpg" alt="Camera" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Camera</h3>
        </div>
        
        <div class="grid-item item-5">
            <img src="/assets/img/Innovation_Lab/FaceLock_1.png" alt="Facelock" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Facelock</h3>
        </div>
        
        <div class="grid-item item-6">
            <img src="/assets/img/Innovation_Lab/fraud_1.png" alt="Fraud" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Fraud</h3>
        </div>
        
        <div class="grid-item item-7">
            <img src="/assets/img/Innovation_Lab/IOT_1.jpg" alt="IoT" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">IoT</h3>
        </div>
        
        <div class="grid-item item-8">
            <img src="/assets/img/Innovation_Lab/livestream_1.jpg" alt="Livestream" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Livestream</h3>
        </div>
        
        <div class="grid-item item-9">
            <img src="/assets/img/Innovation_Lab/location.png" alt="Location" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Location</h3>
        </div>
        
        <div class="grid-item item-10">
            <img src="/assets/img/Innovation_Lab/qrcode_1.png" alt="QR" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">QR</h3>
        </div>
        
        <div class="grid-item item-11">
            <img src="/assets/img/Innovation_Lab/robot_1.png" alt="Robot" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Robotics</h3>
        </div>
        
        <div class="grid-item item-12">
            <img src="/assets/img/Innovation_Lab/testing.jpg" alt="Testing" class="img-fluid" style="width: 100%; height: 100%; object-fit: cover;">
            <h3 class="text-center text-uppercase mt-2">Digital Twin</h3>
        </div>
    </div>
</div>




<div class="container d-lg-none d-md-block d-block" style="margin-top: 100px;">
    <div class="section-title" style="max-width: 800px !important;">
        <h2 class="text-center mt-3" style="max-width: 800px !important;">Emerging & Advanced Technologies</h2>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/5g_1.png" alt="5G" class="img-fluid" style="height: 180px;">
                <h3 class="image-heading mt-2">5G</h3>
            </div>
        </div>
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/AI_1.png" alt="AI" class="img-fluid" style="height: 220px;">
                <h3 class="image-heading mt-2">AI</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 mb-4">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/App_1.jpg" alt="App" class="img-fluid" style="height: 200px;">
                <h3 class="image-heading mt-2">App</h3>
            </div>
        </div>
        <div class="col-6 mt-3">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/CCTV_1.jpg" alt="CCTV" class="img-fluid" style="height: 140px;">
                <h3 class="image-heading mt-2">CCTV</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 mb-3">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/cctvCamera_1.jpg" alt="Camera" class="img-fluid" style="height: 210px;">
                <h3 class="image-heading mt-2">Camera</h3>
            </div>
        </div>
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/FaceLock_1.png" alt="FaceLock" class="img-fluid" style="height: 150px;">
                <h3 class="image-heading mt-2">Facelock</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/fraud_1.png" alt="Fraud" class="img-fluid" style="height: 170px;">
                <h3 class="image-heading mt-2">Fraud</h3>
            </div>
        </div>
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/IOT_1.jpg" alt="IoT" class="img-fluid" style="height: 230px;">
                <h3 class="image-heading mt-2">IoT</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/KYC_1.png" alt="KYC" class="img-fluid" style="height: 210px;">
                <h3 class="image-heading mt-2">KYC</h3>
            </div>
        </div>
        <div class="col-6 my-3">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/livestream_1.jpg" alt="LiveStream" class="img-fluid" style="height: 190px;">
                <h3 class="image-heading mt-2">Livestream</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/location.png" alt="Location" class="img-fluid" style="height: 180px;">
                <h3 class="image-heading mt-2">Location</h3>
            </div>
        </div>
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/qrcode_1.png" alt="QR" class="img-fluid" style="height: 220px;">
                <h3 class="image-heading mt-2">QR</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/robot_1.png" alt="Robot" class="img-fluid" style="height: 240px;">
                <h3 class="image-heading mt-2">Robotics</h3>
            </div>
        </div>
        <div class="col-6 mt-3">
            <div class="image-container text-center">
                <img src="assets/img/Innovation_Lab/testing.jpg" alt="Testing" class="img-fluid" style="height: 150px;">
                <h3 class="image-heading mt-2">Digital Twin</h3>
            </div>
        </div>
    </div>
</div>


<!-- Infogrphic Cards -->

<div class="container mb-5 mt-5">
    <div class="row justify-content-center">
        <!-- Images for small screens -->
        <div class="col-lg-4 col-sm-12 image-group">
            <div class="single-pricing-table text-left">
                <img src="assets/img/Innovation_Lab/Group 1.png" alt="" style="width: 100%; height: auto;">
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 image-group">
            <div class="single-pricing-table text-left">
                <img src="assets/img/Innovation_Lab/Group 2.png" alt="" style="width: 100%; height: auto;">
            </div>
        </div>
        <div class="col-lg-4 col-sm-12 image-group">
            <div class="single-pricing-table text-left">
                <img src="assets/img/Innovation_Lab/Group 3.png" alt="" style="width: 100%; height: auto;">
            </div>
        </div>

        <!-- Image for large screens only -->
        <div class="col-lg-12 col-sm-12">
            <div class="single-pricing-table text-left large-screen-image">
                <img src="assets/img/Innovation_Lab/Frame 2.png" alt="Frame 2" style="width: 100%; height: auto;">
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-12 text-center">
            <div class="solutions-bar p-3" style="background: linear-gradient(90deg, #9e8cf2, #ff6633); border-radius: 10px; color: white;">
                <p style="color: #fff;">Solutions for: BFSI | Government | Smart Cities | Defence | Manufacturing | Hospitality | Retail</p>
            </div>
        </div>
    </div>
</div>





<!-- <div class="saas-banner-two hero-banner banner-bg2 ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container mt-50">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1><span class="highlight">Innovating</span> for Your Success<span class="highlight"></span> </h1>
                            <p>At our Innovation Lab, we transform cutting-edge ideas into impactful digital solutions, driving business growth through technology experimentation and rapid prototyping. Our team pushes the boundaries of innovation, delivering breakthrough advancements tailored to your needs. </p>
                        </div>
                    </div>

                    <div class="col-lg-6">
                       <div class="saas-banner-image animate-tb">
                            <img src="assets\img\Innovation_Lab\Visionary technology-amico 1.png" alt="image" style="width: 90%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!--  -->
<!-- <section class="pricing-area-two pt-100 pb-70  ">
    <div class="container">
        <div class="section-title" style="max-width: 800px !important;">
            <h2 class=" text-center" style="max-width: 800px !important;">Emerging & Advanced Technologies</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-12 col-sm-12"> <div class="single-pricing-table text-left">
                <img class="emgTech" src="assets/img/Innovation_Lab/devlop.jpg" alt="New Technology Image" style="width: 100%; height: auto;">
            </div></div>
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table  text-left"style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\TelematicsIcons.png" alt="Telematics Icon" style="width:20px; height:20px;">
                            Telematics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\IOT.png" alt="IoT Icon" style="width:20px; height:20px;">
                            IoT
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\scanner.png" alt="Scanner Icon" style="width:20px; height:20px;">
                            Scanner
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Stack.png" alt="Scanner Icon" style="width:20px; height:20px;"> 
                            India Stack
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\qr-code.png" alt="QR Code Icon" style="width:20px; height:20px;">
                            QR code
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\biometrics.png" alt="Biometrics Icon" style="width:20px; height:20px;">
                            Biometrics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Drone_imagining.png" alt="Drone Imaging CCTV Icon" style="width:20px; height:20px;">
                            Drone Imaging
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Geo_Tagging.png" alt="Drone Imaging CCTV Icon" style="width:20px; height:20px;">
                           Geo-Tagging
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\cctv-camera.png" alt="CCTV and Custom Web Apps Icon" style="width:20px; height:20px;">
                            CCTV 
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Custome_mobileapps .png" alt="CCTV Icon" style="width:20px; height:20px;">
                            Custom Web & Mobile Apps
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Custom_camera.png" alt="Custom Web & Mobile Apps Icon" style="width:20px; height:20px;">
                            Custom Camera
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table text-left" style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\AI.png" alt="AI/ML Icon" style="width:20px; height:20px;">
                            AI/ML
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Fraud.png" alt="Fraud Analytics Icon" style="width:20px; height:20px;">
                            Fraud Analytics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\ComputerVison.png" alt="Computer Vision Icon" style="width:20px; height:20px;">
                            Computer Vision
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\5g.png" alt="5G Off-Grid Network Icon" style="width:20px; height:20px;">
                            5G Off-Grid Network
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\VidoAnalytic.png" alt="Video Analytics Icon" style="width:20px; height:20px;">
                            Video Analytics
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\ocr.png" alt="OCR Icon" style="width:20px; height:20px;">
                            OCR / ICR / OMR
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\FaceReco.png" alt="Face Recognition Icon" style="width:20px; height:20px;">
                            Face Recognition
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\DocManagment.png" alt="DMS Icon" style="width:20px; height:20px;">
                            DMS
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\data-lake.png" alt="Data Lake Icon" style="width:20px; height:20px;">
                            Data Lake
                        </li>
                    </ul>
                </div>
            </div>
        
            <div class="col-lg-4 col-sm-12">
                <div class="single-pricing-table text-left"style="background-color: #f4f7fc;border: 1px solid #c9cbd0;min-height:410px;">
                    <ul class="pricing-features" style="margin: 0;">
                        <li>
                            <img src="assets\img\Innovation_Lab\KYC.png" alt="KYC Icon" style="width:20px; height:20px;">
                            Full Stack KYC
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\app.png" alt="Productivity Apps Icon" style="width:20px; height:20px;">
                            Productivity Apps & Services
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Digitaltwin.png" alt="Digital Twin Icon" style="width:20px; height:20px;">
                            Digital-Twin
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\bolt.png" alt="Humanoid Bot Icon" style="width:20px; height:20px;">
                            Humanoid Bot
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\IOT.png" alt="App Bolt-Ons Icon" style="width:20px; height:20px;">
                            App Bolt-Ons
                        </li>
                        <li>
                            <img src="assets\img\Innovation_Lab\Buapp.png" alt="Business Apps Icon" style="width:20px; height:20px;">
                            Business Apps
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
</section> -->

<!-- Start Custom Developement -->

<section class="choose-area-two bg-f2f2f7" style="padding-top: 55px;">
    <div class="container">
        <h2 class="text-center mb-3">Custom Development</h2>
        <p class="text-center" style="font-size: 20px;">We craft tailor-made software solutions designed to meet your business's unique needs.</p>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-md-1">
                <div class="choose-image saas-banner-image animate-tb">
                    <img class="CustomDevelopement mt-5 img-fluid" src="assets/img/Innovation_Lab/CustomDevelopement.png" alt="image">
                    <h5 class="mt-3 text-center mb-5">Customised Business Solutions</h5> 
                </div>
            </div>

            <div class="col-lg-6 col-md-12 order-md-2">
                <div class="choose-content">
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Tailored Solutions</h4>
                        <p>Customized applications built to align with your goals and processes.</p>
                    </div>
                    
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Scalable Architecture</h4>
                        <p>Solutions designed for growth, adapting as your business expands.</p>
                    </div>
                    
                    <div class="choose-text">
                        <i class='bx bx-badge-check'></i>
                        <h4>Seamless Integration</h4>
                        <p>Smooth integration with existing systems, boosting efficiency without disruptions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




























<!-- <div class="bd-about-area pb-70" style="margin-top: 50px;">
    <div class="container">
        <div class="row align-items-center">
                <h3 class=" text-center mb-3" >Messaging & Communication Suite</h3>
                </div>
                <div class="row">
                    
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs1.png" width="50" alt="image">
                            </div>
                            <h6>Video Conference
                                & Chat
                               </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs3.png" width="50" alt="image">
                            </div>
                            <h6>Enterprise Command & Control
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs5.png" width="50" alt="image">
                            </div>
                            <h6>Broadcast Messages
                            </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs2.png" width="50" alt="image">
                            </div>
                            <h6>Push to talk (PTT) 
                                Walkie Talkie </h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs4.png" width="50" alt="image">
                            </div>
                            <h6>Sub-Group Chats</h6>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <div class="service-card-one bg-white center" style="padding: 0;">
                            <div class="icon">
                                <img src="assets\img\Innovation_Lab\mcs5.png" width="50" alt="image">
                            </div>
                            <h6>Project Progress
                                Reporting</h6>
                        </div>
                    </div>
        </div>
        <div class="row align-items-center">
            <h3 class=" text-center mb-3" >Work Related</h3>
            </div>
            <div class="row">
                
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="service-card-one bg-white center" style="padding: 0;">
                        <div class="icon">
                            <img src="assets\img\Innovation_Lab\mcs6.png" width="50" alt="image">
                        </div>
                        <h6>Task Management</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="service-card-one bg-white center" style="padding: 0;">
                        <div class="icon">
                            <img src="assets\img\Innovation_Lab\mcs7.png" width="50" alt="image">
                        </div>
                        <h6>Site Inspections</h6>   
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
    </div>
    <div class="row align-items-center">
        <h3 class=" text-center mb-3" >Human Resource Related</h3>
        </div>
        <div class="row">
            
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="service-card-one bg-white center" style="padding: 0;">
                    <div class="icon">
                        <img src="assets\img\Innovation_Lab\mcs8.png" width="50" alt="image">
                    </div>
                    <h6>Attendance Marking</h6>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="service-card-one bg-white center" style="padding: 0;">
                    <div class="icon">
                        <img src="assets\img\Innovation_Lab\mcs9.png" width="50" alt="image">
                    </div>
                    <h6>H.R.M.S 
                        Self-Service</h6>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                
            </div>
            <div class="col-lg-2 col-md-6">
               
            </div>
</div>
<div class="row align-items-center">
    <h3 class=" text-center mb-3" >Workforce Health & Safety Related</h3>
    </div>
    <div class="row">
        
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
            <div class="service-card-one bg-white center" style="padding: 0;">
                <div class="icon">
                    <img src="assets\img\Innovation_Lab\mcs10.png" width="50" alt="image">
                </div>
                <h6>Safety Interfaces</h6>
            </div>
        </div>
        <div class="col-lg-2 col-md-6">
            <div class="service-card-one bg-white center" style="padding: 0;">
                <div class="icon">
                    <img src="assets\img\Innovation_Lab\mcs11.png" width="50" alt="image">
                </div>
                <h6>Health  Monitors
                </h6>
            </div>
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
        <div class="col-lg-2 col-md-6">
           
        </div>
</div>
    </div>
</div> -->

