<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Object Detection Solution</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area ptb-100">
    <div class="container">
        <div class="row"><div class="col-md-12"> <h2>Object Detection Solution</h2>
            <p>AI-enabled object detection utilizes advanced computer vision algorithms and machine
                learning techniques to identify and locate specific objects within an image or video stream.
                By analysing visual features, patterns, and context, the AI system can accurately detect and
                classify objects such as people, vehicles, animals, or specific items of interest. This
                technology has diverse applications, ranging from autonomous vehicles and surveillance
                systems to augmented reality and image analysis. It enables automated and real-time
                detection, tracking, and understanding of objects, enhancing efficiency, safety, and
                decision-making in various industries and domains.</p></div></div>
        <div class="overview-box it-overview">
            <div class="overview-content">
                <div class="content">
                   
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Object Detection & Identification
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Object Counting
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Damage Detection
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Supply chain/ shop floor auto order triggering functionality
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Factory floor management. Example : Object littering/ Missing object recognition
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Vehicle counting. Example : Audit use case at Toll Booths
                            </span>
                        </li>
                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/object_detection.svg" alt="image">
                </div>
            </div>
        </div>



    </div>
</section>
<!-- End Projects Area -->

<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Location-Based-Solution" ><i class="flaticon-left-chevron"></i>Location Based Solution </a>
        </div>

        <div class="nav-next">
            <a routerLink="/iMagica-Suite">iMagica Suite <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>