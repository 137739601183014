import { Component } from '@angular/core';

@Component({
  selector: 'app-innovation-lab',
  templateUrl: './innovation-lab.component.html',
  styleUrls: ['./innovation-lab.component.scss']
})
export class InnovationLabComponent {

}
