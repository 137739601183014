<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>DigiVision</h2>
                    <ul>
                        <li><a routerLink="/Digivision">Digivision</a></li>
                        <li>Video KYC</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

   
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="overview-area pb-100">
    <div class="container">
        <div class="overview-box it-overview">
<div class="p-50"><h2>Agent-assisted Video KYC</h2>
    <p>Agent-assisted Video KYC is a process where a customer's identity is verified remotely
        through a video call with a trained agent or representative. During the call, the customer is
        required to display and verify their identification documents, such as a passport or driver's
        license, to the agent. The agent then verifies the documents, capture uploaded document
        for audit purposes, and completes the KYC process. This method combines the convenience
        of remote verification with the assurance of human interaction, making it suitable for
        situations where face-to-face verification is not feasible but an extra level of security and
        personal touch is desired.</p></div>

            <div class="overview-content">
                <div class="content about-content">
                    
                        
                        <h4>Two-Way Agent Assisted Video KYC</h4> 
                       <p> Wherein an Agent and Customer connects and initiate the Video KYC.</p>
                       <h4>Three-Way Agent Assisted Video KYC </h4>
                       <p>Wherein an Agent, Customer and Advisor connects and initiate the Video KYC.</p>


                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Agent Live Interaction with Customer
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Live Chat box facility
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Multi Language Support
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Geotagging with IP logging
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Document Upload and Identification/Detection
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Perform OCR on KYC
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Agent Verifies the submitted documents & accepts or rejects the case
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Two-Way Agent Assisted and Three-Way Agent Assisted Video KYC available
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Available for Web version & Mobile APP version
                            </span>
                        </li>


                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/Agent-assisted-Video-KYC.svg" alt="image">
                </div>
            </div>
        </div>

    </div>
</section>
<!-- End Projects Area -->
<section class="overview-area pb-100 bg-f4f7fe">
    <div class="container">
        <div class="overview-box it-overview">
<div class="p-50"><h2>Self-assisted Video KYC</h2>
    <p>Self-assisted Video KYC is a process where customers verify their own identity remotely
        through a video call without the involvement of an agent. Customers are guided through the
        KYC process using a video-based platform that prompts them to provide and verify their
        identification documents, such as a passport or driver's license, in real-time. They follow the
        instructions provided on the platform, capture images or videos of their documents, and
        complete the verification process independently. This method offers convenience, flexibility,
        and privacy to customers, allowing them to complete the KYC process from anywhere at
        their own pace.</p></div>

            <div class="overview-content">
                <div class="content about-content">
                
                    <ul class="features-list">
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Multi Language Support
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Geotagging with IP logging
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Random questions readout capture and verification
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Document Upload and Identification/Detection
                            </span>
                        </li>
                      
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Perform OCR on KYC
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Face Matching/Tele underwriting
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Review Summarized Result & Submit
                            </span>
                        </li>
                        <li>
                            <span>
                                <i class='bx bxs-badge-check'></i>
                                Available for Web version & Mobile App Version
                            </span>
                        </li>


                    </ul>

                    <!-- <div class="rm-btn">
                        <a routerLink="/service-details" class="default-btn">
                            Read More 
                            <span></span>
                        </a>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/Self-assisted-Video-KYC.svg" alt="image">
                </div>
            </div>
        </div>

    </div>
</section>


<section class="overview-area ptb-100">
    <div class="container">
        <div class="about-text card p-10">
    <h3>Comply with guidelines set by the RBI, IRDAI, SEBI    </h3>
    <ul class="features-list">
        <li><i class="flaticon-tick"></i>Record video as well as capture photo</li>
        <li><i class="flaticon-tick"></i> Accept clear image of PAN card</li>
        <li><i class="flaticon-tick"></i> Capture live location to ensure customer is in India</li>
        <li><i class="flaticon-tick"></i> Face matching with Id proof</li>
        <li><i class="flaticon-tick"></i> KYC document to be matched with application form</li>
        <li><i class="flaticon-tick"></i> Verify a few details with the customer in random order</li>
        <li><i class="flaticon-tick"></i> Video recording has to be stored with time stamp</li>
        <li><i class="flaticon-tick"></i> Mask Aadhaar number on Aadhaar card</li>

    </ul><p>&nbsp;</p>
</div>
</div>
</section>
<div class="post-navigation"><div class="container">
    <div class="navigation-links">
        
        <div class="nav-previous ">
            <a routerLink="/Offline-Vision-Engine" ><i class="flaticon-left-chevron"></i> Offline Vision Engine  </a>
        </div>

        <div class="nav-next">
            <a routerLink="/Document-Indexing">Document Indexing & Classification <i class="flaticon-right-chevron"></i></a>
        </div></div>
    </div>
</div>