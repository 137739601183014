<app-navbar></app-navbar>

<!-- Start Cyber Security Banner Area -->
<div class="cs-banner-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="cs-banner-content">
                    <span class="sub-title d-none">&nbsp;</span>
                    <h1>Full Attack Surface Monitoring</h1>
                    <p>Monitor and manage your organization's complete attack surface and stay ahead of emerging threats through real-time monitoring and leverage actionable threat intelligence.</p>

                    <ul class="banner-btn">
                        <li>
                            <a class="default-btn burger-menu">
                                Get Started <span></span>
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="cs-banner-image">
                    <img src="assets/img/GRC2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="cs-lock">
        <img src="assets/img/cyber-security-home/banner/lock.png" alt="image">
    </div>
</div>
<!-- End Cyber Security Banner Area -->

<div class="background-with-black-color">

    <!-- Start CS About Area -->
    <div class="cs-about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-image">
                        <img src="assets/img/cyber-security-home/about.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-about-content">
                        <span class="sub-title">ABOUT SECURENEXUS - GRC</span>
                        <h3>Securing Your Nexus of Forces</h3>
                        <p>SecureNexus specializes in Cyber Security, offering VAPT, Red Teaming, OSINT, Source code review, threat modeling, Cloud Security Assessment, and more. With our proprietary products and advanced threat intelligence, we provide enhanced services for top-notch security.</p>

                        <!-- <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-lock"></i>
                                    </div>
                                    <h4>Quality Team</h4>
                                    <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="cs-about-card">
                                    <div class="icon">
                                        <i class="fa-solid fa-shield"></i>
                                    </div>
                                    <h4>High Quality Output</h4>
                                     <p>Lorem ipsum dolor sit amet, is demo consectetur adipiscing elit.</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="about-btn d-none">
                            <a href="contact.html" class="default-btn">
                                Get Started with free POC <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

 
<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1 class="text-white">Our Featured Solutions</h1>
            <p class="text-white">Stay ahead of threats with real-time intelligence, manage vendors, ensure compliance, and streamline business continuity.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-seo"></i>
                    </div>
                    <h3 class="text-white">Full Attack Surface Monitoring</h3>
                    <p>Monitor organization's points of exposure with ongoing attack surface monitoring and stay ahead of emerging threats with real-time threat intelligence.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3 class="text-white">Vulnerability Management</h3>
                    <p>Monitor vulnerabilities, periodic assessments, dashboards, recommendation engine, escalation matrix, aging reports, import scan results, & security index/score for assets, vendors, & orgs.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3 class="text-white">Software Composition Analysis</h3>
                    <p>Automated tech stack and component tracking, including third-party, for compliance and vulnerability management. Generates SBOM and tracks licenses and threat advisories.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-architecture"></i>
                    </div>
                    <h3 class="text-white">Vendor Onboarding & Due Diligence</h3>
                    <p>Manages vendor onboarding with security scoring, contract management and dynamic questionnaires with workflows for due diligence. Tiers vendors and tracks issues using centralized tracker.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3 class="text-white">Business Continuity Management</h3>
                    <p>Automated management of business processes, dependencies,  & maximum tolerable downtime. Generates function resumption plans for disruption scenarios & resource requirements.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3 class="text-white">Secret Discovery & Management</h3>
                    <p>AI algorithms for identifying secrets in repos and monitoring sensitive data throughout their lifecycle. Continuously scans and integrates with CI/CD pipelines.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-maincolor">
                        <i class="flaticon-play-button"></i>
                    </div>
                    <h3 class="text-white">Exception Management</h3>
                    <p>Make informed decisions, assesses non-compliance, manages the lifecycle of exceptions, provides understanding for renewal or extension, delivers alerts and notifications, and links exceptions to policies or procedures.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-digital-marketing"></i>
                    </div>
                    <h3 class="text-white">Dynamic Forms, wiki and Inbuilt rule Engine</h3>
                    <p>Our low code platform includes dynamic forms, a wiki, and an inbuilt rule engine, which can be customized to meet the specific needs of your organization.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-digital-marketing"></i>
                    </div>
                    <h3 class="text-white">IT Risk Management (RCM)</h3>
                    <p>The IT Risk Management (RCM) tool facilitates comprehensive risk assessment, real-time threat monitoring, and compliance tracking to enhance website security.</p>
                    <!-- <a  class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></a> -->
                </div>
            </div>

           
        </div>
    </div>
</section>
<!-- End Services Area -->


    <!-- Start CS Choose Area -->
    <div class="cs-choose-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-content">
                        <span class="sub-title">WHY CHOOSE SECURE NEXUS-GRC</span>
                        <h3>Your cyber Risk Shield -  Choose SecureNexus for <span>Unmatched</span> Cyber Security</h3>
                        <p>Enhance your cyber resilience with our tailored strategies and proactive approach—partner with SecureNexus to safeguard your digital assets.</p>

                        <ul class="list">
                            <li><i class="fa-solid fa-check"></i> Industry-leading professionals ensuring exceptional security.</li>
                            <li><i class="fa-solid fa-check"></i> Holistic approach covering all security aspects.</li>
                            <li><i class="fa-solid fa-check"></i> Trusted choice with successful outcomes.</li>
                        </ul>
                        <div class="choose-btn">
                            <a class="default-btn burger-menu">
                                Get Started <span></span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-choose-image">
                        <img src="assets/img/Safe-cuate.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Choose Area -->

 

    <!-- Start CS Fun Facts Area -->
    <section class="cs-fun-facts-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">01.</div>
                        <h3><span class="odometer" data-count="200">00</span><span class="sign-icon">+</span></h3>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">02.</div>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>All Time Clients</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">03.</div>
                        <h3><span class="odometer" data-count="200">00</span><span class="sign-icon">+</span></h3>
                        <p>Attacks Blocked</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="cs-single-fun-facts">
                        <div class="number">04.</div>
                        <h3><span class="odometer" data-count="60">00</span><span class="sign-icon">+</span></h3>
                        <p>Experts Team</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End CS Fun Facts Area -->

    <!-- Start Leading Technology Area -->
    <!-- <div class="leading-technology-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-image">
                        <img src="assets/img/cyber-security-home/leading-technology.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="leading-technology-content">
                        <span class="sub-title">LEADING TECHNOLOGY FIRM</span>
                        <h3>Provide Advanced <span>Security</span> For Threat And Effective Protection</h3>
                        <p>We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential of digital engagements We work hand-in-hand with industry-leading brands to help redefine the possibilities and potential.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Detection</li>
                                    <li>Managing Cloud Security</li>
                                    <li>Website Hack Repair</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li>Network Trafic Analysis</li>
                                    <li>Hybrid Cloud Security</li>
                                    <li>24/7 Security Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Leading Technology Area -->

    <!-- Start CS Overview Area -->
    <div class="cs-overview-area pb-70 pt-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Personal Online Protection Everywhere We Go</h3>
                                    <a class="get-started-btn burger-menu">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="cs-overview-item">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-4 col-md-6">
                                <div class="cs-overview-image">
                                    <img src="assets/img/cyber-security-home/overview-2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6">
                                <div class="cs-overview-content">
                                    <h3>Simple Solution For Your Business's Security</h3>
                                    <a  class="get-started-btn burger-menu">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End CS Overview Area -->

</div>

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area with-black-color d-none">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-content">
                <h2>Join Our Newsletter</h2>

                <form class="newsletter-form" data-toggle="validator">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                    <button type="submit">Subscribe</button>
                    <div id="validator-newsletter" class="form-result"></div>
                </form>
            </div>

            <div class="subscribe-shape-1">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-1.png" alt="image">
            </div>
            <div class="subscribe-shape-2">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-2.png" alt="image">
            </div>
            <div class="subscribe-shape-3">
                <img src="assets/img/cryptocurrency-home/subscribe/shape-3.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->