import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare const grecaptcha: any;

@Component({
  selector: 'app-register3',
  templateUrl: './register3.component.html',
  styleUrls: ['./register3.component.scss']
})
export class Register3Component {
  @ViewChild('recaptchaRef') recaptchaRef: any;

  contactForm: FormGroup;
  recaptchaResponse: string = '';
  showEmailError: boolean = false;
  showProductServiceError: boolean = false;
  successMessage: string = ''; // Variable to store the success message
  formSubmitted: boolean = false; // Flag to track form submission status


// Declare the varible in ts file
selectedValue:any;
  //isRecaptchaValid: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.createContactForm();
  }

  ngAfterViewInit() {
    grecaptcha.ready(() => {
      this.loadRecaptcha();
    });
  }

  loadRecaptcha() {
    grecaptcha.render(this.recaptchaRef.nativeElement, {
      sitekey: '6LeMo0UnAAAAAAZTi3aRnmCpP2TgSNI1xjM5P5h6',
      callback: (response: string) => {
        this.handleRecaptchaResponse(response);
      }
    });
  }

  createContactForm() {
    this.contactForm = this.formBuilder.group({
      // Form controls...
      firstName: ['',Validators.required],  
      lastName: ['',Validators.required],  
      email: ['',[Validators.required, Validators.email]],
      productsServices: [''],
      mobile:['', Validators.required],
      company:['', Validators.required],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  handleRecaptchaResponse(response: string): void {
    this.recaptchaResponse = response;
    this.contactForm.patchValue({ recaptcha: this.recaptchaResponse });
    //this.isRecaptchaValid = true; // Set isRecaptchaValid to true when the response is valid
  }

  isRecaptchaValid(): boolean {
    return this.recaptchaResponse !== '';
  }

  isFormValid(): boolean {
    return this.contactForm.valid && this.isRecaptchaValid();
  }

  getSelectedValue(event:any){
  
    // Prints selected value
    console.log(event.target.value);
  
  }
  
  onProductsServicesSelection(selectedValue: string) {
    this.contactForm.get('productsServices').setValue(selectedValue);
  }
 
  validateProductService() {
    const productServiceControl = this.contactForm.get('productsServices');
    const selectedOption = productServiceControl.value;
    if (!selectedOption || selectedOption.trim() === '') {
      productServiceControl.setErrors({ required: true });
    } else {
      productServiceControl.setErrors(null);
    }
    this.showProductServiceError = productServiceControl.touched && productServiceControl.invalid;
  }
  
  debugger;
  onSubmit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid || !this.isRecaptchaValid) {
      // Stop form submission if the form is invalid or reCAPTCHA is not valid
      return;
    }

    // Include the reCAPTCHA response in the form data
    this.contactForm.patchValue({ recaptcha: this.recaptchaResponse });

    // Make the API call or perform form submission logic here...
    //console.log('Your form data: ', this.contactForm.value);
    // Make the API call
    debugger;
    this.http.post('https://api.x-bizcore.com/inquiry', this.contactForm.value)
      .subscribe(
        (response: HttpResponse<any>) => {
          // Check the status code in the response
          if (response.status === 200) {
            // Handle success response
            this.successMessage = 'Your inquiry has been submitted successfully!';
            this.formSubmitted = true; // Set the formSubmitted flag to true
            this.toastr.success(`Your inquiry has been submitted successfully!`)
            // You can also clear the form after successful submission, if needed:
            this.contactForm.reset();
          } else {
            console.error(response)
            this.toastr.error(`Error submitting form`, `Status code${response.status}`);
          }
        }, (error) => {
          console.error(error)
          if (error.status === 200 && error.error) {
            // Handle the success response (even though it's inside the error callback)
            this.successMessage = 'Your inquiry has been submitted successfully!';
            this.formSubmitted = true;
            this.toastr.success(`Your inquiry has been submitted successfully!`);
            this.contactForm.reset();
          } else {
            // Handle the actual error response
            this.toastr.error('Error!!');
          }
        }
       
      );
  }
}
