<app-navbar></app-navbar>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects Style Two</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects Style Two</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Project area -->
<div class="works-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>All Projects</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work1.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Designing a better cinema experience</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work2.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Web Design</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Building awesome design process within teams</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work3.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">eCommerce</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">How intercom brings play eCommerce</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work4.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">React</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast project with React Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work5.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Angular</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast project with Angular Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work6.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Developing a super-fast App with React Js</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work7.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Graphic Design</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Graphic Design Design the Web, Mobile, and eCommerce</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work8.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Bootstrap</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">Bootstrap Redesigning the New York times app</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work9.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">App Development</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of app development</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work10.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of marketing support</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work11.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Email Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of Email Marketing</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-xl-3">
                <div class="work-card">
                    <img src="assets/img/works/work12.jpg" alt="image">
                    
                    <div class="content">
                        <span>
                            <a routerLink="/services-2">Email Marketing</a>
                        </span>
                        <h3>
                            <a routerLink="/project-details">We provide any type of Marketing & Reporting</a>
                        </h3>
                            
                        <a class="work-btn" routerLink="/project-details">Read More</a>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <div class="col-lg-12 col-sm-12">
                <div class="pagination-area">
                    <a routerLink="/services-2" class="prev page-numbers">
                        <i class="fas fa-angle-double-left"></i>
                    </a>
                    <a routerLink="/services-2" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/services-2" class="page-numbers">3</a>
                    <a routerLink="/services-2" class="page-numbers">4</a>
                    <a routerLink="/services-2" class="next page-numbers">
                        <i class="fas fa-angle-double-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End project area -->